import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../components/voice/Sidebar';
import PromptInputVoice from '../components/voice/Prompt';
import { retrieveModels } from "../data/models";
import { retrieveTemplates } from "../data/templates";
import { updateModelList } from '../store/modelSlice';
import { setModel, setGenerateMedia } from '../store/generateOptionsSlice';
import { updateTemplates, templateIsLoaded, selectTemplate } from '../store/templateSlice';
import Loading from '../components/Loading';
import { updateVoices, updateSelectedVoice } from '../store/contentSlice';
import { elevenLabsVoices } from '../promptApi/elevenlabs';
import { Flex, Card, CardBody, Heading } from '@chakra-ui/react'

function GenerateVoice() {
  const dispatch = useDispatch();
  const modelList = useSelector((state) => state.models.modelList);
  const templatesLoaded = useSelector((state) => state.templates.templatesLoaded);
  const voices = useSelector((state) => state.content.voices);
  const { innerWidth: width} = window;
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        
        if(modelList.length === 0) {
          const modelListFromServer = await retrieveModels();
          dispatch(updateModelList(modelListFromServer)); 
        }

        modelList.forEach((m) => {
          if(m._id === '64f8fb61f50662eb6ecb1d55'){
            dispatch(setModel(m));
          }
        });

        if(!templatesLoaded) {
          const templateList = await retrieveTemplates();
          dispatch(updateTemplates(templateList));
          dispatch(templateIsLoaded());
          dispatch(selectTemplate(templateList));
          dispatch(setGenerateMedia('Voice'));
        }

        if(voices.length <= 0) {
          const voiceData = await elevenLabsVoices();
          dispatch(updateVoices(voiceData.voices));
          dispatch(updateSelectedVoice(voiceData.voices[15]));
        }
        setLoading(false); 
      } catch(error) {
        console.log('Error fetching data', error);
      }
    };
    fetchData();
  }, [dispatch, modelList, voices]);
  
  if (width <= 900){
    return (
      <Flex
        justify={'center'}
        align={'center'}
        p={'20px'}
      >
        <Heading size={'sm'}>
          Sorry we don't currently support screen sizes below 900px
        </Heading>
      </Flex>
    )
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Flex w='100%' h='100%' bgGradient='linear(to-t, #EBF3FD, #f3f0ff)'>
      <SideBar />
      <Flex direction='column'  w='100%' h='100%' p='20px'>
        <Card w='100%' variant='outline' boxShadow='md' overflow='scroll'>
          <CardBody pt='10px' p='opx'>
            <PromptInputVoice />
          </CardBody>
        </Card>
      </Flex>
    </Flex>      
  );
}

export default GenerateVoice;
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateImagePrompt, updateBase64, toggleLoading } from '../../store/contentSlice';
import Generate from '../../promptApi/generateImage';
import { updateCredits } from '../../store/userSlice';
import axios from 'axios'
import noUser from '../../utils/HandleNoUser';

import {
  Button,
  Textarea,
  Flex,
  FormControl,
  Stack,
  Select,
  Tooltip,
  Text
} from '@chakra-ui/react';

import { BsFillInfoCircleFill } from "react-icons/bs";

const ENV = process.env.NODE_ENV;
const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai'

export default function SDXLPromptInput({refreshImages}) {
  const isLoading = useSelector((state) => state.content.isLoading);
  const prompt = useSelector((state) => state.content.imagePrompt);
  const model = useSelector((state) => state.generateOptions.model);
  const imageTemplate = useSelector((state) => state.templates.imageTemplate);
  let imgSrc = useSelector((state) => state.content.base64);

  const [buttonLoading, setButtonLoading] = useState({
    optimisePrompt: false,
    downloadFile: false
  });

  const [promptSettings, setPromptSettings] = useState({
    style: '',
    negativePrompt: '',
    size: '1024x1024'
  });

  const dispatch = useDispatch();

  useEffect(()=>{
    if(imageTemplate !== 'no-template'){
      dispatch(updateImagePrompt(imageTemplate.promptTemplate))
    }
  },[imageTemplate])

  let handlePromptInputChange = (e) => {
    let inputValue = e.target.value
    dispatch(updateImagePrompt(inputValue));
  };

  let handleSubmit = async () => {
    dispatch(toggleLoading());    
    Generate('',prompt,model,promptSettings).then(response => {
      if(response === 'insufficient credit'){
        dispatch(updateCredits(false));
      }
        dispatch(updateBase64(response));
        dispatch(toggleLoading());
        //update image history state
        //wait 5 seconds to allow history to update
        setTimeout(refreshImages,10000);
    });
  }

  const downloadFile = (imgSrc) => {
    setButtonLoading({...buttonLoading, downloadFile: true});
  
    // Convert the Base64 string to a Blob
    const byteCharacters = atob(imgSrc);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const imageBlob = new Blob([byteArray], { type: 'image/png' });
  
    // Create an object URL for the Blob
    const imageObjectURL = URL.createObjectURL(imageBlob);
  
    // Create a hidden link and set the URL using the object URL
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = imageObjectURL;
    let timestamp = new Date().toString();
    link.download = `veolabs-generated-image-${timestamp}.png`;
  
    document.body.appendChild(link);
    link.click();
    
    // Cleanup the object URL and the link after the download starts
    setTimeout(() => {
      URL.revokeObjectURL(imageObjectURL);
      document.body.removeChild(link);
    }, 100); // A small delay to give time for the download to start
    
    setButtonLoading({...buttonLoading, downloadFile: false});
  };

  const handleSelectChange = (event) => {

    let key = event.target.dataset.key;
    let value = event.target.value;

    let newPromptSettings = {
      ...promptSettings,
      [key]: value
    }
    setPromptSettings(newPromptSettings);
  };

  let handleImprovePrompt = async () => {
    setButtonLoading({...buttonLoading, optimisePrompt: true});
    const response = await axios.post(
      `${api_url}/optimiseImagePrompt`,
      {prompt: prompt},
      {withCredentials: true}
      ).then((response) => {
        if(response.data === 'no user'){
          noUser();
        } else {
          return JSON.parse(response.data.content);
        }
    }).catch(err => console.log(err));

    dispatch(updateImagePrompt(response.optimisedPrompt));
    setButtonLoading({...buttonLoading, optimisePrompt: false});
  }

  const Style = () => {
    const toolTipLabel = 'Select a pre-set style to give the SDXL model better guidance when generating your image.'
    return (
        <Flex direction={'column'} gap={'10px'}>
            <Flex align={'center'}>
                <Text size='xs' as='h1'>Style</Text>
                <Tooltip label={toolTipLabel}><Flex pl={'5px'}><BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }} /></Flex></Tooltip>
            </Flex>
            <Select defaultValue={promptSettings.style} onChange={handleSelectChange} data-key='style'>
                <option value=''>-</option>
                <option value='photographic'>Photographic</option>
                <option value='analog-film'>Analog Film</option>
                <option value='cinematic'>Cinematic</option>
                <option value='digital-art'>Digital Art</option>
                <option value='3d-model'>3D Model</option>
                <option value='anime'>Anime</option>
                <option value='comic-book'>Comic Book</option>
                <option value='enhance'>Enhance</option>
                <option value='fantasy-art'>Fantasy Art</option>
                <option value='isometric'>Isometric</option>
                <option value='line-art'>Line Art</option>
                <option value='low-poly'>Low Poly</option>
                <option value='modeling-compound'>Modeling Compound</option>
                <option value='neon-punk'>Neon Punk</option>
                <option value='origami'>Origami</option>
                <option value='pixel-art'>Pixel Art</option>
                <option value='tile-texture'>Tile Texture</option>
            </Select>
        </Flex>
    )
  }

  const Size = () => {
    const toolTipLabel = 'The size of the generated image.'
    return (
        <Flex direction={'column'} gap={'10px'}>
            <Flex align={'center'}>
                <Text size='xs' as='h1'>Size</Text>
                <Tooltip label={toolTipLabel}><Flex pl={'5px'}><BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }}  /></Flex></Tooltip>
            </Flex>
            <Select defaultValue={promptSettings.size} data-key='size' onChange={handleSelectChange}>
                <option value='1024x1024'>1024x1024</option>
                <option value='1152x896'>1152x896</option>
                <option value='1216x832'>1216x832</option>
                <option value='1344x768'>1344x768</option>
                <option value='1536x640'>1536x640</option>
                <option value='640x1536'>640x1536</option>
                <option value='768x1344'>768x1344</option>
                <option value='832x1216'>832x1216</option>
                <option value='896x1152'>896x1152</option>
            </Select>
        </Flex>
    )
  } 
  const promptToolTipLabel = 'Describe the image you want SDXL to generate, be as descriptive as possible.'
  const negativePromptToolTipLabel = 'Describe the elements you DON\'t want to be present in the generated image. This helps the SDXL model optimise the quality of your image.'

  let showImprovePrompt = prompt?.length > 0 ? false : true;
  let showDownload = imgSrc?.length > 0 ? false : true;

  return (
    <Stack w='100%' direction={{ base: 'column', md: 'row' }}>
      <Flex flex={1} align={'top'} justify={'center'}>
        <Stack spacing={4} w={'full'} maxW={'md'}>
          <FormControl id="prompt">
            <Stack spacing={4} w={'full'} maxW={'md'} mb='20px'>
                <Flex direction='column'  gap={'10px'}>
                    <Flex align={'center'}>
                        <Text size='xs' as='h1'>Prompt</Text>
                        <Tooltip label={promptToolTipLabel}><Flex pl={'5px'}><BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }}/></Flex></Tooltip>
                    </Flex>
                    <Textarea
                      value={prompt}
                      onChange={handlePromptInputChange}
                      placeholder='Place your prompt here.'
                      size='sm'
                      w='100%'
                      minH="220px"
                      id={'prompt'}
                    />
                </Flex>
                <Flex direction='column'  gap={'10px'}>
                    <Flex align={'center'}>
                        <Text size='xs' as='h1'>Negative prompt</Text>
                        <Tooltip label={negativePromptToolTipLabel}><Flex pl={'5px'}><BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }}/></Flex></Tooltip>
                    </Flex>
                    <Textarea
                    data-key='negativePrompt'
                    value={promptSettings.negativePrompt}
                    onChange={handleSelectChange}
                    placeholder='Place your negative prompt here.'
                    size='sm'
                    w='100%'
                    minH="80px"
                    id={'negative-prompt'}
                    />
                </Flex>
                <Flex direction={'column'} gap={'20px'}>
                    <Style />
                    <Size />
                </Flex>
            </Stack>
          </FormControl>
          <Stack spacing={6}>
            <Flex width={'100%'} justify={'space-between'}>
              <Button w={'190px'} isLoading={buttonLoading.optimisePrompt} colorScheme={'blue'} variant={'outline'} borderColor='gray.400' onClick={handleImprovePrompt} isDisabled={showImprovePrompt}>
                {'Improve prompt'}
              </Button>
              <Button w={'190px'} isLoading={buttonLoading.downloadFile} colorScheme={'blue'} variant={'outline'} borderColor='gray.400' onClick={()=>{downloadFile(imgSrc)}} isDisabled={showDownload}>
                {'Download Image'}
              </Button>
            </Flex>
            <Button isLoading={isLoading} colorScheme={'blue'} variant={'solid'} borderColor='gray.400' onClick={handleSubmit}>
              {'Generate'}
            </Button>
          </Stack>
        </Stack>
      </Flex>
    </Stack>
  );
}
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelection } from '../../store/generateOptionsSlice';
import SelectTemplate from '../../components/image/SelectTemplate';
import SelectHistory from '../../components/image/SelectHistory';
import SelectModel from '../../components/models/SelectModel';
import { selectImageTemplate } from '../../store/templateSlice';


import {
  Box,
  VStack,
  Stack,
  Card,
  CardBody,
  Image,
  Heading,
  Text,
  Button,
  Center,
  Flex
} from '@chakra-ui/react';

import {
  HiOutlineCode
} from 'react-icons/hi';

import {
  ImImages
} from 'react-icons/im';

import {
  TfiWrite
} from 'react-icons/tfi';

import {
  PiChatsLight
} from 'react-icons/pi';

import {
  BiUserVoice
} from 'react-icons/bi';


export default function SideBar() {
  const dispatch = useDispatch();
  const model = useSelector((state) => state.generateOptions.model);
  const template = useSelector((state) => state.templates.imageTemplate);
  const selection = useSelector((state) => state.generateOptions.selection);

  const handleClick = (e)=> {
    const label = e.target.id;
    dispatch(setSelection(label));
    
  };

  const CurrentModel = () => {
    return (
      <Card
        key={model.title}
        direction={{ base: 'column', sm: 'row' }}
        overflow='hidden'
        variant='outline'
        height='270px'
        width='310px'
        boxShadow='md'
      >
        <Stack>
          <Image
              objectFit={'cover'}
              maxW='310px'
              minH='100px'
              maxH='180px'
              src={`/images/${model.icon}`}
              alt={model.title}
          />
            <CardBody>
              <Center direction='column'>
                <Heading size='sm'>Generating with {model.title}</Heading>
                  <Text py='2' fontSize='xs'>
                  </Text>
                  <SelectModel />
              </Center>
            </CardBody>
        </Stack>
      </Card>
    )
  }

  const removeTemplate = () => {
    dispatch(selectImageTemplate({}));
  }


  return (
    <Box
      backgroundColor='#fff'
      borderRight="1px"
      borderRightColor={'#f5f6fa'}
      w={'350px'}
      minW={'350px'}
      h="100%"
      pt='20px'
      overflow='scroll'
    >
      {/* <Flex
      direction='column'
      mt='30px'
      mb='40px'
      ml='20px'
      gap='20px'
      >
      <Flex 
        align='center' 
        >
        <Icon
            mr="4"
            fontSize="15"
            as={HiOutlineCode}
          />
        <Link onClick={handleClick} fontSize='15px'>
          Prompt
        </Link>
      </Flex>
      <Flex 
        align='center' 
      >
        <Icon
            mr="4"
            fontSize="15"
            as={TfiWrite}
          />
        <Link onClick={()=>{window.location.href='/generate/copy'}} fontSize='15px'>
          Text
        </Link>
      </Flex>
      <Flex 
        align='center' 
      >
        <Icon
            mr="4"
            fontSize="15"
            as={PiChatsLight}
          />
        <Link onClick={()=>{window.location.href='/generate/chat'}} fontSize='15px'>
          Chat
        </Link>
      </Flex>
      <Flex 
        align='center' 
      >
        <Icon
            mr="4"
            fontSize="15"
            as={BiUserVoice}
          />
        <Link onClick={()=>{window.location.href='/generate/voice'}} fontSize='15px'>
          Voice
        </Link>
      </Flex>
      <Flex 
        align='center' 
      >
        <Icon
            mr="4"
            fontSize="15"
            as={ImImages}
          />
        <Link onClick={()=>{window.location.href='/generate/image'}} fontSize='15px'>
          Image
        </Link>
        
      </Flex>
    </Flex> */}
    
    <VStack gap='10px' align={'center'}>
        <CurrentModel />
        <SelectTemplate />
        <SelectHistory />
    </VStack>
  </Box>
  );
}
import React from 'react';
import  ReactQuill  from  "react-quill";
import  "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";

const  modules  = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline"],
        [{ color: [] }, { background: [] }],
        ["blockquote", "code-block"],
        [{ list:  "ordered" }, { list:  "bullet" }],
        [{ align: [] }]
    ],
};

const  Quill  = () => {
    const content = useSelector((state) => state.content.transcription);
    return  <ReactQuill modules={modules} theme="snow" value={content}/>;
};

export  default  Quill;
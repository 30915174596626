import React from 'react';
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLoading, switchThinking } from '../../store/contentSlice';
import Generate from '../../promptApi/generate';
import { updateConversation, updateConversationId, updateConversationHistory } from '../../store/contentSlice';
import { updateCredits } from '../../store/userSlice';

import history from '../../data/history';

import {
  Button,
  Textarea,
  Flex,
  FormControl,
  Heading,
  Card,
  CardBody,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Image
} from '@chakra-ui/react';

import {
  RiSendPlane2Fill
} from 'react-icons/ri'
import {
  FiUser
} from 'react-icons/fi'

function Messages(props) {
  const model = useSelector((state) => state.generateOptions.model);
  const isThinking = useSelector((state) => state.content.isThinking);

  const chat = props.chat;

  const messages = chat.map((message,i) => {
    const content = message.content;
    return (
      <Flex 
        direction={'column'}
        w='100%'
        minH={'40px'}
        flexShrink={0}
        color={'#fff'}
        pt={'24px'}
        pb={'24px'}
        pl={'170px'}
        pr={'170px'}
        key={message.content + i}
        bgColor={message.role === 'user' ? '#343541' : '#454654' }
        >
        <Flex
          w={'100%'}
          gap={5}
          >
          <Box minW={30} maxW={30} minH={30} maxH={30} bgColor={model.manufacturer === 'OpenAi' || message.role === 'user' ? 'rgb(25, 195, 125)' : 'rgb(255, 255, 255)'} p={model.manufacturer === 'OpenAi' || message.role === 'user' ? '4px' : '0px'} borderRadius={2}>
            {message.role === 'user' ? <FiUser fontSize="1.35em"/> : <Image src={`/images/${model.chatIcon}`}/>}
          </Box>
          <span>
            {content}
          </span>
        </Flex>
      </Flex>)
  })
  

  if(isThinking){
    messages.push(
      <Flex 
        w='100%'
        minH={'40px'}
        flexShrink={0}
        color={'#fff'}
        pt={'24px'}
        pb={'24px'}
        pl={'170px'}
        pr={'170px'}
        key={'loader'}
        bgColor={'#454654'}
        >
        <Flex
          w={'100%'}
          gap={5}
          >
          <Box minW={30} maxW={30} minH={30} maxH={30} bgColor={model.manufacturer === 'OpenAi' ? 'rgb(25, 195, 125)' : 'rgb(255, 255, 255)'} p={model.manufacturer === 'OpenAi'? '4px' : '0px'} borderRadius={2}>
            {<Image src={`/images/${model.chatIcon}`}/>}
          </Box>
          <span>
            {'Thinking...'}
          </span>
        </Flex>
      </Flex>
    )
  }

  const revMessages = messages.reverse();

  return (
    <Flex direction={'column-reverse'}
      maxH='100%'
      w='100%'
      overflow='scroll'
      position={'absolute'}
    >
      {revMessages}
    </Flex>
  )
}

export default function Chat() {
  const isLoading = useSelector((state) => state.content.isLoading);
  const conversation = useSelector((state) => state.content.conversation);
  const conversationId = useSelector((state) => state.content.conversationId);
  const textAreaRef = useRef();
  const model = useSelector((state) => state.generateOptions.model);

  const dispatch = useDispatch();

  let [promptValue, setPromptValue] = useState({
      prompt: ''
  });

  let [systemPromptValue, setSystemPromptValue] = useState('')

  let activeCheck = promptValue.prompt !== '' ? null : 'isDisabled';
  
  let handlePromptInputChange = (e) => {
    let inputValue = e.target.value
    setPromptValue({
      prompt: inputValue
    });
  };

  let handleSystemPromptInputChange = (e) => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`; 
    let inputValue = e.target.value
    setSystemPromptValue(inputValue)
  }

  function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

  let handleSubmit = async (e) => {
    textAreaRef.current.style.height = 'auto';

    let id;

    if(conversation.length === 0){
      id = generateUUID();
      dispatch(updateConversationId(id));
    }else{
      id = conversationId;
    }

    if(!isLoading){
      dispatch(toggleLoading());
      dispatch(switchThinking(true));
      let prompt = promptValue.prompt;
      dispatch(updateConversation([1,{
        'role':'user',
        'content': prompt
      }]))

      setPromptValue({prompt: ''});

      const stateUpdate = (content,chunk) => {

        if(chunk !== '...that is all for now :)...'){
          dispatch(switchThinking(false));
          dispatch(updateConversation([2,{
            'role':'assistant',
            'content': content
          }]))
        }

        if(content === 'insufficient credit'){
          dispatch(updateCredits(false));
        }
        
      }
      const now = new Date();
      const updatedConversation = [...conversation,{'role':'user','content': prompt}];
      await Generate(systemPromptValue,updatedConversation,model,stateUpdate,'chat',id,now);
      dispatch(toggleLoading());

      const chatHistoryData = await history.getChatHistory();
      dispatch(updateConversationHistory(chatHistoryData));
    }
  }
  
  const systemPromptInput = 
  <Accordion allowToggle>
    <AccordionItem>
      <AccordionButton
        fontSize='sm' 
        bg={'gray.50'}
        >
        <Box as="span" flex='1' textAlign='left'>
          <Heading size='xs'>System Prompt</Heading>
        </Box>
        <AccordionIcon/>
      </AccordionButton>
      <AccordionPanel p={0} pt={4} pb={4}>
        <FormControl id="systemPrompt">
          <Textarea
            value={prompt}
            onChange={handleSystemPromptInputChange}
            placeholder='Place your system prompt here'
            size='sm'
            minH="200px"
          />
        </FormControl>
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
  
  let showSystemPrompt = false;
  if('promptOptions' in model){
    showSystemPrompt = model.promptOptions.systemPrompt
  }

  const props = {
    promptValue,
    setPromptValue,
  };

  const autoResize = (e) => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`; 
  };

  return (
    <Card 
      minH='100%'
      w='100%'
      variant='outline'
      boxShadow='md'
      >
      <CardBody 
        p={0}
        pb={10}
        bgColor='#343541'
        >
        <Flex 
          w={'100%'}
          h={'100%'}
          direction={'column-reverse'}
          justify={'space-between'}
          >
          <Flex
            direction='row'
            justify={'center'}
            w='100%'
            mt='30px'
            >
            <Flex
            p={'12px'}
            pl={'18px'}
            flex={1}
            align={'center'}
            direction='row'
            bgColor='#404150'
            borderRadius='15px'
            maxW='70%'
              >
              <Textarea
                autoFocus
                ref={textAreaRef}
                sx={{AcceptsReturn: "False"}}
                value={promptValue.prompt}
                onInput={autoResize}
                onChange={handlePromptInputChange}
                placeholder='Send a message'
                p={0}
                rows={1}
                overflow={'auto'}
                id={'prompt'}
                border='0'
                color='white'
                resize='none'
                maxH={'8rem'}
                minH={'1rem'}
                focusBorderColor='#404150'
                fontFamily={'sans-serif'}
                onKeyPress={e=> {
                  if (e.key === 'Enter') {
                    handleSubmit();
                    e.preventDefault();
                  }
                }}
              />
              <Button 
                colorScheme={'blue'} 
                onClick={handleSubmit} 
                color='white'
                p={0}
                ml={5}
                maxW={2}
                isDisabled={activeCheck}
                isLoading={isLoading}
                id='sendMessage'
                >
                <RiSendPlane2Fill size={24}/>
              </Button>
            </Flex> 
          </Flex>
          <Flex
            direction={'column'}
            h={'100%'}
            w={'100%'}
            position={'relative'}
            >
            <Messages chat={conversation} />
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
}
import axios from 'axios'
import noUser from '../utils/HandleNoUser'

const ENV = process.env.NODE_ENV;

const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai'

export const retrieveTemplates = async () => {
    return await axios.get(`${api_url}/data/templates/`).then((response) => {
        if(response.data === 'no user'){
            noUser();
        } else {
            return response.data;
        }
    }).catch(err => console.log(err))
}

export const createTemplate = async (template) => {
    console.log(template)
    const newTemplate = {
        ...template,
        // promptInputs: JSON.parse(template.promptInputs),
        models: template.models.split(','),
        tags: template.tags.split(','),
        platform: template.platform.split(',')
    }
    return await axios.post(`${api_url}/data/templates/create`,newTemplate).then((response) => {
        if(response.data === 'no user'){
            noUser();
        } else {
            return response.data;
        }
    }).catch(err => console.log(err))
}

export const updateTemplate = async (template) => {
    const newTemplate = {
        ...template,
        promptInputs: JSON.parse(template.promptInputs),
        tags: template.tags.split(',')
    }
    return await axios.post(`${api_url}/data/templates/update`,newTemplate).then((response) => {
        if(response.data === 'no user'){
            noUser();
        } else {
            return response.data;
        }
    }).catch(err => console.log(err))
}

export const deleteTemplate = async (template) => {
    return await axios.post(`${api_url}/data/templates/delete`,template).then((response) => {
        if(response.data === 'no user'){
            noUser();
        } else {
            return response.data;
        }
    }).catch(err => console.log(err))
}
import user from '../data/user';
import mixpanel from 'mixpanel-browser';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { updateUser, updateAuth } from '../store/userSlice';
import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Link,
    Center
  } from '@chakra-ui/react';
  
export default function Register() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    username: '',
    password: ''
  });
  const [failedAuth, setFailedAuth] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if(values.password.length < 8){
      setError('Password must be 8 characters');
    }else if (!emailRegex.test(values.username)) {
      setError('Please enter a valid email address');
    } else {
      user.register(values).then((res)=>{
        setLoading(true);
        if(res === 'User created in DB'){
          // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
          window.fbq('track', 'StartTrial');
          window.gtag('event', 'conversion', {'send_to': 'AW-11367579655/8KdtCL3etuoYEIeAvqwq'});          
          mixpanel.identify(user._id);
          mixpanel.track('Sign up', {
            'Signup Type': 'Beta'
          })

          user.authenticate(values).then((res)=>{
            if(res[0]) {
              // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
              mixpanel.track('Login', {
                'Login Type': 'Beta'
              })
              dispatch(updateUser(res[1]));
              dispatch(updateAuth(true));
              setFailedAuth(false);
              window.location.href = '/models'
            } else {
              setFailedAuth(true);
            }
            setLoading(false);
          });
        } else {
          setError(res);
          setLoading(false);
        }
      });
    }
  }

  const handleChange = (e)=>{
    const value = e.target.value;
    const key = e.target.name;
    setValues({
        ...values,
        [key]: value
    })
  }

  const errorMessage = (
    <Center pb={'30px'}>
      <Heading fontSize={'lg'} color={'red.600'}>
        {error}
      </Heading>
    </Center>
  );
  
    return (
      <Flex
        bgGradient='linear(to-t, #EBF3FD, #f3f0ff)'
        h={'100%'}
        w={'100%'}
        align={'center'}
        justify={'center'}
        >
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'} textAlign={'center'} mb={'20px'} mt={'-100px'}>
              Get started with Veolabs
            </Heading>
            <Text fontSize={'lg'} color={'gray.600'}>
              30 days and 30 credits FREE!
            </Text>
            <Text fontSize={'lg'} color={'gray.600'}>
              No credit card needed for free trial ✌️
            </Text>
          </Stack>
          <Center>
            <Box
              rounded={'lg'}
              bg={useColorModeValue('white', 'gray.700')}
              boxShadow={'lg'}
              p={8}
              w='350px'
              >
              <Stack>
                {error ? errorMessage : null}
                <form onSubmit={handleSubmit}>
                  <FormControl id="email" isRequired>
                    <FormLabel>Email address</FormLabel>
                    <Input mb={'20px'} type="email" value={values.username} name='username' autoComplete={'email'} onChange={handleChange}/>
                  </FormControl>
                  <FormControl id="password" isRequired>
                    <FormLabel>Password</FormLabel>
                    <InputGroup>
                      <Input type={showPassword ? 'text' : 'password'} value={values.password} name='password' autoComplete={'off'} onChange={handleChange}/>
                      <InputRightElement h={'full'}>
                        <Button
                          variant={'ghost'}
                          onClick={() =>
                            setShowPassword((showPassword) => !showPassword)
                          }>
                          {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <Stack spacing={10} pt={'60px'}>
                    <Button
                      type='submit'
                      bg={'blue.400'}
                      color={'white'}
                      isLoading={loading}
                      _hover={{
                        bg: 'blue.500',
                      }}>
                      Register
                    </Button>
                  </Stack>
                  <Stack pt={'30px'}>
                    <Text align={'center'}>
                      Already a user? <Link color={'blue.400'} href={'/login'}>Login</Link>
                    </Text>
                    <Text align={'center'}>
                      <Link color={'blue.400'} target="_blank" href={'https://www.veolabs.ai/legal/terms-of-service'}> Terms of use</Link>
                    </Text>
                  </Stack>
                </form>
              </Stack>
            </Box>
          </Center>
        </Stack>
      </Flex>
    );
  }
import React from 'react';
import { elevenLabsVoices } from '../../promptApi/elevenlabs';
import { useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { updateVoices, updateSelectedVoice, toggleVoiceIsLoaded } from '../../store/contentSlice';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Text  
} from '@chakra-ui/react'

import {
FiPlayCircle
} from 'react-icons/fi'

export default function VoiceSelection() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useDispatch();
    const voices = useSelector((state) => state.content.voices);

    const getVoices = async () => {
        const voiceData = await elevenLabsVoices();
        dispatch(updateVoices(voiceData.voices));
        dispatch(updateSelectedVoice(voiceData.voices[0]));
    }

    if(voices.length <= 0){
        getVoices();
        return <Button onClick={onOpen}>Loading voices</Button>
    }

    const handlePlay = (url) => {
       new Audio(url).play();
    }

    const handleSelectVoice = (voice) => {
        dispatch(updateSelectedVoice(voice));
        onClose();
    }

    const RenderedVoices = voices[0].map((voice) => {
        const labels = voice.labels;
        const renderedLabels = [
            <Td textAlign={'center'} onClick={() => {handleSelectVoice(voice)}} key={`accent-${voice.voice_id}`}>{labels.accent || labels['accent ']}</Td>,
            <Td textAlign={'center'} onClick={() => {handleSelectVoice(voice)}} key={`age-${voice.voice_id}`}>{labels.age}</Td>,
            <Td textAlign={'center'} onClick={() => {handleSelectVoice(voice)}} key={`description-${voice.voice_id}`}>{labels.description || labels['description ']}</Td>,
            <Td textAlign={'center'} onClick={() => {handleSelectVoice(voice)}} key={`gender-${voice.voice_id}`}>{labels.gender}</Td>,
            <Td textAlign={'center'} onClick={() => {handleSelectVoice(voice)}} key={`use-${voice.voice_id}`}>{labels['use case'] || labels['usecase']}</Td>
        ];
        return (
            <Tr _hover={{ bg: "gray.100", cursor: "pointer" }} key={`${voice.voice_id}voiceButton`}>
                <Td textAlign={'center'} onClick={() => {handlePlay(voice.preview_url)}} key={`preview-${voice.voice_id}`}><FiPlayCircle fontSize={'1.4rem'} color='green'/></Td> 
                <Td textAlign={'center'} onClick={() => {handleSelectVoice(voice)}} key={`name-${voice.voice_id}`}><Text as='b'>{voice.name}</Text></Td>
                {renderedLabels}
            </Tr>
        )
    })

    return (
      <>
        <Button w={'200px'} onClick={onOpen}>Select voice</Button>
  
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent minW='1000px'>
            <ModalHeader ></ModalHeader>
            <ModalCloseButton />
            <ModalBody maxH='700px' overflow={'scroll'}>
                <TableContainer>
                    <Table variant='simple' size='sm'>
                        <Thead>
                            <Tr height='60px'>
                                <Th textAlign={'center'} key='Preview'>Preview</Th>
                                <Th textAlign={'center'} key='Name'>Name</Th>
                                <Th textAlign={'center'} key='Accent'>Accent</Th>
                                <Th textAlign={'center'} key='Age'>Age</Th>
                                <Th textAlign={'center'} key='Description'>Description</Th>
                                <Th textAlign={'center'} key='Gender'>Gender</Th>
                                <Th textAlign={'center'} key='useCase'>Use case</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {RenderedVoices}
                        </Tbody>
                    </Table>
                </TableContainer>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }
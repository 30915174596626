import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VeoWriterSettings from '../../agents/veowriter/Settings';
import ActionLogOverlay from '../../agents/veowriter/ActionOverlay';
import Loading from '../../components/Loading';
import history from '../../data/history';
import { updateImageHistory } from '../../store/contentSlice';
import Quill from '../../agents/veowriter/Quill';

import { 
  Flex, 
  Card,
  CardBody,
  Heading
} from '@chakra-ui/react'

function VeoWriter(){
  const { innerWidth: width} = window;
  const [dataLoading, setDataLoading] = useState(true);
  const [maxHeight, setMaxHeight] = useState('90%'); // Default value

  const updateMaxHeight = () => {
    const viewportHeight = window.innerHeight;
    const newMaxHeight = `${viewportHeight - 65}px`; // Calculate new max height
    setMaxHeight(newMaxHeight);
  };

  useEffect(() => {
    updateMaxHeight(); // Set initial value
    window.addEventListener('resize', updateMaxHeight); // Update value on window resize

    // Cleanup listener when component unmounts
    return () => window.removeEventListener('resize', updateMaxHeight);
  }, []); // Only run on mount and unmount

  useEffect(() => {
    setTimeout(()=>{
      setDataLoading(false);
    }, 1000)
  }, []);

  if (width <= 900){
    return (
        <Flex
            justify={'center'}
            align={'center'}
            p={'20px'}
            >
            <Heading size={'sm'}>
                Sorry we don't currently support screen sizes below 900px
            </Heading>
        </Flex>
    )
  }

  if (dataLoading) {
    return <Loading />
  }

  return (
    <Flex 
      w='100%' 
      h='100%'
      bgGradient='linear(to-t, #EBF3FD, #f3f0ff)'
      >
        <Flex 
          p='20px'
          gap='20px'
          maxH={maxHeight}
          w='100%'
          h='100%'
          >
          <Card 
            w='100%'
            minW='650px'
            variant='outline'
            boxShadow='md'
            overflow='scroll'
            flex='1'
            p={'0px'}
            >
            <CardBody
              p={'0px'}
              pb={'-1px'}
            >
              <VeoWriterSettings/>
            </CardBody>
          </Card>
          <Card 
            h='100%'
            w='100%'
            overflow='hidden'
            variant='outline'
            boxShadow='md'
            flex='2'
            pb={'10px'}
            bgColor='#313138'
            >
            <CardBody
              bgColor={'#313138'}
            >
              <Quill maxH={maxHeight}/>
            </CardBody>
          </Card>
        </Flex>  
        <ActionLogOverlay />   
    </Flex>      
  )
}

export default VeoWriter;
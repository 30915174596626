import React from 'react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { 
    Card, 
    Image,
    CardBody,
    Heading,
    CardFooter,
    Button,
    Flex,
    Text,
    Badge,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverBody,
    List,
    ListItem,
    Checkbox
} from '@chakra-ui/react';
import {
    ChevronDownIcon
} from '@chakra-ui/icons'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { retrieveTemplates } from "../data/templates";
import { updateTemplates, templateIsLoaded, selectTemplate } from '../store/templateSlice';
import { setSelection } from '../store/generateOptionsSlice';
import Loading from '../components/Loading';

function Templates(){
    let [selections, setSelections] = useState({
        platform: ['Google','Amazon','Facebook','Instagram','Twitter','Youtube','LinkedIn','Email','Website','Platform independent'],
        tags: ['Ads','Creativity','Copy Writing','Translation','Interpretation','Blog / Article','SEO','Social','Ecommerce'],
    });

    const templateList = useSelector((state) => state.templates.templateList);
    const templatesLoaded = useSelector((state) => state.templates.templatesLoaded);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { innerWidth: width} = window;

    if(width <= 800){
        return (
            <Flex
                justify={'center'}
                align={'center'}
                p={'20px'}
                >
                <Heading size={'sm'}>
                    Sorry we don't currently support screen sizes below 800px
                </Heading>
            </Flex>
        )
    }
    
    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;
        
        // While there remain elements to shuffle.
        while (currentIndex > 0) {
        
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
        
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
        
        return array;
    }

    if(!templatesLoaded) {
        retrieveTemplates().then(templateList => {
            dispatch(updateTemplates(shuffle(templateList)));
            dispatch(templateIsLoaded());
        });
        return <Loading />
    }

    const handleFilterChange = (e)=>{
        const value = e.target.labels[0].innerText;
        const checked = e.target.checked;
        const category = e.target.labels[0].dataset.category;
        if(checked){
            const updatedSelection = [...selections[category],value]
            setSelections({...selections, [category]: updatedSelection});
        } else {
            const index = selections[category].indexOf(value);
            if(index > -1){
                const updatedSelection = [...selections[category]]
                updatedSelection.splice(index,1);
                setSelections({...selections, [category]: updatedSelection});
            }
        }
    };

    const filters = (
        <Flex mt='40px' mb='20px' pl='40px' justify='left' gap='40px'>
            <Popover placement='top-start' >
                <PopoverTrigger >
                    <Button
                        variant='outline'
                        _hover={{ bg: '#fff' }}
                    >Platform<ChevronDownIcon boxSize='2rem' pl='10px' color='gray.500'/></Button>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                    <List>
                        <ListItem><Checkbox data-category='platform' defaultChecked mr='10px' onChange={handleFilterChange}>Google</Checkbox></ListItem>
                        <ListItem><Checkbox data-category='platform' defaultChecked mr='10px' onChange={handleFilterChange}>Amazon</Checkbox></ListItem>
                        <ListItem><Checkbox data-category='platform' defaultChecked mr='10px' onChange={handleFilterChange}>Facebook</Checkbox></ListItem>
                        <ListItem><Checkbox data-category='platform' defaultChecked mr='10px' onChange={handleFilterChange}>Instagram</Checkbox></ListItem>
                        <ListItem><Checkbox data-category='platform' defaultChecked mr='10px' onChange={handleFilterChange}>Twitter</Checkbox></ListItem>
                        <ListItem><Checkbox data-category='platform' defaultChecked mr='10px' onChange={handleFilterChange}>Youtube</Checkbox></ListItem>
                        <ListItem><Checkbox data-category='platform' defaultChecked mr='10px' onChange={handleFilterChange}>LinkedIn</Checkbox></ListItem>
                        <ListItem><Checkbox data-category='platform' defaultChecked mr='10px' onChange={handleFilterChange}>Email</Checkbox></ListItem>
                        <ListItem><Checkbox data-category='platform' defaultChecked mr='10px' onChange={handleFilterChange}>Website</Checkbox></ListItem>
                        <ListItem><Checkbox data-category='platform' defaultChecked mr='10px' onChange={handleFilterChange}>Platform independent</Checkbox></ListItem>
                    </List>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
            <Popover placement='top-start' >
                <PopoverTrigger >
                    <Button
                        variant='outline'
                        _hover={{ bg: '#fff' }}
                    >Tags<ChevronDownIcon boxSize='2rem' pl='10px' color='gray.500'/></Button>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                        <List>
                            <ListItem><Checkbox data-category='tags' defaultChecked mr='10px' onChange={handleFilterChange}>Ads</Checkbox></ListItem>
                            <ListItem><Checkbox data-category='tags' defaultChecked mr='10px' onChange={handleFilterChange}>Creativity</Checkbox></ListItem>
                            <ListItem><Checkbox data-category='tags' defaultChecked mr='10px' onChange={handleFilterChange}>Copy Writing</Checkbox></ListItem>
                            <ListItem><Checkbox data-category='tags' defaultChecked mr='10px' onChange={handleFilterChange}>Translation</Checkbox></ListItem>
                            <ListItem><Checkbox data-category='tags' defaultChecked mr='10px' onChange={handleFilterChange}>Interpretation</Checkbox></ListItem>
                            <ListItem><Checkbox data-category='tags' defaultChecked mr='10px' onChange={handleFilterChange}>Blog / Article</Checkbox></ListItem>
                            <ListItem><Checkbox data-category='tags' defaultChecked mr='10px' onChange={handleFilterChange}>SEO</Checkbox></ListItem>
                            <ListItem><Checkbox data-category='tags' defaultChecked mr='10px' onChange={handleFilterChange}>Social</Checkbox></ListItem>
                            <ListItem><Checkbox data-category='tags' defaultChecked mr='10px' onChange={handleFilterChange}>Ecommerce</Checkbox></ListItem>

                        </List>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Flex>
    )

    const renderedModels = templateList.map(template => {
        let filteredPlatform = false;
        let filteredTags = false;
        
        template.tags.map(tag => {
            if(selections.tags.includes(tag)) {
                filteredTags = true;
            }
        });

        if(!filteredTags){
            return;
        }

        template.platform.map(platform => {
            if(selections.platform.includes(platform)) {
                filteredPlatform = true;
            }
        });

        if(!filteredPlatform){
            return;
        }

        const handleClick = ()=> {
            dispatch(selectTemplate(template)); 
            dispatch(setSelection('Templates')); 
            navigate('/generate/copy')
        };

        const renderedTags = template.tags.map(tag => {
            return <Badge variant='solid' mt='10px' mr='10px' key={tag}>{tag}</Badge>
        });
        
        return <Card
            key={template._id}
            direction={{ base: 'column', sm: 'row' }}
            overflow='hidden'
            variant='outline'
            height='250px'
            width='350px'
            margin='30px'
            boxShadow='md'
        
        >
            <Flex direction='column' justify='space-between' align='center' w='100%'>
                <Flex justify='space-between'>
                    <Image
                        boxSize='100px'
                        padding='20px'
                        src={`/images/${template.imgPath}`}
                        alt={template.name}
                    />
                    <CardBody pb='0px'>
                        <Heading size='md'>{template.name}</Heading>
                        <Text py='2' fontSize='sm'>
                        {template.description}
                        </Text>
                    </CardBody>
                </Flex>
                <Flex margin='auto'>
                    {renderedTags}
                </Flex>
                <CardFooter>
                    <Button variant='solid' colorScheme='blue' onClick={handleClick}>
                        Generate
                    </Button>
                </CardFooter>
            </Flex>
        </Card>
    })
    
    return (
        <Flex direction="column" width='100%' sx={{userSelect: 'none'}}>
            {filters}
            <Flex wrap='wrap' justify='center' maxWidth='1400px' margin='auto'>
                {renderedModels}
            </Flex>
        </Flex>
    )
}

export default Templates;
import React from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import ModelsView from './ModelSelectionView';

import { 
    useDisclosure,
    Button,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    ModalContent,
}  from '@chakra-ui/react'

function SelectModel({model}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    return (
      <>
        <Button 
          onClick={onOpen}
          variant='outline' 
          colorScheme='blue'
          w='130px'
          size='xs'
          p={4}
          pl={8}
          pr={8}
        >
          Change Model
        </Button>
        <Modal
          isCentered
          onClose={onClose}
          isOpen={isOpen}
          motionPreset='slideInBottom'
        >
          <ModalOverlay />
          <ModalContent minW={'1200px'}>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />
            <ModalBody maxH={'500px'} minH={'500px'} overflow='scroll'>
              <ModelsView closeFunc={onClose}/>
            </ModalBody >
            <ModalFooter justifyContent='right' margin='15px'>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default SelectModel;
import { 
    CircularProgress,
    Center
} from '@chakra-ui/react'

export default function Loading(){
    return (
        <Center h='100vh'>
            <CircularProgress isIndeterminate color='blue.300' size='100px' thickness='4px'/>
        </Center>
    )
}

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  useToast,
  Flex,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  VStack,
  Text,
  Spinner
} from "@chakra-ui/react";

import { LiaSave } from "react-icons/lia";
const ENV = process.env.NODE_ENV;
const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai';

const SaveSettings = ({ options, showImprovePrompt }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [settingsName, setSettingsName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (!isOpen) {
      setSettingsName('');
    }
  }, [isOpen]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${api_url}/settings/create`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          settings: options,
          name: settingsName,
          agent: 'youtube-writer'
        }),
      });

      const result = await response.json();

      if (response.ok) {
        toast({
          title: 'Settings saved',
          description: 'Your settings have been successfully saved.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else if (response.status === 409) {
        toast({
          title: 'Duplicate Name',
          description: result.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error(result.message || 'Something went wrong');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message || 'Something went wrong',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        onClick={onOpen}
        w="120px"
        variant="outline"
        colorScheme="blue"
        borderColor="gray.400"
        isDisabled={showImprovePrompt}
        leftIcon={<LiaSave />}
        p={0} m={0}
      >Save</Button>
      <SaveSettingsPopup
        isOpen={isOpen}
        onClose={onClose}
        settingsName={settingsName}
        setSettingsName={setSettingsName}
        isLoading={isLoading}
        handleSave={handleSave}
      />
    </>
  );
};

const SaveSettingsPopup = ({
  isOpen,
  onClose,
  settingsName,
  setSettingsName,
  isLoading,
  handleSave,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Save Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Input
              placeholder="Enter a name for your settings"
              value={settingsName}
              onChange={(e) => setSettingsName(e.target.value)}
            />
            {isLoading && (
              <Flex align="center" justify="center" height="100%" direction="column" gap="10px" marginTop="20px">
                <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                <Text>Saving your settings...</Text>
              </Flex>
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave} isDisabled={!settingsName || isLoading}>
            Save
          </Button>
          <Button
            variant="ghost"
            onClick={onClose}
            isDisabled={isLoading}
            sx={{
              bg: 'gray.100',
              _hover: {
                bg: 'gray.200',
              }
            }}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SaveSettings;
import React, { useEffect, useRef, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { Flex, Box, Spinner, Center } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { marked } from 'marked';
import DocumentOptions from './DocumentOptions';

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline'],
    [{ color: [] }, { background: [] }],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const initialContent = ``;

const Quill = ({ maxH }) => {
  const isLoadingYouTubeWriter = useSelector((state) => state.content.isLoadingYouTubeWriter);
  const rawContent = useSelector((state) => state.content.YouTubeWritercontent);
  const [content, setContent] = useState('');
  const [displayedContent, setDisplayedContent] = useState('');
  const [index, setIndex] = useState(0);
  const [maxHeight, setMaxHeight] = useState('90%'); // Default value
  const contentContainerRef = useRef(null); // Ref for the Box component
  const dispatch = useDispatch();
  const html = marked(rawContent);

  const createMarkup = () => {
    return { __html: displayedContent };
  };

  const updateMaxHeight = () => {
    const viewportHeight = window.innerHeight;
    const newMaxHeight = `${viewportHeight - 185}px`; // Calculate new max height
    setMaxHeight(newMaxHeight);
  };

  useEffect(() => {
    updateMaxHeight(); // Set initial value
    window.addEventListener('resize', updateMaxHeight); // Update value on window resize

    // Cleanup listener when component unmounts
    return () => window.removeEventListener('resize', updateMaxHeight);
  }, []); // Only run on mount and unmount

  useEffect(() => {
    if (rawContent.length === 0) {
      setContent('');
      setDisplayedContent('');
      setIndex(0);
    }
  }, [rawContent]);

  useEffect(() => {
    if (isLoadingYouTubeWriter) {
      // Reset the content and index completely when a new load starts
      setContent('');
      setDisplayedContent('');
      setIndex(0);
    }
  }, [isLoadingYouTubeWriter]);

  useEffect(() => {
    if (!isLoadingYouTubeWriter) {
      // Directly set the full content when loading is complete
      setContent(html);
      setDisplayedContent(html);
      setIndex(html.length); // Ensure the typing effect is skipped
    } else {
      // Handle the typing effect during loading
      const doc = new DOMParser().parseFromString(html, 'text/html');
      const iframeElements = doc.querySelectorAll('iframe');

      // Replace iframes with placeholders in the content
      iframeElements.forEach((iframe, index) => {
        //iframe.replaceWith(document.createTextNode(`{{YOUTUBE_VIDEO_PLACEHOLDER_${index}}}`));
        const placeholderSpan = `
          <span class='video-placeholder' data-placeholder-index='${index}' style='display: flex; justify-content: center; align-items: center; border: 1px solid #ccc; padding: 10px; width: 100%; margin: auto;'>
            <div>
              <span>Selecting video...</span>
            </div>
          </span>`;
        const fragment = document.createRange().createContextualFragment(placeholderSpan); // createContextualFragment to create elements with classes
        iframe.replaceWith(fragment);
      });

      const cleanHtml = doc.body.innerHTML;

      setContent(cleanHtml);
    }
  }, [isLoadingYouTubeWriter, rawContent]);

  useEffect(() => {
    if (isLoadingYouTubeWriter && index < content.length) {
      const typingTimeout = setTimeout(() => {
        setDisplayedContent(prev => prev + content[index]);
        setIndex(index + 1);
      }, 1); // Adjust the typing speed by changing the delay (in milliseconds)

      return () => clearTimeout(typingTimeout);
    }
  }, [index, isLoadingYouTubeWriter, content]);

  useEffect(() => {
    // Autoscroll to the bottom ONLY when typing is occurring (isLoadingYouTubeWriter is true)
    if (isLoadingYouTubeWriter && contentContainerRef.current) {
      contentContainerRef.current.scrollTop = contentContainerRef.current.scrollHeight;
    }
  }, [displayedContent, isLoadingYouTubeWriter]);

  function countWordsInHTML(htmlString) {
    // Create a temporary DOM element to parse the HTML
    var tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;

    // Get the text content of the element, which strips out the HTML tags
    var textContent = tempElement.textContent || tempElement.innerText || '';

    // Split the text content into an array of words
    var wordsArray = textContent.trim().split(/\s+/);

    // Get the number of words
    var wordCount = wordsArray.length;

    // Format the word count with commas for thousands
    var formattedWordCount = wordCount.toLocaleString();

    // Return the formatted word count
    return formattedWordCount;
  }

  let length = countWordsInHTML(displayedContent);

  return (
    <Flex direction="column" height="100%" bgColor='#313138'>
      <DocumentOptions content={content} length={length}/>
      <Box
        ref={contentContainerRef}
        bgColor='#313138'
        id='veoWriterHTMLContainer'
        p={5}
        maxHeight={maxHeight}
        flex="1"
        overflowY="scroll"
      >
        <div dangerouslySetInnerHTML={createMarkup()} />
        {isLoadingYouTubeWriter && index >= content.length && (
          <Center mt={2}>
            <Spinner size="lg" />
          </Center>
        )}
      </Box>
    </Flex>
  )
};

export default Quill;
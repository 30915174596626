import React from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Templates from './TemplateSelectionView';

import { 
    useDisclosure,
    Button,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    ModalContent,
}  from '@chakra-ui/react'


function SelectTemplate({model}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const template = useSelector((state) => state.templates.template);
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    return (
      <>
        <Button 
          onClick={onOpen}
          variant='outline' 
          colorScheme='blue'
          w='130px'
          size='xs'
          p={4}
          pl={8}
          pr={8}
        >
          {template.name ? 'Change Template' : 'Select Template'}
        </Button>
        <Modal
          isCentered
          onClose={onClose}
          isOpen={isOpen}
          motionPreset='slideInBottom'
        >
          <ModalOverlay />
          <ModalContent minW={'1200px'}>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />
            <ModalBody maxH={'750px'} minH={'750px'} overflow='scroll'>
              <Templates closeFunc={onClose}/>
            </ModalBody >
            <ModalFooter justifyContent='right' margin='15px'>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default SelectTemplate;
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTranscription, toggleLoading } from '../../store/contentSlice';
import axios from 'axios';
import {
  Button,
  Input,
  Flex,
  FormControl,
  Stack,
  Text,
  Box,
  VStack,
  useToast,
  CloseButton
} from '@chakra-ui/react';

import { IoCloudUploadOutline } from "react-icons/io5";

import noUser from '../../utils/HandleNoUser'

const ENV = process.env.NODE_ENV;

const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai'

export default function PromptInput() {
  const isLoading = useSelector((state) => state.content.isLoading);
  const transcription = useSelector((state) => state.content.transcription);
  const [dragging, setDragging] = useState(false);
  const [optimisationLoading, setOptimisationLoading] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();

  const [file, setFile] = useState(null);

  const onDrop = useCallback((event) => {
    event.preventDefault();
    setDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      // The correct MIME type for MP3 files is 'audio/mpeg'
      if (file.type === 'audio/mpeg') {
        setFile(file);
      } else {
        toast({
          title: 'Invalid file type',
          description: 'Please upload an MP3 file.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [toast]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    setDragging(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setDragging(false);
  }, []);

  const openFileDialog = () => {
    const fileInput = document.getElementById('file-upload-input');
    fileInput && fileInput.click();
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!isLoading && file) {
      dispatch(toggleLoading());
      const formData = new FormData();
      formData.append('audio', file);
      try {
        const response = await axios.post(`${api_url}/whisper`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          withCredentials: true
        });
        const transcription = response.data;
        dispatch(updateTranscription(transcription));
      } catch (error) {
        console.log('Error uploading file: ', error);
      } finally {
        dispatch(toggleLoading());
      }
    }
  };

  let showOptimise = transcription.length > 0;

  const handleOptimise = async () => {
    if (!isLoading) {
      setOptimisationLoading(true);
      try {
        dispatch(updateTranscription(''));
        let content = '';
        const body = {
          "transcription": transcription
        }
        const response = await fetch(`${api_url}/optimiseTranscription`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body)
        });
      
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8')
        const loopRunner = true;
        while (loopRunner) {
          // Here we start reading the stream, until its done.
          const { value, done } = await reader.read();
          if (done) {
            break;
          }
          const chunkString = decoder.decode(value, { stream: true })
          const decodedChunk = decoder.decode(value, { stream: true });
          content += decodedChunk;
          if(chunkString === 'no user'){
            noUser()
          } else {
            dispatch(updateTranscription(content));
          }
        }
        } catch (error) {
          console.log('Error uploading file: ', error);
        } finally {
          setOptimisationLoading(false);
      }
    }
  }

  const handleFileRemove = () => {
    setFile(null); // Clear the selected file
  };

  const fileSize = (size) => {
    if (size < 1024) return size + ' bytes';
    else if (size >= 1024 && size < 1048576) return (size / 1024).toFixed(1) + ' KB';
    else if (size >= 1048576) return (size / 1024 / 1024).toFixed(1) + ' MB';
  };

  return (
    <Stack minW={'400px'} direction={{ base: 'column', md: 'row' }}>
      <Flex p={4} flex={1} align={'top'} justify={'center'}>
        <Stack spacing={4} w={'full'} maxW={'md'} pt={'30px'}>
          <FormControl id="file-upload">
            {/* Drag and Drop Area */}
            <VStack
              p={18} 
              pb={'40px'}
              pt={'40px'}
              borderWidth={2}
              borderRadius="md"
              borderStyle="dashed"
              borderColor={dragging ? 'blue.300' : 'gray.300'}
              bg={dragging ? 'blue.50' : 'transparent'}
              _hover={{ bg: 'blue.50' }}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              onClick={openFileDialog}
              cursor="pointer"
              align="center"
              justify="center"
              spacing={3}
            >
              <Text pb={'30px'}>Drop your MP3 file here, or click to browse</Text>
              <Button variant="outline" colorScheme="blue" leftIcon={<IoCloudUploadOutline fontSize={30}/>}>
                Upload MP3
              </Button>
            </VStack>

            <Input
              type="file"
              accept=".mp3"
              onChange={handleFileChange}
              size="md"
              id="file-upload-input"
              hidden
            />

            {/* Conditional rendering for file information */}
            {file && (
              <Flex mt={8} justify="space-between" align="center">
                <Box>
                  <Text fontWeight="bold">{file.name}</Text>
                  <Text fontSize="sm">Size: {fileSize(file.size)}</Text>
                </Box>
                <CloseButton onClick={handleFileRemove} />
              </Flex>
            )}

          </FormControl>
          {/* ...existing Flex with Buttons... */}
          <Flex gap='10px' w='100%' justify={'center'} pt='40px'>
            <Button isLoading={isLoading} colorScheme={'blue'} variant={'solid'} onClick={handleSubmit} isDisabled={!file} w='100%'>
              {'Transcribe'}
            </Button>
            <Button colorScheme={'blue'} variant={'outline'} onClick={handleOptimise} isDisabled={!showOptimise} isLoading={optimisationLoading} w='100%'>
              {'Optimise'}
            </Button>
          </Flex>
        </Stack>
      </Flex>
    </Stack>
  );
}
import { configureStore } from '@reduxjs/toolkit';
import contentSlice from './contentSlice';
import templateSlice from './templateSlice';
import generateOptions from './generateOptionsSlice';
import modelSlice from './modelSlice';
import userSlice from './userSlice';
import agentSlice from './agentSlice';

export const store = configureStore({
    reducer: {
        content: contentSlice,
        models: modelSlice,
        agents: agentSlice,
        generateOptions: generateOptions,
        templates: templateSlice,
        user: userSlice
    },
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
});
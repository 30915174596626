import React from 'react';
import { useSelector } from 'react-redux';

import {
  Flex,
  Heading,
  Stack,
} from '@chakra-ui/react';

export default function Favourites() {

  const model = useSelector((state) => state.generateOptions.model);
  
  return (
    <Stack minH={'100vh'} minW={'400px'} direction={{ base: 'column', md: 'row' }}>
      <Flex p={4} flex={1} align={'top'} justify={'center'}>
        <Stack spacing={4} w={'full'} maxW={'md'}>
          <Heading fontSize={'2xl'}>Favourites with {model.title}</Heading>
        </Stack>
      </Flex>
    </Stack>
  );
}
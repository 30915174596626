import axios from 'axios'
import mixpanel from 'mixpanel-browser'
import noUser from '../utils/HandleNoUser'

export default async function Generate(systemPromptValue, prompt, model, settings) {

    // Track an event. It can be anything, but in this example, we're tracking a prompt sent event.
    mixpanel.track('Prompt sent', {
        'Model': model.title
    });

    const apiRoute = model.api.route;

    const ENV = process.env.NODE_ENV;

    const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai';
    const now = new Date();

    let config = {
        headers: {
          'Content-Type': 'application/json',
          'timestamp': now
        },
        withCredentials: true,
      }

    return await axios.post(`${api_url}${apiRoute}`,{systemPromptValue,prompt,settings},config).then((response) => {
        if(response.data === 'no user'){
            noUser();
        } else {
        return response.data.image
    }}).catch(err => console.log(err))
}

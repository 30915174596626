// Import the editor styles
import '@pqina/pintura/pintura.css';
import { useSelector } from "react-redux";
import { useRef } from 'react';

// Import the editor default configuration
import { getEditorDefaults } from '@pqina/pintura';

// Import the editor component from `react-pintura`
import { PinturaEditor } from '@pqina/react-pintura';

// get default properties
const editorConfig = getEditorDefaults();

function ImageEditor() {
    let imgSrc = useSelector((state) => state.content.base64);
    if(imgSrc === ""){
        // imgSrc = "/enterPrompt.png";
    } else {
        imgSrc = `data:image/png;base64,${imgSrc}`;
    }

    const downloadFile = (file) => {
        // Create a hidden link and set the URL using createObjectURL
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = URL.createObjectURL(file);
        let timestamp = new Date().toString();
        link.download = `veolabs-generated-image-${timestamp}`;
    
        // We need to add the link to the DOM for "click()" to work
        document.body.appendChild(link);
        link.click();
    
        // To make this work on Firefox we need to wait a short moment before clean up
        setTimeout(() => {
            URL.revokeObjectURL(link.href);
            link.parentNode.removeChild(link);
        }, 0);
    };

    const handleEditorProcess = (imageState) => {
        downloadFile(imageState.dest);
    };

    const editorRef = useRef(null);
    return (
        <div style={{ height: '100%', width: '100%'}}>
            <PinturaEditor
                ref={editorRef}
                {...editorConfig}
                src={imgSrc}
                imageCropAspectRatio={1}
                onProcess={handleEditorProcess}
            ></PinturaEditor>
        </div>
    );
}

export default ImageEditor;
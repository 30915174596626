import noUser from '../../utils/HandleNoUser'
import mixpanel from 'mixpanel-browser';
export default async function GenerateYouTubeWriter(options,actionName){

    const ENV = process.env.NODE_ENV;

    const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai';

    const apiRoute = `/agents/youtubewriter`;

    const body = {
        options,
        actionName
    }

    const response = await fetch(`${api_url}${apiRoute}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    });

    return response;
}
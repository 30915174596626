import React, { useEffect, useRef, useState } from 'react';
import Router from './routing/Router';
import user from './data/user';
import { useDispatch } from 'react-redux';
import { updateUser, updateAuth } from './store/userSlice';
import mixpanel from 'mixpanel-browser';
import Hotjar from '@hotjar/browser';
import { Flex } from '@chakra-ui/react';
//import WebSocketContext from './WebsocketContext';

const ENV = process.env.NODE_ENV;

const api_url = ENV === 'development' ? 'ws://localhost:4000' : 'wss://server.veolabs.ai'

const siteId = 3639553;
const hotjarVersion = 6;
const WEBSOCKET_URL = api_url; // Replace with your WebSocket server URL
const MAX_RECONNECT_ATTEMPTS = 5;

Hotjar.init(siteId, hotjarVersion);
if (user._id) {
  Hotjar.identify(user._id);
}

mixpanel.init('d534bc57dce9c9e5edc996342298e0c0', {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
});

const App = () => {
  const dispatch = useDispatch();
  const reconnectAttempts = useRef(0);
  const [ws, setWs] = useState(null);

  useEffect(() => {
    const { innerWidth: width } = window;
    if (width <= 830) {
      console.log('screen is too narrow');
    }

    user.get().then((activeUser) => {
      if (activeUser) {
        dispatch(updateUser(activeUser));
        dispatch(updateAuth(true));
        localStorage.setItem('loggedIn', true);

        // Configure Intercom settings if needed here...

      } else {
        dispatch(updateAuth(false));
        localStorage.setItem('loggedIn', false);
      }
    });

    // Function to initialize a new WebSocket connection
    // const connectWebSocket = () => {
    //   if (reconnectAttempts.current >= MAX_RECONNECT_ATTEMPTS) {
    //     console.log('Max WebSocket reconnect attempts reached');
    //     return;
    //   }

    //   const newWs = new WebSocket(WEBSOCKET_URL);

    //   newWs.onopen = () => {
    //     console.log('WebSocket connection established');
    //     reconnectAttempts.current = 0; // Reset reconnect attempts on successful connection
    //   };

    //   newWs.onmessage = (event) => {
    //     const message = JSON.parse(event.data);
    //     console.log('Message from server:', message);
    //     // Handle message
    //   };

    //   newWs.onerror = (error) => {
    //     console.error('WebSocket error:', error);
    //     // Handle error (for example, you could increase reconnectAttempts here if needed)
    //   };

    //   newWs.onclose = (event) => {
    //     console.log('WebSocket connection closed', event);
    //     if (!event.wasClean) {
    //       console.log('Reconnecting WebSocket...');
    //       reconnectAttempts.current++;
    //       setTimeout(connectWebSocket, 3000); // Try reconnecting after a delay
    //     }
    //   };

    //   setWs(newWs);
    // };

    // // Initialize WebSocket connection
    // connectWebSocket();

    // Cleanup function for WebSocket, invoked when component unmounts
    // return () => {
    //   if (ws) {
    //     ws.close();
    //   }
    // };
  }, [dispatch]);

  return (
    //<WebSocketContext.Provider value={ws}>
      <Flex w="100%" h="100%" justify={'start'} direction={'column'} sx={{ whiteSpace: 'pre-wrap' }}>
        <Flex w="100%" h="100%" justify={'center'} mt='60px'>
          <Router />
        </Flex>
      </Flex>
    //</WebSocketContext.Provider>
  );
};

export default App;
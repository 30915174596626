import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTranscription, toggleLoading } from '../../store/contentSlice';
import axios from 'axios';
import {
  Button,
  Input,
  Flex,
  FormControl,
  Stack,
  Text,
  Box,
  VStack,
  useToast,
  CloseButton
} from '@chakra-ui/react';

import { IoCloudUploadOutline, IoCloudDownloadOutline } from "react-icons/io5";

import noUser from '../../utils/HandleNoUser'

const ENV = process.env.NODE_ENV;

const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai'

export default function UploadFile({setFile, file}) {
  const isLoading = useSelector((state) => state.content.isLoading);
  const transcription = useSelector((state) => state.content.transcription);
  const [dragging, setDragging] = useState(false);
  const [optimisationLoading, setOptimisationLoading] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();
  
  const onDrop = useCallback((event) => {
    event.preventDefault();
    setDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      if (file.type === 'text/csv') {
        setFile(file);
      } else {
        toast({
          title: 'Invalid file type',
          description: 'Please upload a CSV file.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [toast]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    setDragging(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setDragging(false);
  }, []);

    const openFileDialog = () => {
        const fileInput = document.getElementById('file-upload-input');
        if (fileInput) {
            fileInput.click();
        } else {
        }
    };  

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileRemove = () => {
    setFile(null); // Clear the selected file
  };

  const fileSize = (size) => {
    if (size < 1024) return size + ' bytes';
    else if (size >= 1024 && size < 1048576) return (size / 1024).toFixed(1) + ' KB';
    else if (size >= 1048576) return (size / 1024 / 1024).toFixed(1) + ' MB';
  };

  return (
    <Stack w='100%' direction={{ base: 'column', md: 'row' }}>
      <Flex w='100%' pt={4} flex={1} align={'top'} justify={'center'}>
        <Stack spacing={4} w={'100%'}>
          <FormControl id="file-upload">
            {/* Drag and Drop Area */}
            <VStack
              p={18} 
              pb={'20px'}
              pt={'20px'}
              borderWidth={2}
              borderRadius="md"
              borderStyle="dashed"
              borderColor={dragging ? 'blue.300' : 'gray.300'}
              bg={dragging ? 'blue.50' : 'transparent'}
              _hover={{ bg: 'blue.50' }}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              cursor="pointer"
              align="center"
              justify="center"
            >
              <Text pb={'30px'}>Drop your CSV file here</Text>
              <Flex gap={'20px'}>
                <Button onClick={openFileDialog} w={'160px'} variant="solid" colorScheme="blue" leftIcon={<IoCloudUploadOutline fontSize={30} />}>
                    Upload CSV
                </Button>
                <Button onClick={openFileDialog} w={'160px'} variant="outline" colorScheme="blue" leftIcon={<IoCloudDownloadOutline fontSize={30} />}>
                    Template
                </Button>
              </Flex>
            </VStack>

            <Input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                size="md"
                id="file-upload-input"
                hidden
            />

            {/* Conditional rendering for file information */}
            {file && (
              <Flex mt={8} justify="space-between" align="center">
                <Box>
                  <Text fontWeight="bold">{file.name}</Text>
                  <Text fontSize="sm">Size: {fileSize(file.size)}</Text>
                </Box>
                <CloseButton onClick={handleFileRemove} />
              </Flex>
            )}

          </FormControl>
        </Stack>
      </Flex>
    </Stack>
  );
}
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selection: '',
    model: null,
    generateMedia: ''
}

export const generateOptionsSlice = createSlice({
    name: 'generateOptions',
    initialState,
    reducers: {
        setSelection: (state, action) => {
            state.selection = action.payload;
        },
        setModel: (state, action) => {
            state.model = action.payload;
        },
        setGenerateMedia: (state, action) => {
            state.generateMedia = action.payload;
        }
    }
});

export const { setSelection, setModel, setGenerateMedia } = generateOptionsSlice.actions;

export default generateOptionsSlice.reducer;
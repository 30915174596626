import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTemplate } from '../../store/templateSlice';
import { setSelection } from '../../store/generateOptionsSlice';

import {
  Flex,
  Stack,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Heading,
  Text
} from '@chakra-ui/react';

export default function TemplateSelector() {
  const model = useSelector((state) => state.generateOptions.model);
  const templateList = useSelector((state) => state.templates.templateList);
  const dispatch = useDispatch();

  const handleClick = (e) => {
    const key = e.target.id;
    const template = templateList.filter((model)=>{
      return model.key === key;
    })
    dispatch(selectTemplate(template[0]));
    dispatch(setSelection('Templates'));
  }

  const uniqueCategories = [];
  const categoryItems = {};
  
  const renderedCategories = templateList.map(template => {
    if(template.models.includes(model.title) || !model?.title){
      if(uniqueCategories.includes(template.category)){
        categoryItems[template.category].push(<Link onClick={handleClick} id={template.key} key={template.key} fontSize='0.9rem' color='#243854'>{template.name}</Link>)
      } else {
        uniqueCategories.push(template.category);
        categoryItems[template.category] = [<Link onClick={handleClick} key={template.key} id={template.key} fontSize='0.9rem' color='#243854'>{template.name}</Link>]
        
        return (
          <AccordionItem key={template._id}>
            <AccordionButton
              key={template._id} 
              fontSize='sm' 
              id={template.category}
              >
              <Box as="span" flex='1' textAlign='left' ml='10px'>
                <Text size='xs'>{template.category}</Text>
              </Box>
              <AccordionIcon/>
            </AccordionButton>
            <AccordionPanel pt='4px'>
              <Flex direction='column' pl='10px' gap='5px'>
                {categoryItems[template.category]}
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        )
      }
      return false;
    }
    return false;
  });

  return (
    <Flex p='0px' flex={1} align={'top'} justify={'top'} direction={'column'}>
      <Accordion w='250px' maxWidth='250px' mb='100px' allowMultiple>
        <AccordionItem>
          <AccordionButton
            fontSize='sm' 
            >
            <Box as="span" flex='1' textAlign='left'>
              <Heading size='xs'>Templates</Heading>
            </Box>
            <AccordionIcon/>
          </AccordionButton>
          <AccordionPanel p='0px'>
            <Stack>
              <Accordion w='250px' maxWidth='250px'  allowMultiple>
                {renderedCategories}
              </Accordion>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>    
    </Flex>
  );
}
import React from 'react';
import { TagsInput } from "react-tag-input-component"
import { useState, useEffect } from 'react';

export default function TagsInputWrapper({props}) {
    let [tags, setTags] = useState([]);
    const {key, name, placeHolder, setPromptValue, promptValues} = props;
    
    useEffect(() => {
      let inputValue = tags
      let inputKey = key
      let newKey = {};
      newKey[inputKey] = inputValue;
      
      setPromptValue({
          ...promptValues,
          ...newKey
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[tags]);

    return (
        <TagsInput
        placeHolder={placeHolder}
        value={tags}
        onChange={setTags}
        name={name}
        id={key}
      />
    )
}

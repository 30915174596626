import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { createTemplate, updateTemplate, deleteTemplate } from '../../data/templates.js';

import {
  Flex,
  Stack,
  Input,
  FormControl,
  FormLabel,
  Textarea,
  Button
} from '@chakra-ui/react';

export default function EditTemplate() {
    const template = useSelector((state) => state.templates.template);
    const [templateValues, setTemplateValues] = useState({
        name: '',
        key: '',
        category: '',
        heading: '',
        models: '',
        systemPrompt: '',
        promptTemplate: '',
        promptInputs: '',
        imgPath: '',
        tags: '',
        description: '',
        platform: '',
        media: ''
    });
    
    useEffect(()=>{
        if(template?.name){
            setTemplateValues({
                ...template,
                promptInputs: JSON.stringify(template.promptInputs)
            })
        }
    },[template])

    const refresh = () => window.location.reload(true)
    
    const handleChange = (e)=>{
        const value = e.target.value;
        const key = e.target.name;
        setTemplateValues({
            ...templateValues,
            [key]: value
        })
    }

    const handleInputsChange = (e)=>{
        const value = e.target.value;
        setTemplateValues({
            ...templateValues,
            promptInputs: value
        })
    }
    
    const values = (
        <Stack>
            <FormControl w={'600px'}>
                <FormLabel size='xs'>Name:</FormLabel>
                <Input size='xs' variant='outline' value={templateValues.name} mb={4} name='name' onChange={handleChange}/>
                <FormLabel size='xs'>Description:</FormLabel>
                <Input size='xs' variant='outline' value={templateValues.description} mb={4} name='description' onChange={handleChange}/>
                <FormLabel size='xs'>Image path:</FormLabel>
                <Input size='xs' variant='outline' value={templateValues.imgPath} mb={4} name='imgPath' onChange={handleChange}/>
                <FormLabel size='xs'>Key:</FormLabel>
                <Input size='xs' variant='outline' value={templateValues.key} mb={4} name='key' onChange={handleChange}/>
                <FormLabel>Category:</FormLabel>
                <Input size='xs' variant='outline' value={templateValues.category} mb={4} name='category' onChange={handleChange}/>
                <FormLabel>Media:</FormLabel>
                <Input size='xs' variant='outline' value={templateValues.media} mb={4} name='media' onChange={handleChange}/>
                <FormLabel>Heading:</FormLabel>
                <Input size='xs' variant='outline' value={templateValues.heading} mb={4} name='heading' onChange={handleChange}/>
                <FormLabel>Models:</FormLabel>
                <Input size='xs' variant='outline' value={templateValues.models} mb={4} name='models' onChange={handleChange}/>
                <FormLabel>Tags:</FormLabel>
                <Input size='xs' variant='outline' value={templateValues.tags} mb={4} name='tags' onChange={handleChange}/>
                <FormLabel>Platform:</FormLabel>
                <Input size='xs' variant='outline' value={templateValues.platform} mb={4} name='platform' onChange={handleChange}/>
                <FormLabel>System prompt:</FormLabel>
                <Textarea size='xs' variant='outline' value={templateValues.systemPrompt} mb={4} name='systemPrompt' onChange={handleChange} h={20}/>
                <FormLabel>Prompt template:</FormLabel>
                <Textarea size='xs' variant='outline' value={templateValues.promptTemplate} mb={4} name='promptTemplate' onChange={handleChange} h={20}/>
                <FormLabel>Prompt inputs:</FormLabel>
                <Textarea size='xs' variant='outline' value={templateValues.promptInputs} mb={4} name='promptInputs' onChange={handleInputsChange} h={20}/>
            </FormControl>
        </Stack>
    )

    const handleCreateNewTemplate = () => {
        createTemplate(templateValues).then(refresh);
    }

    const handleUpdateTemplate = () => {
        updateTemplate(templateValues).then(refresh);
    }

    const handleDeleteTemplate = () => {
        deleteTemplate(templateValues).then(refresh);
    }

    let controls;

    if(template?._id){
        controls = (
            <Stack direction='row'>
                <Button onClick={handleUpdateTemplate}>Update</Button>
                <Button colorScheme='red' onClick={handleDeleteTemplate}>Delete</Button>
            </Stack>
        )
    } else {
        controls = (
            <Stack>
                <Button colorScheme='green' onClick={handleCreateNewTemplate}>Create</Button>
            </Stack>
        )
    }

    return (
        <Flex p={4} flex={1} align={'top'} justify={'top'} direction={'column'}>
            {values}
            {controls}
        </Flex>
    );
}
import noUser from '../utils/HandleNoUser'
import mixpanel from 'mixpanel-browser';

export default async function Generate(systemPromptValue, prompt, model, stateUpdate, method, uid, dateTime, end) {
  // Track an event. It can be anything, but in this example, we're tracking a prompt sent event.
  mixpanel.track('Prompt sent', {
    'Model': model.title
  });

  let content = '';
  //stream response
  const ENV = process.env.NODE_ENV;

  const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai';

  const apiRoute = method === 'chat' ? `${model.api.route}/chat` : `${model.api.route}`;

  const body = {
    "systemPromptValue": systemPromptValue,
    "prompt": prompt
  }
  const response = await fetch(`${api_url}${apiRoute}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Conversation-Id': uid,
      'Conversation-TimeStamp': dateTime
    },
    body: JSON.stringify(body)
  });

  const reader = response.body.getReader();
  const decoder = new TextDecoder('utf-8')
  const loopRunner = true;
  while (loopRunner) {
    // Here we start reading the stream, until its done.
    const { value, done } = await reader.read();
    if (done) {
      break;
    }
    const chunkString = decoder.decode(value, { stream: true })
    const decodedChunk = decoder.decode(value, { stream: true }).replace("...that is all for now :)...","");
    content += decodedChunk;
    if(chunkString === 'no user'){
      noUser()
    } else {
      stateUpdate(content,chunkString);
    }
  }
}

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinkerSettings from '../../agents/linker/Settings';
import ActionLogOverlay from '../../agents/linker/ActionOverlay';
import { retrieveModels } from "../../data/models";
import { retrieveTemplates } from "../../data/templates";
import { updateModelList, loaded } from '../../store/modelSlice';
import { setModel, setGenerateMedia } from '../../store/generateOptionsSlice';
import { updateTemplates, templateIsLoaded, selectTemplate } from '../../store/templateSlice';
import Loading from '../../components/Loading';
import history from '../../data/history';
import { updateImageHistory } from '../../store/contentSlice';

import { 
  Flex, 
  Card,
  CardBody,
  Heading
} from '@chakra-ui/react'

function Linker(){
  const dispatch = useDispatch();
  const models = useSelector((state) => state.models.modelList);
  const selectedModel = useSelector((state) => state.generateOptions.model);
  const imageHistory = useSelector((state) => state.content.imageHistory);
  const { innerWidth: width} = window;
  const [imagesLoading, setimagesLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [maxHeight, setMaxHeight] = useState('90%'); // Default value

  const updateMaxHeight = () => {
    const viewportHeight = window.innerHeight;
    const newMaxHeight = `${viewportHeight - 65}px`; // Calculate new max height
    setMaxHeight(newMaxHeight);
  };

  useEffect(() => {
    updateMaxHeight(); // Set initial value
    window.addEventListener('resize', updateMaxHeight); // Update value on window resize

    // Cleanup listener when component unmounts
    return () => window.removeEventListener('resize', updateMaxHeight);
  }, []); // Only run on mount and unmount

  useEffect(() => {
    const fetchData = async () => {
      try {

        if(models.length <= 0) {
          const modelList = await retrieveModels();
          dispatch(updateModelList(modelList));
          dispatch(loaded());
        }

        let loadModel = true;

        if(selectedModel !== null){
          if(selectedModel.generates.includes('Text')){
            loadModel = false;
          }
        }

        if(loadModel){
          models.forEach((m) => {
            if(m._id === '64ab27f9bd772e58bcb34ac3'){
              dispatch(setModel(m));
            }
          });
        }
        
        const templateList = await retrieveTemplates();
        dispatch(updateTemplates(templateList));
        dispatch(templateIsLoaded());
        dispatch(selectTemplate(templateList));
        dispatch(setGenerateMedia('Image'));

        setDataLoading(false);
        
        if(imageHistory[0] === 'no-history' || !imageHistory[imageHistory.length -1].hasOwnProperty('src') || imageHistory[imageHistory.length -1].src === null){
          const images = await history.getImageHistory();
          const imageDataNoImage = images.map((image) => {
            return { ...image, src: null, isLoading: true }
          }).reverse();
          await dispatch(updateImageHistory([1,imageDataNoImage]));
          setimagesLoading(false);
        }
        
      } catch (error) {
        console.log('Error fetching data', error);
      }
    }

    fetchData();
  }, [dispatch,models]);

  if (width <= 900){
    return (
        <Flex
            justify={'center'}
            align={'center'}
            p={'20px'}
            >
            <Heading size={'sm'}>
                Sorry we don't currently support screen sizes below 900px
            </Heading>
        </Flex>
    )
  }

  if (dataLoading) {
    return <Loading />
  }

  return (
    <Flex 
      w='100%' 
      h='100%'
      bgGradient='linear(to-t, #EBF3FD, #f3f0ff)'
      >
        <Flex 
          p='20px'
          gap='20px'
          maxH={maxHeight}
          w='100%'
          h='100%'
          justify={'center'}
          >
          <Card 
            w='100%'
            minW='650px'
            maxW='650px'
            variant='outline'
            boxShadow='md'
            overflow='scroll'
            flex='1'
            p={'0px'}
            >
            <CardBody
              p={'0px'}
              pb={'-1px'}
            >
              <LinkerSettings/>
            </CardBody>
          </Card>
          {/* <Card 
            h='100%'
            w='100%'
            overflow='hidden'
            variant='outline'
            boxShadow='md'
            flex='2'
            pb={'10px'}
            >
            <CardBody>
              <Quill />
            </CardBody>
          </Card> */}
        </Flex>  
        <ActionLogOverlay />   
    </Flex>      
  )
}

export default Linker;
import TableAll from './TableAll';

import {
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel
  } from '@chakra-ui/react';

  export default function TabView() {
    return (
        <Tabs w='100%'>
            <TabList>
                <Tab>All documents</Tab>
                {/* <Tab>Folders</Tab> */}
            </TabList>

            <TabPanels>

                <TabPanel>
                <TableAll />
                </TabPanel>

                <TabPanel>
                <p>List all folders</p>
                </TabPanel>

            </TabPanels>
        </Tabs>
    )
}
import React from 'react';
import { Center } from '@chakra-ui/react';

function models(){
    return (
        <Center h='100%'>
            Wokflows
        </Center>
    )
}

export default models;
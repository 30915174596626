import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelection } from '../../store/generateOptionsSlice';
import SelectTemplate from '../../components/templates/SelectTemplate';
import SelectModel from '../../components/models/SelectModel';
import CopyHistory from './../controls/CopyHistory';
import { selectTemplate } from '../../store/templateSlice';

import {
  Box,
  VStack,
  Stack,
  Card,
  CardBody,
  Image,
  Heading,
  Text,
  Button,
  Center,
  Flex
} from '@chakra-ui/react';

export default function SideBar() {
  const dispatch = useDispatch();
  const model = useSelector((state) => state.generateOptions.model);
  const template = useSelector((state) => state.templates.template);
  const selection = useSelector((state) => state.generateOptions.selection);

  const handleClick = (e)=> {
    const label = e.target.id;
    dispatch(setSelection(label));
    
  };

  const CurrentModel = () => {
    return (
      <Card
        key={model.title}
        direction={{ base: 'column', sm: 'row' }}
        overflow='hidden'
        variant='outline'
        height='270px'
        width='310px'
        boxShadow='md'
      >
        <Stack w='100%'>
          <Image
              objectFit={'cover'}
              maxW='310px'
              minH='100px'
              maxH='180px'
              src={`/images/${model.icon}`}
              alt={model.title}
          />
            <CardBody>
              <Center direction='column'>
                <Heading size='sm'>Transcribing with {model.title}</Heading>
                  <Text py='2' fontSize='xs'>
                  </Text>
              </Center>
            </CardBody>
        </Stack>
      </Card>
    )
  }

  return (
    <Box
      backgroundColor='#fff'
      borderRight="1px"
      borderRightColor={'#f5f6fa'}
      w={'350px'}
      minW={'350px'}
      h="100%"
      overflow='scroll'
      pt='20px'
    >
    <VStack gap='10px' align={'center'}>
        <CurrentModel />
    </VStack>
  </Box>
  );
}
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GenerateCopy from '../pages/GenerateCopy';
import GenerateChat from '../pages/GenerateChat';
import GenerateImage from '../pages/GenerateImage';
import GenerateVoice from '../pages/GenerateVoice';
import Models from '../pages/Models';
import Agents from '../pages/Agents';
import Knowledge from '../pages/Knowledge';
import Workflows from '../pages/Workflows';
import Projects from '../pages/Projects';
import Templates from '../pages/Templates';
import ManageTemplates from '../pages/ManageTemplates';
import Account from '../pages/Account';
import Documents from '../pages/Documents';
import GenerateTranscription from '../pages/GenerateTranscription';
import PaymentPrompt from '../components/payments/PaymentCard';
import VeoWriter from '../pages/agents/VeoWriter';
import Linker from '../pages/agents/Linker';
import YoutubeWriter from '../pages/agents/YoutubeWriter';

const AuthenticatedRoutes = () => (
    <Routes>
        <Route path='/' element={<Navigate to="/agents" />} />
        <Route path='/agents/veowriter' element={<VeoWriter/>}/>
        <Route path='/agents/youtubewriter' element={<YoutubeWriter/>}/>
        <Route path='/agents/linker' element={<Linker/>}/>
        <Route path='/generate/copy' element={<GenerateCopy />} />
        <Route path='/generate/chat' element={<GenerateChat/>} />
        <Route path='/generate/image' element={<GenerateImage/>}/>
        <Route path='/generate/voice' element={<GenerateVoice/>}/>
        <Route path='/models' element={<Models/>}/>
        <Route path='/agents' element={<Agents/>}/>
        <Route path='/knowledge' element={<Knowledge/>}/>
        <Route path='/workflows' element={<Workflows/>}/>
        <Route path='/projects' element={<Projects/>}/>
        <Route path='/templates' element={<Templates/>}/>
        <Route path='/account' element={<Account/>}/>
        <Route path='/documents' element={<Documents/>}/>
        <Route path='/generate/transcription' element={<GenerateTranscription/>}/>
        <Route path='/manage-templates' element={<ManageTemplates/>}/>

    </Routes>
);

const PrivateRoutes = () => {
    const auth = useSelector((state) => state.user.isAuth);
    const subscriptionActive = useSelector((state) => state.user.subscriptionActive);
    const credits = useSelector((state) => state.user.credits);

    if (auth === null) {
        return null;
    }

    if (!credits) {
        return <PaymentPrompt />
    }

    return auth ? <AuthenticatedRoutes /> : <Navigate to='/login' />;
}

export default PrivateRoutes;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateVeoWritercontent, updateVeoWriterOptions, updateYouTubeWritercontent, updateYouTubeWriterOptions, updateCopyHistory, updateImageHistory, updateConversationHistory, updateVoiceHistory, updateAgentHistory, updateConversation, updateConversationId } from '../../store/contentSlice';
import history from '../../data/history';
import { extendTheme, ChakraProvider, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Text, Flex, Button } from "@chakra-ui/react";
import Loading from '../Loading';
import { updateBase64, updateImagePrompt, updateAudioUri, updateAudioPrompt, updateAudioDownloadBlob, updateCopyPrompt, update } from '../../store/contentSlice';
import { setModel } from '../../store/generateOptionsSlice';
import { retrieveModels } from "../../data/models";
import { updateModelList, loaded } from '../../store/modelSlice';
import { generateElevenlabs } from '../../promptApi/elevenlabs';
import {
    FiDelete
} from 'react-icons/fi';

function TableCmp() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const modelListLoaded = useSelector((state) => state.models.loadedModelList);
    const chatDocuments = useSelector((state) => state.content.conversationHistory);
    const imageDocuments = useSelector((state) => state.content.imageHistory);
    const copyDocuments = useSelector((state) => state.content.copyHistory);
    const voiceDocuments = useSelector((state) => state.content.voiceHistory);
    const agentDocuments = useSelector((state) => state.content.agentHistory);

    const models = useSelector((state) => state.models.modelList);
    const images = useSelector((state) => state.content.imageHistory);

    const [loadCount, setLoadCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [localDocuments, setLocalDocuments] = useState([]);

    const ENV = process.env.NODE_ENV;
    const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai';
    
    const shortenDescription = (text) => {
        return text.length > 100 ? text.substring(0, 100) + "..." : text;
    };

    const incrementLoadCount = () => {
        setLoadCount((prevCount) => {
            const newCount = prevCount + 1;
            if (newCount === 5) {
                setLoading(false);
            }
            return newCount;
        });
    };

    useEffect(() => {
        const fetchImageHistory = async () => {
            const imageHistoryData = await history.getImageHistory(); 
            dispatch(updateImageHistory([1, imageHistoryData]));
            incrementLoadCount();
        };
        fetchImageHistory();
    }, [dispatch]);

    useEffect(() => {
        const fetchChatHistory = async () => {
            const chatHistoryData = await history.getChatHistory(); 
            dispatch(updateConversationHistory(chatHistoryData));
            incrementLoadCount();
        };
        fetchChatHistory();
    }, [dispatch]);
    
    useEffect(() => {
        const fetchCopyHistory = async () => {
            const copyHistoryData = await history.getCopyHistory();
            dispatch(updateCopyHistory(copyHistoryData));
            incrementLoadCount();
        };
        fetchCopyHistory();
    }, [dispatch]);
    
    useEffect(() => {
        const fetchVoiceHistory = async () => {
            const voiceHistoryData = await history.getVoiceHistory();
            dispatch(updateVoiceHistory(voiceHistoryData));
            incrementLoadCount();
        };
        fetchVoiceHistory();
    }, [dispatch]);
    
    useEffect(() => {
        const fetchAgentHistory = async () => {
            const agentHistoryData = await history.getAgentHistory();
            dispatch(updateAgentHistory(agentHistoryData));
            incrementLoadCount();
        };
        fetchAgentHistory();
    }, [dispatch]);

    const combineAndLabelDocuments = (docs, type) => {
        return docs.map((doc) => {
            let newDoc = {
                ...doc,
                type
            };
            if (doc.description) {
                newDoc.description = shortenDescription(doc.description);
            }
            return newDoc;
        });
    };

    useEffect(() => {
        const labeledChatDocuments = combineAndLabelDocuments(chatDocuments, 'Chat');
        const labeledImageDocuments = combineAndLabelDocuments(imageDocuments, 'Image');
        const labeledCopyDocuments = combineAndLabelDocuments(copyDocuments, 'Text/Copy');
        const labeledVoiceDocuments = combineAndLabelDocuments(voiceDocuments, 'Voice/Audio');
        const labeledAgentDocuments = combineAndLabelDocuments(agentDocuments.map(doc => {
            return { ...doc, dateTime: doc.createdAt, modelName: doc.agent };
        }), 'Agent');

        const allDocuments = [
            ...labeledChatDocuments,
            ...labeledImageDocuments,
            ...labeledCopyDocuments,
            ...labeledVoiceDocuments,
            ...labeledAgentDocuments
        ];

        const cleanDocs = allDocuments.filter((doc) => doc.title);

        setLocalDocuments(
            cleanDocs.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
        );
    }, [chatDocuments, imageDocuments, copyDocuments, voiceDocuments, agentDocuments]);

    const handleClick = async (doc) => {
        const { type } = doc;
        if (type === 'Image') {
            navigate('/generate/image');
            dispatch(updateImagePrompt(doc.prompt));
            let imgSrc = '';
            await images.forEach(async (img) => { 
                if (img._id === doc._id) {
                    if (img.imgSrc) {
                        imgSrc = img.imgSrc;
                        const imgData = imgSrc.replace('data:image/png;base64,', '');
                        dispatch(updateBase64(imgData))
                    }
                }
            });

            if (imgSrc === '') {
                const response = await fetch(
                    `${api_url}/data/history/oneImage/${doc._id}`, 
                    { credentials: 'include' }
                );
                const imgSrc = await response.text();
                const imgData = imgSrc.replace('data:image/png;base64,', '');
                dispatch(updateBase64(imgData));
            }

            models.forEach((model) => {
                if (model._id === doc.modelID) {
                    dispatch(setModel(model));
                }
            });
        }

        if (type === 'Voice/Audio') {
            navigate('/generate/voice');
            dispatch(updateAudioPrompt(doc.prompt));
            const stream = await fetch(
                `${api_url}/data/history/oneVoice/${doc._id}`, 
                { 
                    credentials: 'include', 
                    headers: { timestamp: doc.dateTime } 
                }
            );

            const updateState = {
                updateUri: (data) => { dispatch(updateAudioUri(data)); },
                updateblob: (data) => { dispatch(updateAudioDownloadBlob(data)); },
            };

            await generateElevenlabs(null, null, updateState, stream);

            models.forEach((model) => {
                if (model._id === doc.modelID) {
                    dispatch(setModel(model));
                }
            });
        }

        if (type === 'Chat') {
            navigate('/generate/chat');
            dispatch(updateAudioPrompt(doc.prompt));
            dispatch(updateConversationId(doc.conversationId));
            dispatch(updateConversation([4, doc.messages]));
            models.forEach((model) => {
                if (model._id === doc.modelID) {
                    dispatch(setModel(model));
                }
            });
        }

        if (type === 'Text/Copy') {
            navigate('/generate/copy');
            dispatch(updateCopyPrompt(doc.prompt));
            dispatch(update(doc.copy));
            models.forEach((model) => {
                if (model._id === doc.modelID) {
                    dispatch(setModel(model));
                }
            });
        }

        if (type === 'Agent') {
            if(doc.agent === 'seo-writer'){
                navigate('/agents/veowriter');
                dispatch(updateVeoWriterOptions(doc.settings));
                dispatch(updateVeoWritercontent({ action: 'overwrite', 'content': doc.data.content }));
            } else {
                navigate('/agents/youtubewriter');
                dispatch(updateYouTubeWriterOptions(doc.settings));
                dispatch(updateYouTubeWritercontent({ action: 'overwrite', 'content': doc.data.content }));
            }
        }
    };

    const handleDelClick = (doc) => {
        if (doc.type === 'Text/Copy') {
            history.delCopyHistory(doc._id);
            const newCopyHistory = copyDocuments.filter((existingDoc) => existingDoc._id !== doc._id);
            dispatch(updateCopyHistory(newCopyHistory));
        }

        if (doc.type === 'Image') {
            history.delImageHistory(doc._id);
            const newImageHistory = imageDocuments.filter((existingDoc) => existingDoc._id !== doc._id);
            dispatch(updateImageHistory([1, newImageHistory]));
        }

        if (doc.type === 'Chat') {
            history.delChatHistory(doc._id);
            const newChatHistory = chatDocuments.filter((existingDoc) => existingDoc._id !== doc._id);
            dispatch(updateConversationHistory(newChatHistory));
        }

        if (doc.type === 'Voice/Audio') {
            history.delVoiceHistory(doc._id, doc.dateTime);
            const newVoiceHistory = voiceDocuments.filter((existingDoc) => existingDoc._id !== doc._id);
            dispatch(updateVoiceHistory(newVoiceHistory));
        }

        if (doc.type === 'Agent') {
            history.delAgentHistory(doc._id);
            const newAgentHistory = agentDocuments.filter((existingDoc) => existingDoc._id !== doc._id);
            dispatch(updateAgentHistory(newAgentHistory));
        }
    };

    const createDocumentList = (documents) => {
        return documents.map((doc, index) => {
            const options = { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            const dateTime = new Date(doc.dateTime);
            const formattedDateTime = dateTime.toLocaleDateString("en-US", options);

            const shortenString = str => (str.length > 80 ? str.substring(0, 80) + '...' : str);
            const shortTitle = shortenString(doc.title).replace(/=+/g, '');;

            const shortenDesc = str => (str.length > 90 ? str.substring(0, 90) + '...' : str);
            const shortDesc = shortenDesc(doc.description).replace(/=+/g, '');;

            return (
                <Tr _hover={{ bg: "gray.100", cursor: "pointer" }} key={index} h='50px' border='none'>
                    <Td textAlign={'center'} onClick={() => { handleClick(doc); }}><Text as='b'>{shortTitle}</Text></Td>
                    <Td textAlign={'center'} onClick={() => { handleClick(doc); }}><Text>{doc.type}</Text></Td>
                    <Td textAlign={'center'} onClick={() => { handleClick(doc); }}>{doc.modelName}</Td>
                    <Td textAlign={'center'} onClick={() => { handleClick(doc); }} sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{shortDesc}</Td>
                    <Td textAlign={'center'} onClick={() => { handleClick(doc); }}>{formattedDateTime}</Td>
                    <Td textAlign={'center'} onClick={() => { handleDelClick(doc); }}><Flex justify={'center'}><FiDelete color='#eb264f' fontSize='1.5rem' /></Flex></Td>
                </Tr>
            );
        });
    };

    const RenderedList = createDocumentList(localDocuments);

    if (loading) {
        return (
            <Flex w={'100%'} h={'300px'} align={'center'} justify={'center'}>
                <Loading />
            </Flex>
        );
    }

    if (!modelListLoaded) {
        retrieveModels().then((modelList) => {
            dispatch(updateModelList(modelList));
            dispatch(loaded());
        });
        return (
            <Flex w={'100%'} h={'300px'} align={'center'} justify={'center'}>
                <Loading />
            </Flex>
        );
    }

    if (localDocuments.length === 0) {
        return (<div>No documents found. Anything you generate will automatically be saved here.</div>);
    }

    return (
        <Flex w='100%'>
            <TableContainer w='100%' maxH={'700px'} overflowY={'auto'} position='relative' boxShadow='md' mt={'10px'} border='1px solid #f8f8f8' borderRadius={'5px'}>
                <Table size='sm' overflowWrap={'auto'} w='100%' overflowY={'visible'}>
                    <Thead backgroundColor={'white'} position='sticky' top='0' zIndex='1' h={'60px'}>
                        <Tr>
                            <Th textAlign={'center'}>Name</Th>
                            <Th textAlign={'center'}>Type</Th>
                            <Th textAlign={'center'}>Model / Agent</Th>
                            <Th textAlign={'center'}>Description</Th>
                            <Th textAlign={'center'}>Created date</Th>
                            <Th textAlign={'center'}>Delete</Th>
                        </Tr>
                    </Thead>
                    <Tbody>{RenderedList}</Tbody>
                </Table>
            </TableContainer>
        </Flex>
    );
}

const newTheme = extendTheme({
    components: {
        Table: {
            parts: ['tbody'],
            baseStyle: {
                tbody: {
                    tr: {
                        '&:nth-of-type(odd)': {
                            background: "#fff",
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: "#f8f8f8",
                        },
                        '&:hover': {
                            backgroundColor: '#f1f3f5',
                        }
                    },
                }
            }
        }
    }
});

export default function TableAll() {
    return (
        <ChakraProvider theme={newTheme}>
            <TableCmp />
        </ChakraProvider>
    );
}
import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateYouTubeWriterOptions, updateActiveTasks, updateYouTubeWritercontent, updateIsLoadingYouTubeWriter, updateYouTubeWriterActions, updateYouTubeWriterActionsLoading } from '../../store/contentSlice';
import { updateCredits } from '../../store/userSlice';
import noUser from '../../utils/HandleNoUser';
import GenerateYouTubeWriter from '../../promptApi/agents/YouTubeWriter';
import SelectDomain from'../../components/domains/selectDomain';
import SelectTOV from'../../components/TOV/SelectTOV';
import SelectSettings from './SelectSettings';
import SaveSettings from './SaveSettings';

import {
  Button,
  Textarea,
  Flex,
  FormControl,
  Select,
  Tooltip,
  Text,
  Heading,
  Switch,
  ButtonGroup
} from '@chakra-ui/react';

import { BsFillInfoCircleFill } from "react-icons/bs";
import { MdOutlineClear } from "react-icons/md";

const ENV = process.env.NODE_ENV;
const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai';

const isValidUrl = (urlString) => {
  const pattern = new RegExp(
    `^(https?:\\/\\/)?` + // protocol
    `(([\\w\\d]([\\w\\d-]*[\\w\\d])*)\\.)+` + // domain name including subdomains
    `([a-z]{2,})` + // TLD, at least two characters
    `(\\:\\d+)?` + // port
    `(\\/[-a-z\\d%_.~+]*)*` + // path
    `(\\?[;&a-z\\d%_.~+=-]*)?` + // query string
    `(\\#[-a-z\\d_]*)?$`, // fragment locator
    'i'
  );
  return pattern.test(urlString);
};

const Model = ({model, handleChange}) => {
  const [selectedMethod, setSelectedMethod] = useState(model);

  useEffect(() => {
    setSelectedMethod(model);
  }, [model]);

  const toolTipLabel = 'Select the base model you want to write your content, experiment with different models and settings to get best results.';
  const buttonStyles = {
    default: {
      w: '130px',
      fontSize: 'sm', // Adjust font size here
      _hover: { bg: 'gray.100' }, // No background change on hover for default buttons
    },
    selected: {
      w: '130px',
      fontSize: 'sm', // Adjust font size here
      bgColor: 'gray.600',
      color: 'white',
      _hover: { bg: 'gray.600' }, // Retain the selected color on hover
    },
  };

  const handleSelect = (selection) => {
    setSelectedMethod(selection.value);
    handleChange({key: 'model', value: selection.value});
  };

  return (
    <Flex direction={'column'} gap={'10px'}>
      <Flex align={'center'}>
        <Heading size='sm' as='h1'>Model</Heading>
        <Tooltip label={toolTipLabel}>
          <Flex pl={'5px'}>
            <BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }} />
          </Flex>
        </Tooltip>
      </Flex>
      <Flex w='100%' gap='10px'>
      <Button minW='160px'
          {...(selectedMethod === 'GPT-4o-Mini' ? buttonStyles.selected : buttonStyles.default)}
          onClick={() => handleSelect({key: 'model', value: 'GPT-4o-Mini'})}
        >
          GPT-4o Mini
        </Button>
        <Button minW='160px'
          {...(selectedMethod === 'GPT-4o' ? buttonStyles.selected : buttonStyles.default)}
          onClick={() => handleSelect({key: 'model', value: 'GPT-4o'})}
        >
          GPT-4o
        </Button>
        <Button minW='160px'
          {...(selectedMethod === 'Claude-3.5-Sonnet' ? buttonStyles.selected : buttonStyles.default)}
          onClick={() => handleSelect({key: 'model', value: 'Claude-3.5-Sonnet'})}
        >
          Claude 3.5 Sonnet
        </Button>
      </Flex>
    </Flex>
  );
};

const Url = ({url, handleChange}) => {
  const urlRef = useRef(null); 
  const urlTT = 'Enter the URL for a YouTube video. The agent will then analyze the video and generate an SEO optimised article.'
  
  useEffect(() => {
    // If there's logic that determines whether the settings have been updated,
    // include that here, and then focus the url input if the condition is met.
      urlRef.current.focus();
    
  }, []); // Add dependencies here, like promptSettings, or other relevant state

  return (
      <Flex direction='column' gap={'10px'}>
        <Flex align={'center'}>
            <Heading size='sm' as='h1'>YouTube URL</Heading>
            <Tooltip label={urlTT}><Flex pl={'5px'}><BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }}/></Flex></Tooltip>
        </Flex>
        <Textarea
          ref={urlRef}
          data-key='url'
          value={url}
          onChange={(e)=>{handleChange({key: e.target.id, value: e.target.value})}}
          placeholder='Enter your YouTube video URL.'
          size='sm'
          w='100%'
          minH="40px"
          id={'url'}
          autoFocus
        />
    </Flex>
  );
};

const ToneOfVoice = ({ TOV, handleChange }) => {
  const toolTipLabel = 'Choose from a pre-trained tone of voice, or use a custom tone of voice trained on your own data.';

  const [selectedOption, setSelectedOption] = useState(TOV);

  useEffect(() => {
    setSelectedOption(TOV);
  }, [TOV]);

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    handleChange({ key: 'TOV', value: selectedValue });
  };

  return (
    <Flex direction={'column'} gap={'10px'} w={'100%'}>
      <Flex align={'center'}>
        <Heading size='sm' as='h1'>Tone of Voice</Heading>
        <Tooltip label={toolTipLabel}><Flex pl={'5px'}><BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }} /></Flex></Tooltip>
      </Flex>
      <Flex w='100%' gap='10px' mb={-3}>
        <SelectTOV selectedOption={selectedOption} handleSelectChange={handleSelectChange} />
      </Flex>
    </Flex>
  );
};

const Language = ({ language, handleChange }) => {
  const toolTipLabel = 'Choose from over 30 languages, when targeting non-english we recommend testing more than one model to get the best output quality.';

  const [selectedLanguage, setSelectedLanguage] = useState(language);

  useEffect(() => {
    setSelectedLanguage(language);
  }, [language]);

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedLanguage(selectedValue);
    handleChange({ key: 'language', value: selectedValue });
  };

  return (
    <Flex direction={'column'} gap={'10px'} w={'100%'}>
      <Flex align={'center'}>
        <Heading size='sm' as='h1'>Language:</Heading>
        <Tooltip label={toolTipLabel}>
          <Flex pl={'5px'}>
            <BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }} />
          </Flex>
        </Tooltip>
      </Flex>
      <Select value={selectedLanguage} data-key='size' onChange={handleSelectChange}>
        <option value='english'>English</option>
        <option value='spanish'>Spanish</option>
        <option value='german'>German</option>
        <option value='french'>French</option>
        <option value='portuguese'>Portuguese</option>
        <option value='polish'>Polish</option>
        <option value='swedish'>Swedish</option>
        <option value='dutch'>Dutch</option>
        <option value='italian'>Italian</option>
        <option value='afrikaans'>Afrikaans</option>
        <option value='indonesian'>Indonesian</option>
        <option value='russian'>Russian</option>
        <option value='ukrainian'>Ukrainian</option>
        <option value='greek'>Greek</option>
        <option value='latvian'>Latvian</option>
        <option value='mandarin'>Mandarin</option>
        <option value='arabic'>Arabic</option>
        <option value='turkish'>Turkish</option>
        <option value='japanese'>Japanese</option>
        <option value='swahili'>Swahili</option>
        <option value='welsh'>Welsh</option>
        <option value='korean'>Korean</option>
        <option value='icelandic'>Icelandic</option>
        <option value='bengali'>Bengali</option>
        <option value='urdu'>Urdu</option>
        <option value='nepali'>Nepali</option>
        <option value='thai'>Thai</option>
        <option value='punjabi'>Punjabi</option>
        <option value='marathi'>Marathi</option>
        <option value='telugu'>Telugu</option>
      </Select>
    </Flex>
  );
};

const TargetCountry = ({ targetCountry, handleChange }) => {
  const toolTipLabel = 'Choose from over 30 target countries, this allows the agent to analyze the correct data for your target region and customise writting style for your geographic audience.';

  const [selectedCountry, setSelectedCountry] = useState(targetCountry);

  useEffect(() => {
    setSelectedCountry(targetCountry);
  }, [targetCountry]);

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCountry(selectedValue);
    handleChange({ key: 'targetCountry', value: selectedValue });
  };

  return (
    <Flex direction={'column'} gap={'10px'} w={'100%'}>
      <Flex align={'center'}>
          <Heading size='sm' as='h1'>Target Country:</Heading>
          <Tooltip label={toolTipLabel}><Flex pl={'5px'}><BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }} /></Flex></Tooltip>
      </Flex>
      <Select value={selectedCountry} data-key='size' onChange={handleSelectChange}>
          <option value='USA'>USA</option>
          <option value='UK'>UK</option>
          <option value='canada'>Canada</option>
          <option value='ireland'>Ireland</option>
          <option value='australia'>Australia</option>
          <option value='new zealand'>New-Zealand</option>
          <option value='spain'>Spain</option>
          <option value='brazil'>Brazil</option>
          <option value='peru'>Peru</option>
          <option value='mexico'>Mexico</option>
          <option value='germany'>Germany</option>
          <option value='france'>France</option>
          <option value='portugal'>Portugal</option>
          <option value='poland'>Poland</option>
          <option value='sweden'>Sweden</option>
          <option value='netherlands'>Netherlands</option>
          <option value='italy'>Italy</option>
          <option value='south africa'>South Africa</option>
          <option value='indonesia'>Indonesia</option>
          <option value='russia'>Russia</option>
          <option value='ukraine'>Ukraine</option>
          <option value='israel'>Israel</option>
          <option value='greece'>Greece</option>
          <option value='latvia'>Latvia</option>
          <option value='Saudi Arabia'>Saudi Arabia</option>
          <option value='turkey'>Turkey</option>
          <option value='japan'>Japan</option>
          <option value='korea'>Korea</option>
          <option value='iceland'>Iceland</option>
          <option value='india'>India</option>
          <option value='pakistan'>Pakistan</option>
          <option value='nepal'>Nepal</option>
          <option value='thailand'>Thailand</option>
      </Select>
    </Flex>
  )
};

const InternalLinks = ({internalLinks, domain, handleChange}) => {
  const [localDomain, setLocalDomain] = useState(domain);

  useEffect(() => {
    setLocalDomain(domain);
  }, [domain]);

  let setDomain = (domain) => {
    handleChange({key: 'domain', value: domain});
    setLocalDomain(domain);
  };
  
  return (
    <Flex direction={'column'} gap={'10px'} w='100%' mb={-3} p={'0px'}>
      <FormControl display='flex' alignItems='center' mt='4' gap={'10px'}>
        {/* Set the size to "lg" for a larger switch */}
        <Switch id='internalLinks' size='lg' colorScheme='blue' isChecked={internalLinks} onChange={()=>{handleChange({key: 'internalLinks', value: !internalLinks})}} />
        <Flex direction={'column'} pl={'15px'} w={'100%'}>
          <Heading as='h1' size='sm' mb='2'>Add internal links</Heading>
          <Text fontSize='sm'>
            If enabled, the AI will automatically add SEO optimised internall links for your selected domain.
          </Text>
        </Flex>
      </FormControl>
        {internalLinks && (<SelectDomain setDomain={setDomain} domain={localDomain}/>)}
    </Flex>
  );
};

const FaqSection = ({includeFAQ, handleChange}) => {
  return (
    <Flex direction={'column'} gap={'10px'}>
      <FormControl display='flex' alignItems='center' mt='4' gap={'10px'}>
        {/* Set the size to "lg" for a larger switch */}
        <Switch id='includeFAQ' size='lg' colorScheme='blue' isChecked={includeFAQ} onChange={()=>{handleChange({key: 'includeFAQ', value: !includeFAQ})}} />
        <Flex direction={'column'} pl={'15px'}>
          <Heading as='h1' size='sm' mb='2'>Include FAQ Section</Heading>
          <Text fontSize='sm'>
          If enabled, the agent will add a FAQ section based on SERP analysis. This will increase article length, and may help you rank for snippets.
          </Text>
        </Flex>
      </FormControl>
    </Flex>
  );
};

const KeyTakeaways = ({includeKeyTakeaways, handleChange}) => {
  return (
    <Flex direction={'column'} gap={'10px'}>
      <FormControl display='flex' alignItems='center' mt='4' gap={'10px'}>
        {/* Set the size to "lg" for a larger switch */}
        <Switch id='real-time-search' size='lg' colorScheme='blue' isChecked={includeKeyTakeaways} onChange={()=>{handleChange({key: 'includeKeyTakeaways', value: !includeKeyTakeaways})}} />
        <Flex direction={'column'} pl={'15px'}>
          <Heading as='h1' size='sm' mb='2'>Include Key Takeaways</Heading>
          <Text fontSize='sm'>
          If enabled, the agent will include a summary of key takeaways beneth the article introduction.
          </Text>
        </Flex>
      </FormControl>
    </Flex>
  );
};

const DisableIntro = ({disableIntro, handleChange}) => {
  // Mock state functions for isChecked and onChange, replace them with your actual state logic
  const [isChecked, setIsChecked] = React.useState(false);
  const handleOnChange = () => setIsChecked(!isChecked);

  return (
    <Flex direction={'column'} gap={'10px'}>
      <FormControl display='flex' alignItems='center' mt='4' gap={'10px'}>
        {/* Set the size to "lg" for a larger switch */}
        <Switch id='real-time-search' size='lg' colorScheme='blue' isChecked={disableIntro} onChange={()=>{handleChange({key: 'disableIntro', value: !disableIntro})}} />
        <Flex direction={'column'} pl={'15px'}>
          <Heading as='h1' size='sm' mb='2'>Disable Introduction</Heading>
          <Text fontSize='sm'>
          By default the agent will include an introduction of two to three paragraphs. You can turn off introductions by enabling this setting.
          </Text>
        </Flex>
      </FormControl>
    </Flex>
  );
};

const DisableConclusion = ({disableConclusion, handleChange}) => {
  return (
    <Flex direction={'column'} gap={'10px'}>
      <FormControl display='flex' alignItems='center' mt='4' gap={'10px'}>
        {/* Set the size to "lg" for a larger switch */}
        <Switch id='real-time-search' size='lg' colorScheme='blue' isChecked={disableConclusion} onChange={()=>{handleChange({key: 'disableConclusion', value: !disableConclusion})}} />
        <Flex direction={'column'} pl={'15px'}>
          <Heading as='h1' size='sm' mb='2'>Disable Conclusion</Heading>
          <Text fontSize='sm'>
          By default the agent will include a closing paragraph as a conclusion. You can turn off introductions by enabling this setting.
          </Text>
        </Flex>
      </FormControl>
    </Flex>
  );
};

export default function YoutubeWriter() {
  const isLoadingYouTubeWriter = useSelector((state) => state.content.isLoadingYouTubeWriter);
  const promptSettings = useSelector((state) => state.content.YouTubeWriterOptions);

  const [buttonLoading, setButtonLoading] = useState({
    optimisePrompt: false,
    downloadFile: false
  });

  let initialSettings = {
    url: '',
    model: 'GPT-4o-Mini',
    TOV: 'SEO Optimised',
    language: 'English',
    targetCountry: 'USA',
    pov: 'Second Person (You, Your, Yours)',
    internalLinks: false,
    domain: '',
    embedYoutubeVideo: false,
    includeFAQ: true,
    includeKeyTakeaways: true,
    disableIntro: false,
    disableConclusion: false
  };

  const dispatch = useDispatch();

  const handleChange = selection => {
    let {key, value} = selection;
    let newSettings = {
      ...promptSettings,
      [key]: value
    }
    dispatch(updateYouTubeWriterOptions(newSettings));
  }

  const handleSubmit = async () => {
    const actionName = 'YouTubeWriter' + Date.now();
    dispatch(updateActiveTasks({ operation: 'addOrUpdate', key: 'YouTubeWriter', value: actionName }));
    dispatch(updateYouTubeWriterActionsLoading(true));
    dispatch(updateYouTubeWriterActions({action: 'overwrite', 'content': []}));
    dispatch(updateIsLoadingYouTubeWriter(true));
    dispatch(updateYouTubeWritercontent({action: 'overwrite', 'content': ''}));

    try {
      const response = await GenerateYouTubeWriter(promptSettings, actionName);

      // ReadableStream processing
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let buffer = '';
      
      while (true) { // A while loop here is sufficient since you break it when done is true
        const { value, done } = await reader.read();
        if (done) {
          break;
        }
  
        // Decode the current chunk and append it to the buffer
        buffer += decoder.decode(value, { stream: true });
        // Process complete messages in the buffer
        while (buffer.includes('\n\n')) {
          
          const splitIndex = buffer.indexOf('\n\n\n');
          const message = buffer.slice(0, splitIndex); // Extract the message up to the delimiter
          buffer = buffer.slice(splitIndex + 2); // Remove the processed message from the buffer
          if (message === '\nno user') {
            noUser();
          }else if(message === '\ninsufficient credit'){
            // set no credits
            dispatch(updateCredits(false));
          } else {
            try {
              let chunkObj = JSON.parse(message);
              console.log(chunkObj);
              if (chunkObj.event === 'content' && chunkObj.load.length > 0) {
                  setTimeout(()=>{
                    dispatch(updateYouTubeWriterActionsLoading(false));
                    dispatch(updateYouTubeWriterActions({action: 'overwrite', 'content': []}));
                  },15000);
                dispatch(updateYouTubeWritercontent({action: 'concat', 'content': chunkObj.load}));
              }
              if (chunkObj.event === 'system' && chunkObj.load.length > 0) {
                if(chunkObj.load.length > 0){
                  dispatch(updateYouTubeWriterActions({action: 'concat', 'content': chunkObj.load}));
                }
              }
            } catch (e) {
              //console.error('Error parsing JSON:', e, message);
            }
          }
        }
      }
    } catch (e) {
      console.error('Fetch failed', e);
    }
    dispatch(updateIsLoadingYouTubeWriter(false));
  };

  let resetSettings = () => {
    dispatch(updateYouTubeWriterOptions(initialSettings));
    dispatch(updateYouTubeWritercontent({ action: 'overwrite', 'content': '' }));

  }

  function isDeepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;
    
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
      return false;
    }
    
    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);
    
    if (keys1.length !== keys2.length) return false;
    
    for (let key of keys1) {
      if (!keys2.includes(key) || !isDeepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
    return true;
  }
  
  let showSaveClear = isDeepEqual(initialSettings, promptSettings);
  let showGenerate = false;

  if(promptSettings.hasOwnProperty('url')){
    if(promptSettings.url.length > 0){
      showGenerate = true;
    }
  }

  const setPromptSettings = (newSettings)=>{dispatch(updateYouTubeWriterOptions(newSettings))};

  return (
    <Flex flex={1} align={'top'} justify={'center'} h={'100%'} m={'0px'}>
      <FormControl id="prompt">
        <Flex direction='column' m='0px'>
          <Flex direction='column' m='20px' gap={'50px'}>
            <Flex direction='column' gap='10px'>  
            <Flex w={'100%'} justify={'space-between'} mb={'30px'}>
              <Heading as='h1' size='xl' mb='2'>YouTube to article</Heading>
              <SelectSettings loadSettings={setPromptSettings} />
            </Flex>

              <Text fontSize='md'>
                Enter a url for a YouTube video, the agent will then analyze the video and generate SEO-optimized Google compliant content, in your brand voice.
              </Text>

            </Flex>

            <Url url={promptSettings.url} handleChange={handleChange}/>
            <Model model={promptSettings.model} handleChange={handleChange}/>
            <Flex gap={'10px'} w='100%'>
              <Language language={promptSettings.language} handleChange={handleChange}/>
              <TargetCountry targetCountry={promptSettings.targetCountry} handleChange={handleChange}/>
            </Flex>
            <ToneOfVoice 
              TOV={promptSettings.TOV} 
              handleChange={handleChange}/>
             <InternalLinks internalLinks={promptSettings.internalLinks} domain={promptSettings.domain} handleChange={handleChange}/>
            <FaqSection includeFAQ={promptSettings.includeFAQ} handleChange={handleChange}/>
            <KeyTakeaways includeKeyTakeaways={promptSettings.includeKeyTakeaways} handleChange={handleChange}/>
            <DisableIntro disableIntro={promptSettings.disableIntro} handleChange={handleChange}/>
            <DisableConclusion disableConclusion={promptSettings.disableConclusion} handleChange={handleChange}/>

          </Flex>
          <Flex
            pl={'20px'}
            pr={'20px'}
            mb={'-60px'}
            pt={'40px'}
            pb={'20px'}
            position="sticky" 
            bottom={0}
            width={"100%"}
            justify="space-between" 
            gap="20px" 
            zIndex="1" // Ensures that it will overlay other content
            bg="linear-gradient(to top, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0.0) 100%)" // Transparent gradient from solid white to transparent
            >
            <Button w="100%" colorScheme="blue" variant="solid" borderColor="gray.400" onClick={handleSubmit} isDisabled={!showGenerate || isLoadingYouTubeWriter}>
              {'Generate Article'}
            </Button>
            
            <ButtonGroup isAttached variant="outline">
              <Button w="120px" colorScheme="blue" variant="outline" leftIcon={<MdOutlineClear/>} borderColor="gray.400" onClick={()=>{resetSettings()}} isDisabled={showSaveClear || isLoadingYouTubeWriter}>
                {'Clear'}
              </Button>
              <SaveSettings options={promptSettings} showImprovePrompt={showSaveClear}/>
            </ButtonGroup>      
          </Flex>
        </Flex>
      </FormControl>
    </Flex>
  );
}
import React, { useState, useEffect } from 'react';
import { ChakraProvider, Flex } from "@chakra-ui/react";
import TOVManager from './TOVManager';

const ENV = process.env.NODE_ENV;

const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai'

const SelectTOV = ({ selectedOption, handleSelectChange, hideSelect }) => {
  const [TOVs, setTOVs] = useState([{name:'Loading writing styles...',_id:'loading'}]);

  const addTOV = async (TOV) => {
    try {
      const response = await fetch(`${api_url}/tov/create`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ TOV }),
      });

      if (response.ok) {
        const result = await response.text();
        fetchTOVs();
      } else {
        console.error('Error:', response.statusText);
      }
      return response;
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const fetchTOVs = async () => {
    try {
      const response = await fetch(`${api_url}/tov`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          // Add any required authorization headers here, e.g., a JWT token
          // 'Authorization': `Bearer ${yourJwtToken}`,
        },
      });

      if (response.ok) {
        const result = await response.json();
        setTOVs(result.length ? [...[
          {name:'Seo optimised', _id: '1'},
          {name:'Professional & Formal', _id: '2'},
          {name:'Casual & Relaxed', _id: '3'},
          {name:'Humorous & Entertainng', _id: '4'},
          {name:'Excited & Up-Beat', _id: '5'},
          {name:'Friendly & In-Formal', _id: '6'}
        ],...result] : [
          {name:'Seo optimised', _id: '1'},
          {name:'Professional & Formal', _id: '2'},
          {name:'Casual & Relaxed', _id: '3'},
          {name:'Humorous & Entertainng', _id: '4'},
          {name:'Excited & Up-Beat', _id: '5'},
          {name:'Friendly & In-Formal', _id: '6'}
        ]);
      } else {
        console.error('Error:', response.statusText);
        setTOVs([
          {name:'Seo optimised', _id: '1'},
          {name:'Professional & Formal', _id: '2'},
          {name:'Casual & Relaxed', _id: '3'},
          {name:'Humorous & Entertainng', _id: '4'},
          {name:'Excited & Up-Beat', _id: '5'},
          {name:'Friendly & In-Formal', _id: '6'}
        ]);
      }
    } catch (error) {
      console.error('Error:', error);
      setTOVs([
        {name:'Seo optimised', _id: '1'},
        {name:'Professional & Formal', _id: '2'},
        {name:'Casual & Relaxed', _id: '3'},
        {name:'Humorous & Entertainng', _id: '4'},
        {name:'Excited & Up-Beat', _id: '5'},
        {name:'Friendly & In-Formal', _id: '6'}
      ]);
    }
  };

  useEffect(() => {
    fetchTOVs();
  }, []);

  return (
    <ChakraProvider>
      <Flex w={'100%'}>
        <TOVManager
            selectedOption={selectedOption}
            handleSelectChange={handleSelectChange}
            fetchTOVs={fetchTOVs}
            TOVs={TOVs}
            addTOV={addTOV}
            hideSelect={hideSelect}
        />
      </Flex>
    </ChakraProvider>
  );
};

export default SelectTOV;
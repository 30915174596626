import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    templateList: [],
    templatesLoaded: false,
    template: {},
    imageTemplate: 'no-template'
}

export const templateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {
        templateIsLoaded: (state, action) => {
            state.templatesLoaded = true;
        },
        selectTemplate: (state, action) => {
            state.template = action.payload;
        },
        selectImageTemplate: (state, action) => {
            state.imageTemplate = action.payload;
        },
        updateTemplates: (state, action) => {
            state.templateList = action.payload;
        }
    }
});

export const { selectTemplate, selectImageTemplate, updateTemplates, templateIsLoaded } = templateSlice.actions;

export default templateSlice.reducer;
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTranscription, toggleLoading } from '../../store/contentSlice';
import axios from 'axios';
import {
  Button,
  Input,
  Flex,
  FormControl,
  Stack,
  Text,
  Box,
  VStack,
  useToast,
  CloseButton,
  Heading
} from '@chakra-ui/react';

import { IoCloudUploadOutline, IoCloudDownloadOutline } from "react-icons/io5";

import noUser from '../../utils/HandleNoUser'

const ENV = process.env.NODE_ENV;

const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai'

export default function UploadNewDomainFile({setViewMode, ViewMode, fetchDomains, onClose}) {
  const [dragging, setDragging] = useState(false);

  const toast = useToast();

  const [file, setFile] = useState(null);

  const handleBackClick = () => {
    setViewMode(ViewMode.DOMAINS);
  };

  const addDomain = async () => {
    try {
        //console.log('File: ',file);
        // Prepare the form data
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch(`${api_url}${'/domain/create'}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
            },
            body: formData
        });

        // Check if the response status code is OK
        if (response.ok) {
            // Parse the response body as JSON
            const data = await response.json();
            //Success toast here

            //fetch updated domains
            await fetchDomains();

            //Close modal
            onClose()

            return data;
        } else {
            // Handle HTTP errors
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    } catch (error) {
        console.error('Error sending request:', error);
        return { error };
    }
  }

  const onDrop = useCallback((event) => {
    event.preventDefault();
    setDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      if (file.type === 'text/csv') {
        setFile(file);
      } else {
        toast({
          title: 'Invalid file type',
          description: 'Please upload a CSV file.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [toast]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    setDragging(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setDragging(false);
  }, []);

    const openFileDialog = () => {
        const fileInput = document.getElementById('file-upload-input');
        if (fileInput) {
            fileInput.click();
        } else {
        }
    };  

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileRemove = () => {
    setFile(null); // Clear the selected file
  };

  const fileSize = (size) => {
    if (size < 1024) return size + ' bytes';
    else if (size >= 1024 && size < 1048576) return (size / 1024).toFixed(1) + ' KB';
    else if (size >= 1048576) return (size / 1024 / 1024).toFixed(1) + ' MB';
  };

  const downloadTemplate = async () => {
    try {
        // Change the fetch URL to use path parameter
        const response = await fetch(`/download/template/domains`, {
          method: 'GET',
          headers: {
            'Accept': 'text/csv',
          },
          credentials: 'include', // Only use 'include' if needed and server is set up for it
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
  
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'domainTemplate.csv'; // You can customize the file name
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        a.remove();
      } catch (err) {
        console.error('There was an error downloading the file:', err);
      }
  }

  return (
    <Stack w='100%' direction={{ base: 'column', md: 'row' }}>
      <Flex w='100%' pt={4} flex={1} align={'center'} justify={'center'} direction={"column"} gap={"30px"}>
      <Heading as={'h1'} size={'lg'} mb={'50px'}>Add / Update Domains</Heading>

        <Stack spacing={4} w={'100%'}>
          <FormControl id="file-upload">
            {/* Drag and Drop Area */}
            <VStack
              p={18} 
              pb={'20px'}
              pt={'20px'}
              borderWidth={2}
              borderRadius="md"
              borderStyle="dashed"
              borderColor={dragging ? 'blue.300' : 'gray.300'}
              bg={dragging ? 'blue.50' : 'transparent'}
              _hover={{ bg: 'blue.50' }}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              cursor="pointer"
              align="center"
              justify="center"
            >
              <Text pb={'30px'}>Drop your CSV file here</Text>
              <Flex gap={'20px'}>
                <Button onClick={openFileDialog} w={'160px'} variant="solid" colorScheme="blue" leftIcon={<IoCloudUploadOutline fontSize={30} />}>
                    Upload CSV
                </Button>
                <Button onClick={downloadTemplate} w={'160px'} variant="outline" colorScheme="blue" leftIcon={<IoCloudDownloadOutline fontSize={30} />}>
                    Template
                </Button>
              </Flex>
            </VStack>

            <Input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                size="md"
                id="file-upload-input"
                hidden
            />

            {/* Conditional rendering for file information */}
            {file && (
              <Flex mt={8} justify="space-between" align="center">
                <Box>
                  <Text fontWeight="bold">{file.name}</Text>
                  <Text fontSize="sm">Size: {fileSize(file.size)}</Text>
                </Box>
                <CloseButton onClick={handleFileRemove} />
              </Flex>
            )}

          </FormControl>
        </Stack>

        <Flex width={"100%"} justify={"center"} gap={"30px"}>
            <Button
                onClick={handleBackClick}
                variant='outline'
                colorScheme='blue'
                w='130px'
                size='xs'
                p={4}
                pl={8}
                pr={8}
            >
                Back
            </Button>
            <Button
                onClick={addDomain}
                isDisabled={file ? false : true}
                variant='solid'
                colorScheme='blue'
                w='130px'
                size='xs'
                p={4}
                pl={8}
                pr={8}
            >
                Add / Update
            </Button>
        </Flex>
        
      </Flex>
    </Stack>
  );
}
import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
} from '@chakra-ui/react';

export default function Knowledge() {
    
    const emailLink = "mailto:hello@veolabs.ai?subject=Knowledge&body=I'm%20interested%20in%20your%20upcoming%20Knowledge%20feature.%0D%0A%0D%0A";
    
    const handleClick = () => {
        window.location.href=emailLink;
    }

  return (
    <Container maxW={'3xl'}>
    <Stack
        as={Box}
        textAlign={'center'}
        spacing={{ base: 8, md: 14 }}
        py={{ base: 20, md: 36 }}>
        <Heading
        fontWeight={600}
        fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
        lineHeight={'110%'}>
        Coming soon...
        </Heading>
        <Text color={'gray.500'}>
        <strong>Knowledge</strong> will allow you to store crucial datasets for your models/agents to work with.
        <br/><br/>This is where you'll define product catalogues, brand identities, customer service processes, business information and more. 
        <br/><br/>You'll have control over how your data is stored and used, keeping it secure and private.
        </Text>
        <Stack
        direction={'column'}
        spacing={3}
        align={'center'}
        alignSelf={'center'}
        position={'relative'}>
        <Button
            colorScheme={'green'}
            bg={'green.400'}
            rounded={'full'}
            px={6}
            onClick={handleClick}
            _hover={{
            bg: 'green.500',
            }}
        >
            Register interest
        </Button>
        </Stack>
    </Stack>
    </Container>  
  );
}
import {
    Box,
    Heading,
    Container,
    Text,
    Button,
    Stack,
  } from '@chakra-ui/react';
  
  export default function Projects() {
      
      const emailLink = "mailto:hello@veolabs.ai?subject=Projects&body=I'm%20interested%20in%20your%20upcoming%20Projects%20feature.%0D%0A%0D%0A";
      
      const handleClick = () => {
          window.location.href=emailLink;
      }
  
    return (
      <Container maxW={'3xl'}>
      <Stack
          as={Box}
          textAlign={'center'}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}>
          <Heading
          fontWeight={600}
          fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
          lineHeight={'110%'}>
          Coming soon...
          </Heading>
          <Text color={'gray.500'}>
          <strong>Projects</strong> will allow you to save your work in easy to navigate folders.
          <br/><br/>With no code you'll be able to teach models to behave the way you want.
          <br/><br/>Finetuned on your data, provided with specific instructions and operating in unision, multiple models can make up one single agent. Designed to excel at highly specialised tasks.
          <br/><br/>We're working hard to bring this feature to the Veolabs.ai platform, in the meantime if you have any questions or want to make sure you're one of the first to try it out, please let us know...
          </Text>
          <Stack
          direction={'column'}
          spacing={3}
          align={'center'}
          alignSelf={'center'}
          position={'relative'}>
          <Button
              colorScheme={'green'}
              bg={'green.400'}
              rounded={'full'}
              px={6}
              onClick={handleClick}
              _hover={{
              bg: 'green.500',
              }}
          >
              Register interest
          </Button>
          </Stack>
      </Stack>
      </Container>  
    );
  }
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../components/SideBar';
import PromptInput from '../components/controls/PromptInput';
import Favourites from '../components/controls/Favourites';
import TemplateInput from '../components/controls/TemplateInput';
import Settings from '../components/controls/Settings';
import Advanced from '../components/controls/Advanced';
import { retrieveModels } from "../data/models";
import { retrieveTemplates } from "../data/templates";
import { updateModelList, loaded } from '../store/modelSlice';
import { setModel, setGenerateMedia } from '../store/generateOptionsSlice';
import { updateTemplates, templateIsLoaded, selectTemplate } from '../store/templateSlice';
import Quill from '../components/Quill';
import Loading from '../components/Loading';

import { 
  Flex, 
  Card,
  CardBody,
  Heading
} from '@chakra-ui/react'

function GenerateCopy(){
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const generateOptions = useSelector((state) => state.generateOptions.selection);
  const models = useSelector((state) => state.models.modelList);

  const selectedModel = useSelector((state) => state.generateOptions.model);

  useEffect(() => {
    const fetchData = async () => {
      try {

        if(models.length <= 0) {
          const modelList = await retrieveModels();
          dispatch(updateModelList(modelList));
          dispatch(loaded());
        }

        let loadModel = true;

        if(selectedModel !== null){
          if(selectedModel.generates.includes('Text')){
            loadModel = false;
          }
        }

        if(loadModel){
          models.forEach((m) => {
            if(m._id === '64ab27f9bd772e58bcb34ac3'){
              dispatch(setModel(m));
            }
          });
        }

        retrieveTemplates().then(templateList => {
            dispatch(updateTemplates(templateList));
            dispatch(templateIsLoaded());
            dispatch(selectTemplate(templateList));
            dispatch(setGenerateMedia('Text'));
        });

        setLoading(false);
        
      } catch(error) {
        console.log('Error fetching data', error);
      }
    };
    fetchData();
  }, [dispatch, models]);

  if (loading) {
    return <Loading />;
  }

  const { innerWidth: width} = window;

  if(width <= 900){
      return (
          <Flex
              justify={'center'}
              align={'center'}
              p={'20px'}
              >
              <Heading size={'sm'}>
                  Sorry we don't currently support screen sizes below 900px
              </Heading>
          </Flex>
      )
  }


  let selection = null;

  switch(generateOptions) {
    case 'Prompt':
        selection = <PromptInput />
      break;
      case 'Favourites':
        selection = <Favourites />
      break;
      case 'Templates':
        selection = <TemplateInput />
      break;
      case 'Settings':
        selection = <Settings />
      break;
      case 'Advanced':
        selection = <Advanced />
      break;
    default:
      selection = <PromptInput />
  }

  return (
    <Flex w='100%' h='100%'
      bgGradient='linear(to-t, #EBF3FD, #f3f0ff)'
      >
        <SideBar />
        <Flex
          w='100%'
          h='100%'
          direction='column' 
          p='20px'
          >
          <Flex 
            gap='20px' 
            w='100%'
            h='100%'
            >
            <Card 
              w='100%'
              minW='400px'
              variant='outline'
              boxShadow='md'
              overflow='scroll'
              flex='1'
            >
              <CardBody p='0px'>
                {selection}
              </CardBody>
            </Card>
            <Card 
              w='100%'
              h='100%'
              overflow='hidden'
              variant='outline'
              boxShadow='md'
              flex='2'
              >
              <CardBody pt='10px' w='100%' pb='70px' overflow='hidden'>
                <Quill />
              </CardBody>
            </Card>
          </Flex>              
        </Flex>
    </Flex>      
  )
}

export default GenerateCopy;
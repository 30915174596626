import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
    Card,
    Image,
    CardBody,
    Heading,
    CardFooter,
    Button,
    Flex,
    Text,
    Center,
    CircularProgress,
    Badge
} from '@chakra-ui/react';

import { updateBase64, updateImagePrompt, updateImageHistory } from '../../store/contentSlice';
import { setModel } from '../../store/generateOptionsSlice';

import history from '../../data/history';

function History({closeFunc}){
    const models = useSelector((state) => state.models.modelList);
    const images = useSelector((state) => state.content.imageHistory);

    const dispatch = useDispatch();

    if(images.length <= 0){
        return (
        <Flex w='100%' h='700px' align={'center'} justify={'center'}>
            <CircularProgress isIndeterminate color='blue.300' size='100px' thickness='4px'/>
        </Flex>
        )
    }

    const shortenDescription = (text) => {
        return text.length > 170 ? text.substring(0, 170) + "..." : text;
    }

    const handleEdit = (base64,prompt,imageModelId)=> {
        //update image prompt
        const imgData = base64.replace('data:image/png;base64,','');
        dispatch(updateBase64(imgData))
        //update image for the editor
        dispatch(updateImagePrompt(prompt));
        //update the nodel
        models.forEach((model) => {
            if(model._id === imageModelId){
                dispatch(setModel(model));
            }
        })
        closeFunc();
    };

    const handleDelete = (id)=> {
        //Delete image
        history.delImageHistory(id);
        //remove image from state
        dispatch(updateImageHistory([2,id]));
    };

    const downloadFile = (file) => {
        // Create a hidden link and set the URL using createObjectURL
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = file;
        let timestamp = new Date().toString();
        link.download = `veolabs-generated-image-${timestamp}`;
    
        // We need to add the link to the DOM for "click()" to work
        document.body.appendChild(link);
        link.click();
    
        // To make this work on Firefox we need to wait a short moment before clean up
        setTimeout(() => {
            URL.revokeObjectURL(link.href);
            link.parentNode.removeChild(link);
        }, 0);
    };

    const renderedImages = images.map((image, index) => (
        <Card key={image._id}
            direction={{ base: 'column', sm: 'row' }}
            overflow='hidden'
            variant='outline'
            height='610px'
            width='400px'
            margin='10px'
            boxShadow='md'
        >
            <Flex direction='column' justify='space-between' align='center' w='100%'>
                {image.isLoading ? (    // If image is loading, show a spinner
                <Flex boxSize='390px' padding='10px' align={'center'} justify={'center'}>
                    <CircularProgress isIndeterminate color='blue.300' size='100px' thickness='4px'/>
                </Flex>
                    
                ) : (
                    <Image boxSize='388px' padding='10px'
                        src={images[index].src} alt={image.title} />
                )}
                <CardBody p={'15px'} mb={'0px'} pb={'0px'}>
                    <Center>
                        <Flex direction={'column'} justify={'center'} align={'center'}>
                            <Heading size='sm'>{image.title}</Heading>
                            <Text py='2' fontSize='xs'>
                                {shortenDescription(image.description)}
                            </Text>
                            <Badge variant='solid' mr='5px' ml='5px' mt='10px' key={image.model}>{image.modelName}</Badge>
                        </Flex>
                    </Center>
                </CardBody>
                <CardFooter mt={'0px'} pt={'0px'}>
                    <Button 
                        isDisabled={image.isLoading}
                        size='md' 
                        w='90px' 
                        mr='5px' 
                        variant='outline' 
                        colorScheme='blue' 
                        onClick={() => {handleEdit(images[index].src,images[index].prompt,image.modelID)}}
                    >
                        Edit
                    </Button>
                    <Button
                        isDisabled={image.isLoading}
                        variant='solid' 
                        colorScheme='blue'
                        size='md' 
                        w='110px' 
                        mr='5px'
                        ml='5px'
                        onClick={()=>{downloadFile(images[index].src)}}
                    >
                        Download
                    </Button>
                    <Button 
                        isDisabled={image.isLoading}
                        size='md' 
                        w='90px' 
                        ml='5px' 
                        variant='solid' 
                        colorScheme='red' 
                        onClick={() => {handleDelete(images[index]._id)}}
                    >
                        Delete
                    </Button>
                </CardFooter>
            </Flex>
        </Card>
    ));

    return (
        <Flex direction="column" width='100%' sx={{userSelect: 'none'}}>
            <Flex wrap='wrap' justify='center' maxWidth='1400px' margin='auto'>
                {renderedImages}
            </Flex>
        </Flex>
    );
}

export default History;
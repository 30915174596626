import React from 'react';
import { useState } from 'react';
import { 
    Card, 
    Image,
    CardBody,
    Heading,
    CardFooter,
    Button,
    Flex,
    Text,
    Badge,
    Center
} from '@chakra-ui/react';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { retrieveTemplates } from "../../data/templates";
import { updateTemplates, templateIsLoaded, selectImageTemplate } from '../../store/templateSlice';
import Loading from '../../components/Loading';

function Templates(closeFunc){
    const model = useSelector((state) => state.generateOptions.model);
    const generateMedia = useSelector((state) => state.generateOptions.generateMedia);
    let [filterSelection, setFilterSelection] = useState('');

    const templateList = useSelector((state) => state.templates.templateList);
    const templatesLoaded = useSelector((state) => state.templates.templatesLoaded);

    const dispatch = useDispatch();
    
    const templatesForCurrentModel = templateList.reduce((result,template) => {
        if(template.models.includes(model.title)){
            result.push(template);
        }
        return result;
    },[]);

    const uniqueCategories = [];
    const uniqueCategoriesDict = [];

    templatesForCurrentModel.forEach(template => {
        if(template.media === generateMedia){
            if(!uniqueCategories.includes(template.category)){
                uniqueCategories.push(template.category);
                uniqueCategoriesDict.push([template.category,true]);
            }
        }
    });

    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;
        
        // While there remain elements to shuffle.
        while (currentIndex > 0) {
        
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
        
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    if(!templatesLoaded) {
        retrieveTemplates().then(templateList => {
            dispatch(updateTemplates(shuffle(templateList)));
            dispatch(templateIsLoaded());
        });
        return <Loading />
    }

    const selectCategory = (e) => {
        const category = e.target.innerText;
        if(filterSelection === category){
            setFilterSelection('');
        } else {
            setFilterSelection(category);
        }
    }
    
    const categorySelection = (
        <Flex w='95%' justify={'center'} margin='auto' marginBottom='25px' marginTop='25px' wrap='wrap' borderRadius={5}>
            {uniqueCategories.map((category) => {
                return <Button size='sm' m={'10px'} colorScheme={category === filterSelection ? 'blue' : 'gray'} onClick={selectCategory} key={category}>{category}</Button>
            })}
        </Flex>
    )

    const renderedTemplates = templateList.map(template => {

        if(!template.models.includes(model.title)){
            return null;
        }

        if(template.media !== generateMedia){
            return null;
        }
        
        if(filterSelection !== ''){
            if(template.category !== filterSelection){
                return null;
            }
        }

        const handleSelectTemplate = ()=> {
            dispatch(selectImageTemplate(template));
            closeFunc.closeFunc();
        };

        const renderedTags = template.tags.map(tag => {
            return <Badge variant='solid' mr='5px' ml='5px' key={tag}>{tag}</Badge>
        });
        
        return <Card
            key={template._id}
            direction={{ base: 'column', sm: 'row' }}
            overflow='hidden'
            variant='outline'
            height='590px'
            width='400px'
            margin='10px'
            boxShadow='md'
            >
            <Flex direction='column' justify='space-between' align='center' w='100%'>
                <Image
                    boxSize='390px'
                    padding='10px'
                    src={`/images/${template.imgPath}`}
                    alt={template.name}
                />
                <CardBody padding={'15px'} margin={'auto'}>
                    <Center>
                        <Flex direction={'column'} justify={'center'} align={'center'}>
                            <Heading size='sm'>{template.name}</Heading>
                            <Text py='2' fontSize='xs'>
                                {template.description}
                            </Text>
                        </Flex>
                    </Center>
                </CardBody>
                <Flex margin='auto'>
                    {renderedTags}
                </Flex>
                <CardFooter>
                    <Button size='md' w='100px' variant='solid' colorScheme='blue' onClick={handleSelectTemplate}>
                        Select
                    </Button>
                </CardFooter>
            </Flex>
        </Card>
    })
    
    return (
        <Flex direction="column" width='100%' sx={{userSelect: 'none'}}>
            {categorySelection}
            <Flex wrap='wrap' justify='center' maxWidth='1400px' margin='auto'>
                {renderedTemplates}
            </Flex>
        </Flex>
    )
}

export default Templates;
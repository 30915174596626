import axios from 'axios'
import mixpanel from 'mixpanel-browser';
import noUser from '../utils/HandleNoUser';

const ENV = process.env.NODE_ENV;

const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai'


export const generateElevenlabs = async (prompt, voice, stateUpdate, stream) => {
  
  const apiRoute = `${api_url}/elevenlabs`;
  const voiceId = voice?.voice_id;
  const now = new Date();
  let requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 
      'Content-Type': 'application/json',
      'timestamp': now
    },
    body: JSON.stringify({ prompt, voiceId }),
  };

  let sourceBuffer; // declare here
  let sourceopen = false;
  let allChunks = []; // maintain a separate array to collect all chunks

  if (window.MediaSource) {
    
    let mediaSource = new MediaSource();
    let audioURL = URL.createObjectURL(mediaSource);
    stateUpdate.updateUri(audioURL);
  
    mediaSource.addEventListener('sourceopen', async () => {

      //check if media sourceopen exists already
      //prevents audio element from re-running the below script
      if(!sourceopen){
      sourceopen = true;  
      if (!sourceBuffer) {
        // Create a sourceBuffer only once and reuse it
        sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
      }
  
      let appending = false;

      async function appendNext(queue) {
        if (!appending) {
          appending = true;
          sourceBuffer.appendBuffer(queue.shift());
          sourceBuffer.addEventListener('updateend', () => {
            appending = false;
            if (queue.length) {
              appendNext(queue);
            } else {
              mediaSource.endOfStream();
            }
          }, { once: true });
        }
      }
  
      try {
        let response;
        if(stream){
          response = stream;
        } else {
          response = await fetch(apiRoute, requestOptions);
        }
        if (!response.ok) throw new Error(response.status);

          let reader = response.body.getReader();
          let queue = [];
    
          reader.read().then(function process({ done, value }) {
            if (!done) {
              // add to queue
              queue.push(value);
              allChunks.push(value); // save the chunk into allChunks as well
              appendNext(queue);
              return reader.read().then(process); // this is the recursion step
            } else {
              // stream is done
              const blob = new Blob(allChunks, { type: 'audio/mpeg' });
              const downloadURL = URL.createObjectURL(blob);
              
              stateUpdate.updateblob(downloadURL);
              if(!stream){
                stateUpdate.toggleLoading();
              }
            }
          });
      } catch (error) {
        console.error('Error:', error);
      }
      }
      
    });
  } else {
    console.error('MediaSource API is not supported');
  }
}

export const elevenLabsVoices = async () => {
  const apiRoute = `${api_url}/elevenlabs/voices`;
  // Track an event. It can be anything, but in this example, we're tracking a prompt sent event.
  mixpanel.track('Prompt sent', {
    'Model': 'ElevenLabs'
  })
  return await axios.post(apiRoute).then((response) => {
    if(response.data === 'no user'){
      noUser();
    } else {
      return response.data;
    }
  }).catch(err => console.log(err))
}
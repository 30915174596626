import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditTemplate from '../components/controls/EditTemplate';
import TemplateSelector from '../components/controls/TemplateSelector';
import { retrieveTemplates } from "../data/templates";
import { retrieveModels } from "../data/models";
import { updateTemplates, templateIsLoaded } from '../store/templateSlice';
import { updateModelList, loaded } from '../store/modelSlice';
import Loading from '../components/Loading';
import { 
    Flex,
} from '@chakra-ui/react';

function ManageTemplates(){
    const dispatch = useDispatch();
    const templatesLoaded = useSelector((state) => state.templates.templatesLoaded);
    const modelListLoaded = useSelector((state) => state.models.loadedModelList);

    if(!modelListLoaded) {
        retrieveModels().then(modelList => {
            dispatch(updateModelList(modelList));
            dispatch(loaded());
        });
        return <Loading />
      }

    if(!templatesLoaded) {
        retrieveTemplates().then(templateList => {
            dispatch(updateTemplates(templateList));
            dispatch(templateIsLoaded());
        });
        return <Loading />
    }
      
    return(
        <Flex wrap='wrap' justify='center' pt={'100px'}>
            <TemplateSelector />
            <EditTemplate/>
        </Flex>
    )
}

export default ManageTemplates;
import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { updateConversationId, updateCopyHistory, update, updateCopyPrompt } from '../../store/contentSlice';
import history from '../../data/history';
import { setModel } from '../../store/generateOptionsSlice';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Text,
    Flex
} from '@chakra-ui/react'

import {
    RiChatDeleteLine
} from 'react-icons/ri'

export default function ChatHistory() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const modelList = useSelector((state) => state.models.modelList);
    const dispatch = useDispatch();
    const copyHistory = useSelector((state) => state.content.copyHistory);

    const getHistory = async () => {
        const chatHistoryData = await history.getCopyHistory();
        dispatch(updateCopyHistory(chatHistoryData));
    }

    if(copyHistory[0] === 'no-history'){
        getHistory();
        return;
    }

    const historyAvailable = copyHistory.length >0;

    const handleClick = (copy) => {
        //set model
        modelList.forEach((model) => {
            if(model._id === copy.modelID){
                dispatch(setModel(model));
            }
        })
        //set conversationID
        dispatch(updateConversationId(copy.conversationId));

        //set prompt
        dispatch(updateCopyPrompt(copy.prompt))

        //set content
        dispatch(update(copy.copy))

        onClose();
    }

    const handleDelClick = (copy) => {
        //delete chat from DB
        history.delCopyHistory(copy._id);

        //delete chat from state
        const newCopyHistory = copyHistory.filter((chatRecord)=>{
            return copy._id !== chatRecord._id;
        })

        //update state
        dispatch(updateCopyHistory(newCopyHistory));
    }

    const RenderedHistoryList = copyHistory.map((chat) => {
        const options = { weekday: 'short', month: 'short', day: 'numeric', 
                      hour: 'numeric', minute: 'numeric'};
        const dateTime = new Date(chat.dateTime);
        const formattedDateTime = dateTime.toLocaleDateString("en-US", options);

        return (
            <Tr _hover={{ bg: "gray.100", cursor: "pointer" }} key={chat.dateTime}>
                <Td textAlign={'center'} onClick={() => {handleClick(chat)}} key={`name-${chat.title}`}><Text as='b'>{chat.title}</Text></Td>
                <Td textAlign={'center'} onClick={() => {handleClick(chat)}} key={`model-${chat.dateTime}`}>{chat.modelName}</Td>
                <Td textAlign={'center'} onClick={() => {handleClick(chat)}} key={`Title-${chat.dateTime}`} sx={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{chat.description}</Td>
                <Td textAlign={'center'} onClick={() => {handleClick(chat)}} key={`Date-${chat.dateTime}`}>{formattedDateTime}</Td>
                <Td textAlign={'center'} onClick={() => {handleDelClick(chat)}} key={`Del-${chat.dateTime}`}><Flex justify={'center'}><RiChatDeleteLine color='#eb264f' fontSize='1.5rem'/></Flex></Td>
            </Tr>
        )
    })

    return (
      <>
        <Button w={'200px'} onClick={onOpen} display={!historyAvailable ? 'none' : null} variant='outline' 
          colorScheme='blue'>View history</Button>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent minW='1000px'>
            <ModalHeader align='center' bgColor='#f2f6fc'>Select a previous generation to continue</ModalHeader>
            <ModalCloseButton mt='8px'/>
            <ModalBody maxH='700px' overflow={'scroll'}>
                <Flex minH='100%' minW='100%' justify={'center'} align='center'>
                    <TableContainer>
                        <Table variant='simple' size='sm' overflowWrap={'auto'}>
                            <Thead>
                                <Tr height='60px'>
                                    <Th textAlign={'center'} key='Name'>Name</Th>
                                    <Th textAlign={'center'} key='Model'>Model</Th>
                                    <Th textAlign={'center'} key='Description'>Description</Th>
                                    <Th textAlign={'center'} key='Date'>Date/Time</Th>
                                    <Th textAlign={'center'} key='Del'>Delete</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {RenderedHistoryList}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Flex>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }
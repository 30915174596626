import React, { useState, useEffect } from 'react';
import { Box } from "@chakra-ui/react";
import PublishManager from './PublishManager';
import publishConnections from '../../data/publishConnections';

const SelectPublish = ({content, disable}) => {
  const [connections, setConnections] = useState([]);

  const addConnection = async (connection) => {
    const response = await publishConnections.create(connection);
    return response;
  };

  const fetchConnections = async () => {
    const connectionsData = await publishConnections.read();
    setConnections(connectionsData);
  };

  const deleteConnection = async (props) => {
    const response = await publishConnections.delete(props);
    return response;
  };

  const publish = async (props) => {
    const response = await publishConnections.publish(props);
    return response;
  };

  useEffect(() => {
    fetchConnections();
  }, []);

  return (
      <Box>
        <PublishManager
            fetchConnections={fetchConnections}
            deleteConnection={deleteConnection}
            connections={connections}
            addConnection={addConnection}
            content={content}
            publish={publish}
            disable={disable}
        />
      </Box>
  );
};

export default SelectPublish;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import UploadNewDomainFile from './UploadNewDomain';

import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalContent,
  Flex,
  Select,
  Heading,
  Text,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter
} from '@chakra-ui/react';

const ViewMode = {
  DOMAINS: 'domains',
  CONNECTIONS: 'connections',
  EDIT_CONNECTION: 'editConnection',
  CREATE: 'create',
};

//Placeholder for your connections view component
const CreateNewView = ({setViewMode,ViewMode,onClose,fetchDomains,setDomains}) => {
    return (
        <Flex direction={'column'} align={'center'} w={'100%'} h={'100%'}>
            <Flex direction={'column'} align={'center'} w={'70%'} gap={'10px'}>
              <UploadNewDomainFile 
                setViewMode={setViewMode} 
                ViewMode={ViewMode} 
                onClose={onClose}
                fetchDomains={fetchDomains}
                setDomains={setDomains}
                />
            </Flex>
        </Flex>
    );
};

function Delete({domain,setViewMode,close,fetchDomains}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()

  const handleDelete = async () => {
    // Presumably, your API endpoint would be something like this
    // Make sure to adjust it to your actual API endpoint
    await fetch('/domains/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'include'
      },
      body: JSON.stringify({ domain }),
    });
    setViewMode(ViewMode.DOMAINS);
    await fetchDomains();
    onClose();
    close();
  }

  return (
    <>
      <Button 
        onClick={onOpen}
        colorScheme='red' 
        w="130px"
        size="xs"
        p={4}
        pl={8}
        pr={8}
      >
        Delete 
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Domain
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

const RenderPublishView = ({domains,setViewMode,close,fetchDomains}) => {
  const [domain, setDomain] = useState(domains[0]);

  const handleNewConnectionClick = () => {
      setViewMode(ViewMode.CREATE);
  };

  const handleDownload = async () => {
    if (domain) {
      try {
        // Change the fetch URL to use path parameter
        const response = await fetch(`/domains/csv/${encodeURIComponent(domain)}`, {
          method: 'GET',
          headers: {
            'Accept': 'text/csv',
          },
          credentials: 'include', // Only use 'include' if needed and server is set up for it
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
  
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'domain-record.csv'; // You can customize the file name
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        a.remove();
        close();
      } catch (err) {
        console.error('There was an error downloading the file:', err);
      }
    }
  };


  return (
    <Flex direction="column" align="center" gap="50px" mt="70px" w='100%'>
      <Heading as={'h1'} size={'lg'}>Manage domains</Heading>
      {domains && domains.length > 0 ? (
          <Flex align="center" justify="center" w='100%'>
            <Select
              onChange={(e) => {
              const selectedDomain = domains.find(d => d.domain === e.target.value);
              setDomain(selectedDomain || domains[0]); // If not found, select the first one as fallback
            }}>
              {domains.map((d) => (
                <option key={d} value={d}>{d}</option>
              ))}
            </Select>
          </Flex>
      ) : (
        <Text>No domains found</Text>
      )}
      <Flex direction="row" gap="10px">
        <Button
          onClick={handleDownload}
          variant="outline"
          colorScheme="blue"
          w="130px"
          size="xs"
          p={4}
          pl={8}
          pr={8}
        >
          Download
        </Button>

        <Button
          onClick={handleNewConnectionClick}
          variant="outline"
          colorScheme="blue"
          w="130px"
          size="xs"
          p={4}
          pl={8}
          pr={8}
        >
          Add / Update
        </Button>

        <Delete setViewMode={setViewMode} domain={domain} close={close} fetchDomains={fetchDomains}/>

      </Flex>
    </Flex>
  );
};

function ManageDomains({domains,fetchDomains}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [viewMode, setViewMode] = useState(ViewMode.DOMAINS);

  // Handles closing the modal and resetting the view to the initial state
  const handleClose = () => {
    setViewMode(ViewMode.DOMAINS);
    onClose();
  };

  return (
    <>
      <Button
        onClick={onOpen}
        w="100%" 
        colorScheme="blue" 
        variant="outline" 
        borderColor="gray.400"
      >
        Manage Domains
      </Button>

      <Modal isCentered onClose={handleClose} isOpen={isOpen} motionPreset='slideInBottom'>
        <ModalOverlay />
        <ModalContent minW={'800px'}>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody maxH={'400px'} minH={'400px'} overflow='scroll'>
            {viewMode === ViewMode.DOMAINS && <RenderPublishView  setViewMode={setViewMode} close={handleClose} domains={domains} fetchDomains={fetchDomains}/>}
            {viewMode === ViewMode.CREATE && <CreateNewView 
              setViewMode={setViewMode} 
              ViewMode={ViewMode} 
              onClose={handleClose}
              fetchDomains={fetchDomains}
              />}
            {/* Here you can add additional cases for EDIT_CONNECTION and NEW_CONNECTION */}
          </ModalBody>
          <ModalFooter justifyContent='right' margin='15px'>
            {/* Footer can stay empty or contain additional controls as needed */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ManageDomains;
import React, { useEffect, useContext } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateVeoWritercontent, toggleLoading, updateVeoWriterActions, updateVeoWriterActionsLoading } from '../../store/contentSlice';
import Generate from '../../promptApi/generateImage';
import { updateCredits } from '../../store/userSlice';
import axios from 'axios'
import noUser from '../../utils/HandleNoUser';
import GenerateVeoWriter from '../../promptApi/agents/VeoWriter';
import UploadFile from './UploadFile';
import ManageDomains from './ManageDomains';
import LoadingOverlay from './Loading';
import WebSocketContext from '../../WebsocketContext'; // adjust the path as necessary

import {
  Button,
  Textarea,
  Flex,
  FormControl,
  Select,
  Tooltip,
  Text,
  Heading,
} from '@chakra-ui/react';

import { BsFillInfoCircleFill } from "react-icons/bs";

const ENV = process.env.NODE_ENV;

const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai'

const isValidUrl = (urlString) => {
  const pattern = new RegExp(
    `^(https?:\\/\\/)?` + // protocol
    `(([\\w\\d]([\\w\\d-]*[\\w\\d])*)\\.)+` + // domain name including subdomains
    `([a-z]{2,})` + // TLD, at least two characters
    `(\\:\\d+)?` + // port
    `(\\/[-a-z\\d%_.~+]*)*` + // path
    `(\\?[;&a-z\\d%_.~+=-]*)?` + // query string
    `(\\#[-a-z\\d_]*)?$`, // fragment locator
    'i'
  );
  return pattern.test(urlString);
};

const Method = ({method, contentSample, handleChange, setFile, file}) => {  
  const buttonStyles = {
    default: {
      w: '130px',
      fontSize: 'sm', // Adjust font size here
      _hover: { bg: 'gray.100' }, // No background change on hover for default buttons
    },
    selected: {
      w: '130px',
      fontSize: 'sm', // Adjust font size here
      bgColor: 'blue.500',
      color: 'white',
      _hover: { bg: 'blue.500' }, // Retain the selected color on hover
    },
  };

  const kwMethodTT = 'Default: We wont expand on your target keyword, your chosen model will use it pre-trained knowledge. AI-Powered: We will analyze top ranking pages and extract up to 100 topically relevant keywords and entities to use in the article. This results in a much more comprehensive article that has a better chance of ranking. Manual: Specify a list of keywords to be used in the article. ';

  return (
    <Flex direction={'column'} gap={'10px'}>
      <Flex align={'center'}>
        <Heading size='sm' as='h1'>Content format</Heading>
        <Tooltip label={kwMethodTT}>
          <Flex pl={'5px'}>
            <BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }} />
          </Flex>
        </Tooltip>
      </Flex>
      <Flex w='100%' gap='10px' mt='10px'>

        <Button
          {...(method === 'bulk' ? buttonStyles.selected : buttonStyles.default)}
          onClick={() => handleChange({key: 'method', value: 'bulk'})}
        >
          Batch/CSV
        </Button>

        <Button
          {...(method === 'sample' ? buttonStyles.selected : buttonStyles.default)}
          onClick={() => handleChange({key: 'method', value: 'sample'})}
        >
          Sample
        </Button>
        
        
        <Button
          {...(method === 'url' ? buttonStyles.selected : buttonStyles.default)}
          onClick={() => handleChange({key: 'method', value: 'url'})}
        >
          URL
        </Button>

      </Flex>

      {method === 'bulk' && (
        <UploadFile setFile={setFile} file={file}/>
      )}

      {method === 'sample' && (
        <Textarea
          data-key='contentSample'
          value={contentSample}
          onChange={(e) => { handleChange({ key: e.target.id, value: e.target.value }) }}
          placeholder='Enter the content you want to optimise with internal links.'
          size='sm'
          mt='15px'
          w='100%'
          minH="147px"
          id={'contentSample'}
        />
      )}

      {method === 'url' && (
        <Textarea
          data-key='contentSample'
          value={contentSample}
          onChange={(e) => { handleChange({ key: e.target.id, value: e.target.value }) }}
          placeholder='Enter your content URL.'
          size='sm'
          mt='15px'
          w='100%'
          minH="147px"
          id={'contentSample'}
        />
      )}  

    </Flex>
  );
};

const Domain = ({domainList, domainOption, handleChange, domain, setDomain}) => {  

  const kwMethodTT = 'Default: We wont expand on your target keyword, your chosen model will use it pre-trained knowledge. AI-Powered: We will analyze top ranking pages and extract up to 100 topically relevant keywords and entities to use in the article. This results in a much more comprehensive article that has a better chance of ranking. Manual: Specify a list of keywords to be used in the article. ';
  return (
    <Flex direction={'column'} gap={'10px'} mb='50px'>
      <Flex align={'center'}>
        <Heading size='sm' as='h1'>Select Domain</Heading>
        <Tooltip label={kwMethodTT}>
          <Flex pl={'5px'}>
            <BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }} />
          </Flex>
        </Tooltip>
      </Flex>
      <Flex w='100%' gap='10px' mb='10px' mt='10px'>
        <Select value={domainOption} onChange={(e) => handleChange({ key: 'domainOption', value: e.target.value })}>
          {domainList.map((thisDomain) => (
            <option key={thisDomain} value={thisDomain}>{thisDomain}</option>
          ))}
        </Select>
      </Flex>
    </Flex>
  );
};


export default function LinkerSettings() {
  const isLoading = useSelector((state) => state.content.isLoading);
  const [file, setFile] = useState(null);
  const [LinkerActions, setLinkerActions] = useState([]);
  const [LinkerActionsLoading, setLinkerActionsLoading] = useState(false);
  const [domain, setDomain] = useState('No domains loaded');
  const [domains, setDomains] = useState([]);
  const ws = useContext(WebSocketContext);

  useEffect(() => {
    if (ws) {
      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data); // make sure to send JSON from the server
          setLinkerActions((prevActions) => [...prevActions, data.message]);
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };
    }
  }, [ws])

  const fetchDomains = async () => {
    try {
      // Using fetch and including credentials for the possibility of session-based authentication
      const response = await fetch('/domains', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // This includes cookies in the request, necessary for some APIs
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      setDomains(data);
      setPromptSettings({...promptSettings,domainOption: data[0]});
    } catch (error) {
      console.error('Failed to fetch domains:', error);
    }
  };

  let initialSettings = {
    method: 'bulk',
    contentSample: '',
    maxLinks: 1,
    domainOption: ''
  };

  useEffect(() => {
    fetchDomains();
  }, []);

  const [promptSettings, setPromptSettings] = useState(initialSettings);

  const handleChange = selection => {
    let {key, value} = selection;
    let newSettings = {
      ...promptSettings,
      [key]: value
    }
    setPromptSettings(newSettings);
  }

  let showImprovePrompt = file ? false : true;

  const uploadContent = async () => {
    try {
        //console.log('File: ',file);
        // Prepare the form data
        setLinkerActionsLoading(true);

        const formData = new FormData();
        formData.append('file', file);

        const domain = promptSettings.domainOption;
        formData.append('domain', domain);
        const response = await fetch(`${api_url}${'/upload-content-csv'}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Accept': 'text/csv',
            },
            body: formData
        });

        // Check if the response status code is OK
        if (response.ok) {
          console.log(response);

          setLinkerActionsLoading(...LinkerActions,'hello');

          const blob = await response.blob();
          const downloadUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = 'optimisedContent.csv'; // You can customize the file name
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(downloadUrl);
          a.remove();
          setLinkerActionsLoading(false);
          setLinkerActions([]);
        } else {
          throw new Error(`Error: ${response.statusText}`);
        }
  
        
    } catch (error) {
        console.error('Error sending request:', error);
        return { error };
    }
  }

  return (
    <Flex flex={1} align={'top'} justify={'center'} h={'100%'} m={'0px'}>
      <FormControl id="prompt">
        <Flex direction='column' m='0px'>
          <Flex direction='column' m='20px' gap={'50px'}>
            <Flex direction='column' gap='10px'>  
              <Heading as='h1' size='xl' mb='2'>Linker</Heading>
              <Text fontSize='md'>
                Optimise existing content for internal links.
              </Text>
            </Flex>
            <Method method={promptSettings.method} handleChange={handleChange} setFile={setFile} file={file}/>
            <Domain domainList={domains} handleChange={handleChange}/>
          </Flex>
          <Flex
            pl={'20px'}
            pr={'20px'}
            mb={'-60px'}
            pt={'40px'}
            pb={'20px'}
            position="sticky" 
            bottom={0}
            width={"100%"}
            justify="space-between" 
            gap="20px" 
            zIndex="1" // Ensures that it will overlay other content
            bg="linear-gradient(to top, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0.0) 100%)" // Transparent gradient from solid white to transparent
            >
            <Button w="100%" colorScheme="blue" variant="solid" borderColor="gray.400" onClick={uploadContent} isDisabled={showImprovePrompt} isLoading={isLoading}>
              {'Optimise links'}
            </Button>
            <ManageDomains 
              domains={domains} 
              handleChange={handleChange} 
              fetchDomains={fetchDomains}/>
          </Flex>
          <LoadingOverlay LinkerActions={LinkerActions} LinkerActionsLoading={LinkerActionsLoading}/>        
        </Flex>
      </FormControl>
    </Flex>
  );
}
import React from 'react';
import { useState, useEffect } from 'react';
import { updateConversation } from '../../store/contentSlice';

import { 
    Card, 
    Image,
    CardBody,
    Heading,
    CardFooter,
    Button,
    Flex,
    Text,
    Badge,
    Stack
} from '@chakra-ui/react';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setModel } from '../../store/generateOptionsSlice';

function ModelsView(closeFunc){
    const model = useSelector((state) => state.generateOptions.model);
    const modelList = useSelector((state) => state.models.modelList);
    const generateMedia = useSelector((state) => state.generateOptions.generateMedia);
    const dispatch = useDispatch();

    const renderedModels = modelList.map(model => {
        if(!model.generates.includes(generateMedia)){
            return null;
        }
        
        const handleSelectModel = ()=> {
            dispatch(setModel(model));
            dispatch(updateConversation([3,]))
            closeFunc.closeFunc();
        };

        const renderedTags = model.abilities.map(tag => {
            return <Badge variant='solid' mr='5px' ml='5px' fontSize={'0.65rem'} key={tag}>{tag}</Badge>
        });
        
        return <Card
            key={model.title}
            direction={{ base: 'column', sm: 'row' }}
            overflow='hidden'
            variant='outline'
            height='220px'
            width='540px'
            margin='15px'
            boxShadow='md'
        >
            <Image
                objectFit='cover'
                maxW='190px'
                src={`/images/${model.icon}`}
                alt={model.title}
            />
            <Stack>
                <CardBody>
                    <Heading size='xs'>{model.title}</Heading>
                    <Text py='2' fontSize='xs'>
                    {model.description}
                    </Text>
                    {renderedTags}
                </CardBody>
                <CardFooter justify='right' pt='0px'>
                    <Button variant='solid' colorScheme='blue' mr='15px' w='90px' size={'sm'} mb={'10px'} onClick={handleSelectModel}>
                        Select
                    </Button>
                </CardFooter>
            </Stack>
        </Card>
    })
    
    return (
        <Flex direction="column" width='100%' sx={{userSelect: 'none'}}>
            {}
            <Flex wrap='wrap' justify='center' maxWidth='1400px' margin='auto'>
                {renderedModels}
            </Flex>
        </Flex>
    )
}

export default ModelsView;
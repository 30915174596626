import axios from 'axios'
import noUser from '../utils/HandleNoUser'

const ENV = process.env.NODE_ENV;

const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai'

export const retrieveAgents = async () => {
  return await axios.get(`${api_url}/data/agents/`,{withCredentials: true}).then((response) => {
    if(response.data === 'no user'){
      noUser();
    } else {
      return response.data;
    }
  }).catch(err => console.log(err))
}


import noUser from '../utils/HandleNoUser'
import mixpanel from 'mixpanel-browser';

const publishConnections = {

    create: async (props) => {
        const ENV = process.env.NODE_ENV;
        const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai';
        const apiRoute = `/publishConnection/create`;

        try {
            const response = await fetch(`${api_url}${apiRoute}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(props)
            });
    
            // Check if the response status code is OK
            if (response.ok) {
                return response;
            } else {
                // Handle HTTP errors
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error sending request:', error);
            return { error };
        }
    },
    read: async (props) => {
        const ENV = process.env.NODE_ENV;
        const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai';
        const apiRoute = `/publishConnection`;

        try {
            const response = await fetch(`${api_url}${apiRoute}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(props)
            });
    
            // Check if the response status code is OK
            if (response.ok) {
                // Parse the response body as JSON
                const data = await response.json();
                return data;
            } else {
                // Handle HTTP errors
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error sending request:', error);
            return { error };
        }

    },
    publish: async (props) => {
        console.log(props);
        const ENV = process.env.NODE_ENV;
        const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai';
        const apiRoute = `/publishConnection/publish`;

        try {
            const response = await fetch(`${api_url}${apiRoute}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(props)
            });

            console.log('response: ',response);
    
            return response;

        } catch (error) {
            console.error('Error sending request:', error);
            return { error };
        }
    },
    delete: async (props) => {
        console.log(props);
        const ENV = process.env.NODE_ENV;
        const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai';
        const apiRoute = `/publishConnection/delete`;

        try {
            const response = await fetch(`${api_url}${apiRoute}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(props)
            });
    
            return response;

        } catch (error) {
            console.error('Error sending request:', error);
            return { error };
        }
    }
}

export default publishConnections;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    agentList: [],
    loadedAgentList: false
}

export const agentSlice = createSlice({
    name: 'agents',
    initialState,
    reducers: {
        loaded: (state, action) => {
            state.loadedAgentList = true;
        },
        updateAgentList: (state, action) => {
            state.agentList = action.payload;
        }
    }
});

export const { updateAgentList, loaded } = agentSlice.actions;


export default agentSlice.reducer;
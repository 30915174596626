import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeUser: {},
    isAuth: null,
    subscriptionActive: true,
    credits: true
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUser: (state, action) => {
            state.activeUser = action.payload;
        },
        updateAuth: (state, action) => {
            state.isAuth = action.payload;
        },
        updateSubscriptionActive: (state, action) => {
            state.subscriptionActive = action.payload;
        },
        updateCredits: (state, action) => {
            state.credits = action.payload;
        }
    }
});

export const { updateUser, updateAuth, updateSubscriptionActive, updateCredits } = userSlice.actions;

export default userSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    modelList: [],
    loadedModelList: false
}

export const modelSlice = createSlice({
    name: 'models',
    initialState,
    reducers: {
        loaded: (state, action) => {
            state.loadedModelList = true;
        },
        updateModelList: (state, action) => {
            state.modelList = action.payload;
        }
    }
});

export const { updateModelList, loaded } = modelSlice.actions;


export default modelSlice.reducer;
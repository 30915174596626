import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAudioUri, updateAudioPrompt, updateAudioDownloadBlob, toggleLoading, updateVoiceHistory } from '../../store/contentSlice';
import { generateElevenlabs } from '../../promptApi/elevenlabs';
import VoiceSelection from '../voice/SelectVoice';
import history from '../../data/history';
import Output from './Output';

import {
  Button,
  Textarea,
  Flex,
  FormControl,
  Heading,
  Stack,
  Text,
  Tag,
} from '@chakra-ui/react';

export default function PromptInputVoice() {
  const isLoading = useSelector((state) => state.content.isLoading);
  const audioBlob = useSelector((state) => state.content.audioDownloadBlob);
  const prompt = useSelector((state) => state.content.audioPrompt);
  const audioLoaded = audioBlob[0] ? true : false;

  const selectedVoice = useSelector((state) => state.content.selectedVoice);

  const dispatch = useDispatch();

  let promptlength = prompt.length;

  let handlePromptInputChange = (e) => {
    let inputValue = e.target.value
    let inputKey = e.target.id
    let newKey = {};
    newKey[inputKey] = inputValue;
    dispatch(updateAudioPrompt(inputValue))
  };

  const updateState = {
    updateUri: (data) => {dispatch(updateAudioUri(data))},
    updateblob: (data) => {dispatch(updateAudioDownloadBlob(data))},
    toggleLoading: () => {dispatch(toggleLoading())}
  }

  let handleSubmit = async () => {
    
    dispatch(toggleLoading());

    try {
        await generateElevenlabs(prompt,selectedVoice,updateState);
    } 
    catch (err) { 
        console.error(err); 
    }
    
    setTimeout(async () => {
      const voiceHistoryData = await history.getVoiceHistory();
      dispatch(updateVoiceHistory(voiceHistoryData));
    },15000)
}

  return (
    <Stack w={'100%'} direction={{ base: 'column', md: 'row' }}>
      <Flex p={4} flex={1} align={'top'} justify={'center'}>
        <Stack spacing={4} w={'full'}>
          <Heading fontSize={'lg'} align={'center'} pb={2}>Generate voice with Elevenlabs v1</Heading>
          <Flex><Text>Selected voice: </Text><Tag ml={'5px'} colorScheme='red'>{selectedVoice.name}</Tag></Flex>
          
          <FormControl id="prompt" w='100%'>
            <Textarea
              focusBorderColor='gray.500'
              value={prompt}
              onChange={handlePromptInputChange}
              placeholder='Type or paste text here, models perform best with longer fragments.'
              size='sm'
              h="300px"
              id={'prompt'}
              maxLength={'5000'}
            />
            <Text pt={3} pb={3} color={'gray.500'} fontSize={'sm'}>{promptlength} / 5,000</Text>
          </FormControl>
          <Flex direction={'row'} gap={5} width='100%'>
            <VoiceSelection />
            <Button w={'200px'} isLoading={isLoading} colorScheme={'blue'} variant={'solid'} onClick={handleSubmit}>
              {'Generate'}
            </Button>
          </Flex>
          {/* <Accordion defaultIndex={[1]} allowMultiple pt={3} pb={3}>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                    Model settings
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
              <ModelSettings />
              </AccordionPanel>
            </AccordionItem>
          </Accordion> */}
          <Output />
        </Stack>
      </Flex>
    </Stack>
  );
}
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoutes from './PrivateRoute';
import TopNav from '../components/TopNav';
import RequestPasswordReset from '../pages/RequestPasswordReset';
import PasswordReset from '../pages/PasswordReset';
import Login from '../pages/Login';
import Register from '../pages/Register';

const Router = () => {
    return (
        <BrowserRouter>
            <TopNav />
            <Routes>
                <Route path='/password-reset-request' element={<RequestPasswordReset />} />
                <Route path='/password-reset' element={<PasswordReset />} />
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path="/*" element={<PrivateRoutes />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router;
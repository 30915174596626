import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAudioUri, updateAudioPrompt, updateAudioDownloadBlob, toggleLoading, updateVoiceHistory } from '../../store/contentSlice';
import history from '../../data/history';
import { generateElevenlabs } from '../../promptApi/elevenlabs';

import { setModel } from '../../store/generateOptionsSlice';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Text,
    Flex
} from '@chakra-ui/react'

import {
    RiChatDeleteLine
} from 'react-icons/ri'

export default function VoiceHistory() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const modelList = useSelector((state) => state.models.modelList);
    const dispatch = useDispatch();
    const voiceHistory = useSelector((state) => state.content.voiceHistory);

    const getHistory = async () => {
        const voiceHistoryData = await history.getVoiceHistory();
        dispatch(updateVoiceHistory(voiceHistoryData));
    }

    if(voiceHistory[0] === 'no-history'){
        getHistory();
        return;
    }

    const historyAvailable = voiceHistory[0] !== 'no-history';

    const handleClick = async (voice) => {
        dispatch(toggleLoading());
        //set model
        modelList.forEach((model) => {
            if(model._id === voice.modelID){
                dispatch(setModel(model));
            }
        })
        //set prompt
        dispatch(updateAudioPrompt(voice.prompt));

        //set conversation messages
        const ENV = process.env.NODE_ENV;
            const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai'

        const stream = await fetch(
            `${api_url}/data/history/oneVoice/${voice._id}`, 
            {
            credentials: 'include',
            headers: {
                timestamp: voice.dateTime
            }
        });

        const updateState = {
            updateUri: (data) => {dispatch(updateAudioUri(data))},
            updateblob: (data) => {dispatch(updateAudioDownloadBlob(data))},
        }

        await generateElevenlabs(null, null, updateState, stream);
        setTimeout(() => {
            dispatch(toggleLoading())
        },2500);
        
        onClose();
    }

    const handleDelClick = (voice) => {
        //delete voice from DB
        history.delVoiceHistory(voice._id,voice.dateTime);

        //delete voice from state
        const newVoiceHistory = voiceHistory.filter((voiceRecord)=>{
            return voice._id !== voiceRecord._id;
        })

        //update state
        dispatch(updateVoiceHistory(newVoiceHistory));

        if(newVoiceHistory.length === 0){
            onClose();
        }
    }

    const RenderedHistoryList = voiceHistory.map((voice) => {
        const options = { weekday: 'short', month: 'short', day: 'numeric', 
                      hour: 'numeric', minute: 'numeric'};
        const dateTime = new Date(voice.dateTime);
        const formattedDateTime = dateTime.toLocaleDateString("en-US", options);

        function limitString(str) {
            if (str.length <= 240) {
                return str;
            }
            return str.slice(0, 240) + '...';
        }

        return (
            <Tr _hover={{ bg: "gray.100", cursor: "pointer" }} key={voice.dateTime}>
                <Td textAlign={'center'} onClick={() => {handleClick(voice)}} key={`name-${voice.title}`}><Text as='b'>{voice.title}</Text></Td>
                <Td textAlign={'center'} onClick={() => {handleClick(voice)}} key={`model-${voice.dateTime}`}>{voice.modelName}</Td>
                <Td textAlign={'center'} onClick={() => {handleClick(voice)}} key={`Title-${voice.dateTime}`} sx={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{limitString(voice.description)}</Td>
                <Td textAlign={'center'} onClick={() => {handleClick(voice)}} key={`Date-${voice.dateTime}`}>{formattedDateTime}</Td>
                <Td textAlign={'center'} onClick={() => {handleDelClick(voice)}} key={`Del-${voice.dateTime}`}><Flex justify={'center'}><RiChatDeleteLine color='#eb264f' fontSize='1.5rem'/></Flex></Td>
            </Tr>
        )
    })

    return (
      <Flex>
        <Button w={'200px'} onClick={onOpen} display={!historyAvailable ? 'none' : null} variant='outline' 
          colorScheme='blue'>View saved audio</Button>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent minW='1000px'>
            <ModalHeader align='center' bgColor='#f2f6fc'>Select an audio file</ModalHeader>
            <ModalCloseButton mt='8px'/>
            <ModalBody maxH='700px' overflow={'scroll'}>
                <Flex minH='100%' minW='100%' justify={'center'} align='center'>
                    <TableContainer>
                        <Table variant='simple' size='sm' overflowWrap={'auto'}>
                            <Thead>
                                <Tr height='60px'>
                                    <Th textAlign={'center'} key='Name'>Name</Th>
                                    <Th textAlign={'center'} key='Model'>Model</Th>
                                    <Th textAlign={'center'} key='Description'>Description</Th>
                                    <Th textAlign={'center'} key='Date'>Date/Time</Th>
                                    <Th textAlign={'center'} key='Del'>Delete</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {RenderedHistoryList}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Flex>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    )
  }
import React from 'react';
import { useSelector } from 'react-redux';
import History from './HistorySelectionView';

import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalContent,
} from '@chakra-ui/react'

function SelectHistory() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const images = useSelector((state) => state.content.imageHistory);

  return (
    <>
      <Button
        display={images.length <= 0 ? 'none' : null}
        onClick={onOpen}
        variant='outline'
        colorScheme='blue'
        w='200px'
        size='md'
      >
        Saved Images
      </Button>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset='slideInBottom'
      >
        <ModalOverlay />
        <ModalContent minW={'1200px'}>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH={'750px'} minH={'750px'} overflow='scroll' h='100%'>
            <History closeFunc={onClose} />
          </ModalBody >
          <ModalFooter justifyContent='right' margin='15px'>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default SelectHistory;
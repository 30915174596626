import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../components/transcription/SideBar';
import PromptInput from '../components/transcription/PromptInput';
import { retrieveModels } from "../data/models";
import { retrieveTemplates } from "../data/templates";
import { updateModelList, loaded } from '../store/modelSlice';
import { setModel, setGenerateMedia } from '../store/generateOptionsSlice';
import { updateTemplates, templateIsLoaded, selectTemplate } from '../store/templateSlice';
import Quill from '../components/transcription/QuillTranscription';
import Loading from '../components/Loading';

import { 
  Flex, 
  Card,
  CardBody,
  Heading
} from '@chakra-ui/react'

function GenerateTranscription(){
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const models = useSelector((state) => state.models.modelList);
  const selectedModel = useSelector((state) => state.generateOptions.model);

  useEffect(() => {
    const fetchData = async () => {
      try {

        if(models.length <= 0) {
          const modelList = await retrieveModels();
          dispatch(updateModelList(modelList));
          dispatch(loaded());
        }

        let loadModel = true;

        if(selectedModel !== null){
          if(selectedModel.generates.includes('Transcription')){
            loadModel = false;
          }
        }

        if(loadModel){
          models.forEach((m) => {
            if(m._id === '65563f13bc87d26caa4b1da5'){
              dispatch(setModel(m));
            }
          });
        }

        retrieveTemplates().then(templateList => {
            dispatch(updateTemplates(templateList));
            dispatch(templateIsLoaded());
            dispatch(selectTemplate(templateList));
            dispatch(setGenerateMedia('Transcription'));
        });

        setLoading(false);
        
      } catch(error) {
        console.log('Error fetching data', error);
      }
    };
    fetchData();
  }, [dispatch, models]);

  if (loading) {
    return <Loading />;
  }

  const { innerWidth: width} = window;

  if(width <= 900){
      return (
          <Flex
              justify={'center'}
              align={'center'}
              p={'20px'}
              >
              <Heading size={'sm'}>
                  Sorry we don't currently support screen sizes below 900px
              </Heading>
          </Flex>
      )
  }

  return (
    <Flex w='100%' h='100%'
      bgGradient='linear(to-t, #EBF3FD, #f3f0ff)'
      >
        <SideBar />
        <Flex
          w='100%'
          h='100%'
          direction='column' 
          p='20px'
          >
          <Flex 
            gap='20px' 
            w='100%'
            h='100%'
            >
            <Card 
              w='100%'
              minW='400px'
              variant='outline'
              boxShadow='md'
              overflow='scroll'
              flex='1'
            >
              <CardBody p='0px'>
                {<PromptInput />}
              </CardBody>
            </Card>
            <Card 
              w='100%'
              h='100%'
              overflow='hidden'
              variant='outline'
              boxShadow='md'
              flex='2'
              >
              <CardBody pt='10px' w='100%' pb='70px' overflow='hidden'>
                <Quill />
              </CardBody>
            </Card>
          </Flex>              
        </Flex>
    </Flex>      
  )
}

export default GenerateTranscription;
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { update, toggleLoading, updateCopyPrompt, updateCopyHistory } from '../../store/contentSlice';
import Generate from '../../promptApi/generate';
import history from '../../data/history';
import { updateCredits } from '../../store/userSlice';

import {
  Button,
  Textarea,
  Flex,
  FormControl,
  Heading,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react';

export default function PromptInput() {
  const isLoading = useSelector((state) => state.content.isLoading);
  const prompt = useSelector((state) => state.content.copyPrompt);
  const model = useSelector((state) => state.generateOptions.model);

  const dispatch = useDispatch();

  let [promptValue, setPromptValue] = useState({
      prompt: ''
  });

  let [systemPromptValue, setSystemPromptValue] = useState('')

  let handlePromptInputChange = (e) => {
    let inputValue = e.target.value
    dispatch(updateCopyPrompt(inputValue))
  };

  let handleSystemPromptInputChange = (e) => {
    let inputValue = e.target.value
    setSystemPromptValue(inputValue)
  }

  function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  const id = generateUUID();

  let handleSubmit = async () => {
    if(!isLoading){
      dispatch(toggleLoading());
      dispatch(update(''));

      const stateUpdate = async (content,chunk) => {
        dispatch(update(content));
        if(content === 'insufficient credit'){
          dispatch(updateCredits(false));
        }
      }

      if(promptValue.tov){
        prompt += ` use the following tone of voice: ${promptValue.tov}. `;
      }

      if(promptValue.audience){
        prompt += ` write for the following target audience: ${promptValue.audience}. `;
      }

      if(promptValue.keywords){
        prompt += ` make use of for the following keywords: ${promptValue.keywords}. `;
      }

      if(promptValue.instruction){
        prompt += ` strictly follow these instructions: ${promptValue.instruction}. `;
      }
      const now = new Date();
      await Generate(systemPromptValue, prompt, model, stateUpdate, 'copy',id, now);
      dispatch(toggleLoading());
      const chatHistoryData = await history.getCopyHistory();
      dispatch(updateCopyHistory(chatHistoryData));
    }
  }
  
  const systemPromptInput = 
  <Accordion allowToggle>
    <AccordionItem>
      <AccordionButton
        fontSize='sm' 
        bg={'gray.50'}
        >
        <Box as="span" flex='1' textAlign='left'>
          <Heading size='xs'>System Prompt</Heading>
        </Box>
        <AccordionIcon/>
      </AccordionButton>
      <AccordionPanel p={0} pt={4} pb={4}>
        <FormControl id="systemPrompt">
          <Textarea
            value={systemPromptValue}
            onChange={handleSystemPromptInputChange}
            placeholder='Place your system prompt here'
            size='sm'
            minH="400px"
          />
        </FormControl>
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
  
  let showSystemPrompt = false;
  if('promptOptions' in model){
    showSystemPrompt = model.promptOptions.systemPrompt
  }

  return (
    <Stack minW={'400px'} direction={{ base: 'column', md: 'row' }}>
      <Flex p={4} flex={1} align={'top'} justify={'center'}>
        <Stack spacing={4} w={'full'} maxW={'md'}>
          <FormControl id="prompt">
            <Textarea
              value={prompt}
              onChange={handlePromptInputChange}
              placeholder='Place your prompt here'
              size='sm'
              minH="400px"
              id={'prompt'}
            />
          </FormControl>
          
          <Stack spacing={6}>
            <Button isLoading={isLoading} colorScheme={'blue'} variant={'solid'} onClick={handleSubmit}>
              {'Generate'}
            </Button>
          </Stack>

          <Stack spacing={'-1px'}>
            {/* {showSystemPrompt && systemPromptInput} */}
            {/* {<CreativeInputsCopy props={props}/>} */}
            {/* <ModelSettings /> */}
          </Stack>
        </Stack>
      </Flex>
    </Stack>
  );
}
import user from '../data/user';
import mixpanel from 'mixpanel-browser';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { updateUser, updateAuth } from '../store/userSlice';
import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Link,
    Center
  } from '@chakra-ui/react';
  
export default function RequestPasswordReset() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    username: '',
    headline: 'Password reset'
  });
  const [failedAuth, setFailedAuth] = useState(false);

  const dispatch = useDispatch();


  const handleSubmit = (e) => {
    e.preventDefault();
    user.requestPasswordReset(values).then((data) => {
        setValues({
            ...values,
            headline: `${data.data}`
        })
    })
  }

  const handleChange = (e)=>{
    
    const value = e.target.value;
    const key = e.target.name;
    setValues({
        ...values,
        [key]: value
    })
  }

  const errorMessage = (
    <Center pb={'30px'}>
      <Heading fontSize={'lg'} color={'red.600'}>
        {error}
      </Heading>
    </Center>
  );
  
    return (
      <Flex
        bgGradient='linear(to-t, #EBF3FD, #f3f0ff)'
        h={'100%'}
        w={'100%'}
        align={'center'}
        justify={'center'}
        >
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'} textAlign={'center'} mb={'20px'} mt={'-100px'}>
              {values.headline}
            </Heading>
            <Text fontSize={'lg'} color={'gray.600'}>
              We all forget things sometimes...
            </Text>
            <Text fontSize={'lg'} color={'gray.600'}>
              Enter your email below and we'll send you a reset link ✌️
            </Text>
          </Stack>
          <Center>
            <Box
              rounded={'lg'}
              bg={useColorModeValue('white', 'gray.700')}
              boxShadow={'lg'}
              p={8}
              w='350px'
              >
              <Stack>
                {error ? errorMessage : null}
                <form onSubmit={handleSubmit}>
                  <FormControl id="email" isRequired>
                    <FormLabel>Email address</FormLabel>
                    <Input mb={'20px'} type="email" value={values.username} name='username' autoComplete={'email'} onChange={handleChange}/>
                  </FormControl>
                  <Stack spacing={10} pt={'20px'}>
                    <Button
                      type='submit'
                      bg={'blue.400'}
                      color={'white'}
                      isLoading={loading}
                      _hover={{
                        bg: 'blue.500',
                      }}>
                      Reset
                    </Button>
                  </Stack>
                  <Stack pt={'30px'}>
                    <Text align={'center'}>
                      Remember your password? <Link color={'blue.400'} href={'/login'}>Login</Link>
                    </Text>
                  </Stack>
                </form>
              </Stack>
            </Box>
          </Center>
        </Stack>
      </Flex>
    );
  }
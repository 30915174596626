import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateImagePrompt, updateBase64, toggleLoading } from '../../store/contentSlice';
import Generate from '../../promptApi/generateImage';
import { updateCredits } from '../../store/userSlice';
import Dalle3PromptInput from './PromptDalle3';
import SDXLPromptInput from './PromptSDXL';


import {
  Button,
  Textarea,
  Flex,
  FormControl,
  Stack,
} from '@chakra-ui/react';

export default function PromptInput({refreshImages}) {
  const isLoading = useSelector((state) => state.content.isLoading);
  const prompt = useSelector((state) => state.content.imagePrompt);
  const model = useSelector((state) => state.generateOptions.model);
  const imageTemplate = useSelector((state) => state.templates.imageTemplate);

  const dispatch = useDispatch();

  useEffect(()=>{
    if(imageTemplate){
      dispatch(updateImagePrompt(imageTemplate.promptTemplate))
    }
  },[imageTemplate])


  if(model._id === '65493974953797e528acaf64'){
    return <Dalle3PromptInput />
  }

  if(model._id === '646298424b056c9c5cc23c97'){
    return <SDXLPromptInput />
  }

  let handlePromptInputChange = (e) => {
    let inputValue = e.target.value
    dispatch(updateImagePrompt(inputValue));
  };


  let handleSubmit = async () => {
    dispatch(toggleLoading());    
    Generate('',prompt,model).then(response => {
      console.log(response)
      if(response === 'insufficient credit'){
        dispatch(updateCredits(false));
      }
        dispatch(updateBase64(response));
        dispatch(toggleLoading());
        //update image history state
        //wait 5 seconds to allow history to update
        setTimeout(refreshImages,10000);
        
    });
  }

  return (
    <Stack w='100%' direction={{ base: 'column', md: 'row' }}>
      <Flex flex={1} align={'top'} justify={'center'}>
        <Stack spacing={4} w={'full'} maxW={'md'}>
          <FormControl id="prompt">
            <Textarea
              value={prompt}
              onChange={handlePromptInputChange}
              placeholder='Place your prompt here'
              size='sm'
              w='100%'
              minH="500px"
              id={'prompt'}
            />
          </FormControl>
          
          <Stack spacing={6}>
            <Button isLoading={isLoading} colorScheme={'blue'} variant={'solid'} borderColor='gray.400' onClick={handleSubmit}>
              {'Generate'}
            </Button>
          </Stack>
        </Stack>
      </Flex>
    </Stack>
  );
}
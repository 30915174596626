import React from 'react';
import { useSelector } from "react-redux";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import {
    Button
} from '@chakra-ui/react'

const Player = () => {
    const audioUri = useSelector((state) => state.content.audioUri);
    const audioBlob = useSelector((state) => state.content.audioDownloadBlob);
    const isLoading = useSelector((state) => state.content.isLoading);

    const audioLoaded = audioBlob[0] ? true : false;

    async function downloadURI(uri, name) {
        //var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = uri;
        a.download = name;
        document.body.appendChild(a);
        a.click();    
        a.remove();
    }

    const handleDownload = () => {
       downloadURI(audioBlob,'veolabsGeneratedVoice.mp3');
    }
      
    return (
        <>
            <AudioPlayer
                autoPlay
                src={audioUri}
                type="audio/mpeg"
                // other props here
            />
            <Button isDisabled={!audioLoaded || isLoading} onClick={handleDownload} w={'200px'} colorScheme={'blue'} variant={'solid'}>Download audio</Button>
        </>
    )
}

export  default  Player;
import React, { useRef, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, VStack, Text, Portal, Flex } from '@chakra-ui/react';

const TypingLine = ({ text, onTypingComplete, scrollContainerRef }) => {
  const [typedText, setTypedText] = useState('');

  useEffect(() => {
    if (typedText.length < text.length) {
      const timeoutId = setTimeout(() => {
        setTypedText((prevTypedText) => prevTypedText + text.charAt(prevTypedText.length));
      }, 3); // Adjust typing speed if necessary
      return () => clearTimeout(timeoutId);
    } else {
      onTypingComplete();
    }
  }, [typedText, text, onTypingComplete]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [typedText, scrollContainerRef]);

  return <Text fontFamily="mono" fontSize="sm">{typedText}</Text>;
};

const typingReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_LINES':
      const newLines = action.payload.filter((line) =>
        !state.typedLines.includes(line) && !state.linesToType.map((item) => item.text).includes(line)
      );
      return {
        ...state,
        linesToType: [...state.linesToType, ...newLines.map((text) => ({ text, key: Math.random().toString(36) }))],
      };
    case 'TYPING_COMPLETE':
      if (state.linesToType.length > 0) {
        const nextLine = state.linesToType[0];
        return {
          ...state,
          typedLines: [...state.typedLines, nextLine.text],
          linesToType: state.linesToType.slice(1),
        };
      }
      return state;
    case 'RESET':
      return {
        ...state,
        typedLines: [],
        linesToType: [],
      };
    default:
      return state;
  }
};

const initialState = {
  typedLines: [],
  linesToType: [],
};

const ActionLogOverlay = () => {
  const veoWriterActions = useSelector((state) => state.content.veoWriterActions);
  const veoWriterActionsLoading = useSelector((state) => state.content.veoWriterActionsLoading);
  const actionLogContainerRef = useRef();
  const [state, dispatch] = useReducer(typingReducer, initialState);

  useEffect(() => {
    if (veoWriterActions.length === 0) {
      dispatch({ type: 'RESET' });
    } else if (veoWriterActions.length > (state.typedLines.length + state.linesToType.length)) {
      const newLines = veoWriterActions.slice(state.typedLines.length + state.linesToType.length);
      dispatch({ type: 'ADD_LINES', payload: newLines });
    }
  }, [veoWriterActions, state.typedLines.length, state.linesToType.length]);

  const handleTypingComplete = () => {
    dispatch({ type: 'TYPING_COMPLETE' });
  };

  return (
    <Portal>
      <Box
        display={veoWriterActionsLoading ? 'block' : 'none'}
        position="fixed"
        top="0"
        left="0"
        width="100vw"
        height="100vh"
        bg="rgba(0, 0, 0, 0.75)"
        zIndex="10000" // Ensure this is above everything else
      >
        <Box
            position="fixed"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            width="70%"
            height="588px"
            backgroundColor="gray.700"
            color="white"
            overflowY="none"
            boxShadow="lg"
            borderRadius="md"
            overflow={'none'}
            zIndex="10000" // Replace with a high z-index number from your theme, or use a custom value
        >
          <Flex 
            direction={'column'} 
            m={'20px'}
            marginBottom={'8px'}
            height="560px"
            ref={actionLogContainerRef}
            overflowY="auto"
            paddingBottom={'12px'}
            >
            {state.typedLines.map((line, index) => (
              <Text key={index} fontFamily="mono" fontSize="sm">
                {line}
              </Text>
            ))}
            {state.linesToType.length > 0 && (
              <TypingLine
                key={state.linesToType[0].key}
                text={state.linesToType[0].text}
                onTypingComplete={handleTypingComplete}
                scrollContainerRef={actionLogContainerRef}
              />
            )}
          </Flex>
        </Box>
      </Box>
    </Portal>
  );
};

export default ActionLogOverlay;
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { update, toggleLoading } from '../../store/contentSlice';
import TagsInputWrapper from './templateInputs/TagsInputWrapper';
import promptConstructor from '../../utils/CreatePrompt';
import Generate from '../../promptApi/generate';
import { updateCredits } from '../../store/userSlice';

import {
  Button,
  Textarea,
  Flex,
  FormControl,
  Heading,
  Stack,
  Spinner,
  Text
} from '@chakra-ui/react';

export default function TemplateInput() {
    const isLoading = useSelector((state) => state.content.isLoading);
    const template = useSelector((state) => state.templates.template);
    const model = useSelector((state) => state.generateOptions.model);
    const dispatch = useDispatch();

    let [promptValues, setPromptValue] = useState({})

    let handlePromptInputChange = (e) => {
        let inputValue = e.target.value
        let inputKey = e.target.id
        let newKey = {};
        newKey[inputKey] = inputValue;
        
        setPromptValue({
            ...promptValues,
            ...newKey
        })
    }
    const stateUpdate = (chunk) => {
      dispatch(update(chunk));
      if(chunk === 'insufficient credit'){
        dispatch(updateCredits(false));
      }

    }

    function generateUUID() { // Public Domain/MIT
      var d = new Date().getTime();//Timestamp
      var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16;//random number between 0 and 16
          if(d > 0){//Use timestamp until depleted
              r = (d + r)%16 | 0;
              d = Math.floor(d/16);
          } else {//Use microseconds since page-load if supported
              r = (d2 + r)%16 | 0;
              d2 = Math.floor(d2/16);
          }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    }
  
    const id = generateUUID();
    const now = new Date();

    let handleSubmit = async () => {
        dispatch(toggleLoading());
        await Generate(template.systemPrompt,promptConstructor(template.promptTemplate, promptValues),model, stateUpdate, 'copy', id, now);
        dispatch(toggleLoading());
    }

    const renderedInputs = template.promptInputs.map(input => {

        if(input.type === 'tags'){
            const props = {
                name: input.name,
                key: input.key,
                placeHolder: input.placeHolder,
                promptValues,
                setPromptValue,
            }
            return (
                <FormControl key={input.key}>
                  <Stack spacing={2}>
                    <Heading size='xs'>{input.name}:</Heading>
                    <TagsInputWrapper key={input.key} props={props}/>
                  </Stack>
                </FormControl>
            )
        }

        return (
          <FormControl key={input.key}>
            <Stack spacing={1}>
              <Heading size='xs'>{input.name}:</Heading>
              <Textarea
                  id={input.key}
                  onChange={handlePromptInputChange}
                  placeholder={input.name}
                  size='sm'
                  minH="100px"
              />
            </Stack>
          </FormControl>
        )
    })

  return (
    
    <Stack minH={'400px'} minW={'400px'} direction={{ base: 'column', md: 'row' }}>
      <Flex p={4} flex={1} align={'top'} justify={'center'}>
        <Stack spacing={4} w={'full'} maxW={'md'}>

          <Flex direction='row' mb={5}>
            <Flex direction='column' gap={5}>
              <Heading align={'center'} fontSize={'lg'}>{template.name}</Heading>
              <Text align={'center'}>{template.description}</Text>
            </Flex>
          </Flex>
          
          
          {renderedInputs}
          <Stack spacing={6}>
            <Button colorScheme={'gray'} variant={'outline'} borderColor='gray.400'  onClick={handleSubmit}>
              {isLoading ? <Spinner speed='0.65s'/> : 'Generate'}
            </Button>
          </Stack>
        </Stack>
        
      </Flex>
    </Stack>
  );
}
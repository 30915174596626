import React from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Templates from './TemplateSelectionView';

import { 
    useDisclosure,
    Button,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    ModalContent,
}  from '@chakra-ui/react'


function SelectTemplate({model}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
      
    return (
      <>
        <Button 
          onClick={onOpen}
          variant='outline' 
          colorScheme='blue'
          w='200px'
          size='md'
        >
          Prompt examples
        </Button>
        <Modal
          isCentered
          onClose={onClose}
          isOpen={isOpen}
          motionPreset='slideInBottom'
        >
          <ModalOverlay />
          <ModalContent minW={'1200px'}>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />
            <ModalBody maxH={'750px'} minH={'750px'} overflow='scroll'>
              <Templates closeFunc={onClose}/>
            </ModalBody >
            <ModalFooter justifyContent='right' margin='15px'>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default SelectTemplate;
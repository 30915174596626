import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../components/image/Sidebar';
import PromptInput from '../components/image/Prompt';
import { retrieveModels } from "../data/models";
import { retrieveTemplates } from "../data/templates";
import { updateModelList, loaded } from '../store/modelSlice';
import { setModel, setGenerateMedia } from '../store/generateOptionsSlice';
import { updateTemplates, templateIsLoaded, selectTemplate } from '../store/templateSlice';
import ImageEditor from '../components/ImageEditor';
import Loading from '../components/Loading';
import history from '../data/history';
import { updateImageHistory } from '../store/contentSlice';

import { 
  Flex, 
  Card,
  CardBody,
  Heading
} from '@chakra-ui/react'

function GenerateImage(){
  const dispatch = useDispatch();
  const models = useSelector((state) => state.models.modelList);
  const selectedModel = useSelector((state) => state.generateOptions.model);
  const templatesLoaded = useSelector((state) => state.templates.templatesLoaded);
  const imageHistory = useSelector((state) => state.content.imageHistory);
  const { innerWidth: width} = window;
  const [imagesLoading, setimagesLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      try {

        if(models.length <= 0) {
          const modelList = await retrieveModels();
          dispatch(updateModelList(modelList));
          dispatch(loaded());
        }

        let loadModel = true;

        if(selectedModel !== null){
          if(selectedModel.generates.includes('Image')){
            loadModel = false;
          }
        }

        if(loadModel){
          models.forEach((m) => {
            if(m._id === '65493974953797e528acaf64'){
              dispatch(setModel(m));
            }
          });
        }
        
        const templateList = await retrieveTemplates();
        dispatch(updateTemplates(templateList));
        dispatch(templateIsLoaded());
        dispatch(selectTemplate(templateList));
        dispatch(setGenerateMedia('Image'));

        setDataLoading(false);
        
        if(imageHistory[0] === 'no-history' || !imageHistory[imageHistory.length -1].hasOwnProperty('src') || imageHistory[imageHistory.length -1].src === null){
          const images = await history.getImageHistory();
          const imageDataNoImage = images.map((image) => {
            return { ...image, src: null, isLoading: true }
          }).reverse();
          await dispatch(updateImageHistory([1,imageDataNoImage]));
          setimagesLoading(false);
        }
        
      } catch (error) {
        console.log('Error fetching data', error);
      }
    }

    fetchData();
  }, [dispatch,models]);

  const updateImageState = async () => {
    if (!imagesLoading) {
        for(let image of imageHistory) {
          if (image.isLoading) {
            const ENV = process.env.NODE_ENV;
            const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai'

            const response = await fetch(
              `${api_url}/data/history/oneImage/${image._id}`, 
              {
                credentials: 'include'
              });
            const data = await response.text();
            
            let newImage = {
              ...image,
              src: data,
              isLoading: false
            };
            dispatch(updateImageHistory([0,newImage]));
          }    
        }
    }
  }

  useEffect(() => {
    updateImageState();
  }, [imagesLoading]);

  const refreshImages = async () => {
    setimagesLoading(true);
    function containsObject(obj, list) {
      var i;
      for (i = 0; i < list.length; i++) {
          if (list[i]._id === obj._id) {
              return false;
          }
      }
      return true;
    }
    const imagesData = await history.getImageHistory();

    const newImgs = imagesData.filter((img) => {
      return containsObject(img, imageHistory);
    })

    const imageDataNoImage = newImgs.map((image) => {
      return { ...image, src: null, isLoading: true }
    }).reverse();

    let newImageState = [...imageDataNoImage, ...imageHistory];

    await dispatch(updateImageHistory([1,newImageState]));

    setimagesLoading(false);
  };

  if (width <= 900){
    return (
        <Flex
            justify={'center'}
            align={'center'}
            p={'20px'}
            >
            <Heading size={'sm'}>
                Sorry we don't currently support screen sizes below 900px
            </Heading>
        </Flex>
    )
  }

  if (dataLoading) {
    return <Loading />
  }

  return (
    <Flex w='100%' h='100%'
      bgGradient='linear(to-t, #EBF3FD, #f3f0ff)'
      >
      <SideBar />
        <Flex 
          p='20px'
          gap='20px'
          w='100%'
          h='100%'
          >
          <Card 
            w='100%'
            minW='430px'
            variant='outline'
            boxShadow='md'
            overflow='scroll'
            flex='1'
            >
            <CardBody>
            <PromptInput refreshImages={refreshImages} />
            </CardBody>
          </Card>
          <Card 
            h='100%'
            w='100%'
            overflow='hidden'
            variant='outline'
            boxShadow='md'
            flex='2'
            >
            <CardBody>
              <ImageEditor />
            </CardBody>
          </Card>
        </Flex>              
    </Flex>      
  )
}

export default GenerateImage;
import React, { useRef, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';

const TypingLine = ({ text, onTypingComplete, scrollContainerRef }) => {
  const [typedText, setTypedText] = useState('');

  useEffect(() => {
    if (typedText.length < text.length) {
      const timeoutId = setTimeout(() => {
        setTypedText((prevTypedText) => prevTypedText + text.charAt(prevTypedText.length));
      }, 3); // Adjusted typing speed for better visibility
      return () => clearTimeout(timeoutId);
    } else {
      onTypingComplete(); // Notify parent that typing of this line is complete
    }
  }, [typedText, text, onTypingComplete]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [typedText, scrollContainerRef]);

  return <Text fontFamily="mono" fontSize="sm">{typedText}</Text>;
};

const typingReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_LINES':
      const newLines = action.payload.filter(
        (line) =>
          !state.typedLines.includes(line) && !state.linesToType.map((item) => item.text).includes(line)
      );
      return {
        ...state,
        linesToType: [...state.linesToType, ...newLines.map((text) => ({ text, key: Math.random().toString(36) }))],
      };
    case 'TYPING_COMPLETE':
      if (state.linesToType.length > 0) {
        const nextLine = state.linesToType[0];
        return {
          ...state,
          typedLines: [...state.typedLines, nextLine.text],
          linesToType: state.linesToType.slice(1),
        };
      }
      return state;
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

const initialState = {
  typedLines: [],
  linesToType: [],
};

const ActionLogOverlay = () => {
  const youTubeWriterActions = useSelector((state) => state.content.YouTubeWriterActions);
  const isLoadingYouTubeWriter = useSelector((state) => state.content.isLoadingYouTubeWriter);
  const actionLogContainerRef = useRef();
  const [state, dispatch] = useReducer(typingReducer, initialState);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [manuallyClosed, setManuallyClosed] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isLoadingYouTubeWriter && !manuallyClosed) {
      onOpen();
      // Close the modal automatically after 45 seconds
      timeoutId = setTimeout(handleClose, 25000);
    } else if (!isLoadingYouTubeWriter) {
      setManuallyClosed(false); // Reset manually closed state when loading stops
    }

    return () => clearTimeout(timeoutId);
  }, [isLoadingYouTubeWriter, manuallyClosed, onOpen]);

  useEffect(() => {
    if (isOpen && youTubeWriterActions.length > state.typedLines.length + state.linesToType.length) {
      const newLines = youTubeWriterActions.slice(state.typedLines.length + state.linesToType.length);
      if (newLines.length > 0) {
        dispatch({ type: 'ADD_LINES', payload: newLines });
      }
    }
  }, [youTubeWriterActions, state.typedLines.length, state.linesToType.length, isOpen]);

  const handleTypingComplete = () => {
    dispatch({ type: 'TYPING_COMPLETE' });
  };

  const handleClose = () => {
    onClose();
    dispatch({ type: 'RESET' });
    setManuallyClosed(true);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent
        background="gray.700"
        color="white"
        maxWidth="800px"
        height="500px" // Fixed height for the ModalContent
      >
        <ModalHeader>Agent Activity Log:</ModalHeader>
        <ModalCloseButton />
        <ModalBody padding={0} pb={'20px'} overflowY="hidden">
          <Flex
            direction={'column'}
            ml={'20px'}
            mr={'20px'}
            pb={'20px'}
            height="440px" // Adjust Flex height to account for ModalHeader and spacing
            ref={actionLogContainerRef}
            overflowY="auto"
          >
            {state.typedLines.map((line, index) => (
              <Text key={index} fontFamily="mono" fontSize="sm">
                {line}
              </Text>
            ))}
            {state.linesToType.length > 0 && (
              <TypingLine key={state.linesToType[0].key} text={state.linesToType[0].text} onTypingComplete={handleTypingComplete} scrollContainerRef={actionLogContainerRef} />
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ActionLogOverlay;
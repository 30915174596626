import user from '../data/user';
import mixpanel from 'mixpanel-browser';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { updateUser, updateAuth } from '../store/userSlice';
import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Link,
    Center
  } from '@chakra-ui/react';
  
export default function PasswordReset() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    password: '',
    rpc: '',
    email: ''
  });

  const [failedAuth, setFailedAuth] = useState(false);

  useEffect(( ) => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    setValues({
      ...values,
      rpc: params.get('rpc'),
      email: params.get('email')
    })
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    user.PasswordReset(values).then((data) => {
      console.log(data.data)
      if(data.data === 'password updated'){
        window.location.href = '/login'
      }
    });
  }

  const handleChange = (e)=>{
    const value = e.target.value;
    const key = e.target.name;
    setValues({
        ...values,
        [key]: value
    })
  }

  const errorMessage = (
    <Center pb={'30px'}>
      <Heading fontSize={'lg'} color={'red.600'}>
        {error}
      </Heading>
    </Center>
  );
  
    return (
      <Flex
        bgGradient='linear(to-t, #EBF3FD, #f3f0ff)'
        h={'100%'}
        w={'100%'}
        align={'center'}
        justify={'center'}
        >
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'} textAlign={'center'} mb={'20px'} mt={'-100px'}>
              Password reset for {values.email}
            </Heading>
            <Text fontSize={'lg'} color={'gray.600'}>
              Enter your new password...
            </Text>
            <Text fontSize={'lg'} color={'gray.600'}>
              Please try harder to remember it ✌️
            </Text>
          </Stack>
          <Center>
            <Box
              rounded={'lg'}
              bg={useColorModeValue('white', 'gray.700')}
              boxShadow={'lg'}
              p={8}
              w='350px'
              >
              <Stack>
                {error ? errorMessage : null}
                <form onSubmit={handleSubmit}>
                  <FormLabel>New Password:</FormLabel>
                  <InputGroup>
                    <Input type={showPassword ? 'text' : 'password'} value={values.password} name='password' autoComplete={'off'} onChange={handleChange}/>
                    <InputRightElement h={'full'}>
                      <Button
                        variant={'ghost'}
                        onClick={() =>
                          setShowPassword((showPassword) => !showPassword)
                        }>
                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <Stack spacing={10} pt={'30px'}>
                    <Button
                      type='submit'
                      bg={'blue.400'}
                      color={'white'}
                      isLoading={loading}
                      _hover={{
                        bg: 'blue.500',
                      }}>
                      Update password
                    </Button>
                  </Stack>
                  <Stack pt={'30px'}>
                    <Text align={'center'}>
                      Remember your password? <Link color={'blue.400'} href={'/login'}>Login</Link>
                    </Text>
                  </Stack>
                </form>
              </Stack>
            </Box>
          </Center>
        </Stack>
      </Flex>
    );
  }